import React, { useEffect, useState } from "react";
import { Container, Col, Row, Card, Image } from "react-bootstrap";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Path } from "../../static/Constants";
import Background from "../../static/Images/bgnew.png";
import { RiWhatsappFill } from "react-icons/ri";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdLocationOn, MdLocalPostOffice, MdPhone } from "react-icons/md";
import Pdf from "./../Autobet_whitepaper.pdf";

const FooterStrip = () => {
  const currentYear = moment().year();
  let history = useHistory();
  const [footerClass, setFooterClass] = useState("");
  
  const isAdmin = () => {
    return window.location.pathname.startsWith("/admin");
  };

  const isCreator = () => {
    return window.location.pathname.startsWith("/creator");
  };

  const getFooterBottomClass = () => {
    if (isAdmin()) {
      return "footer-bottom admin-footer";
    } else if (isCreator()) {
      return "footer-bottom creator-footer";
    }
    return "footer-bottom";
  };

  useEffect(() => {
    const footerBottomClass = getFooterBottomClass();
    setFooterClass(footerBottomClass);
  }, []);
  return (
    <Container fluid className="p-0">
      <div className={footerClass}>
        <div className="footer-section">
          <Container fluid>
            <div className="row justify-content-around footer-div">
              <Col lg={3} md={4} sm={6} xs={12} className="footer-col">
                <div className="footer-icon">
                  <Image
                    src={require("../../static/Images/logo.png").default}
                    className="imagee"
                  />
                  <span style={{ marginBottom: "6px" }}>
                    <p className="autobetLg">
                      AutoBET -{" "}
                      <p className="tagLine">
                        Decentralized Lottery Ecosystem for Fundraising
                      </p>
                    </p>
                  </span>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={12} className="footer-col">
                <div className="copy-right-text">
                  <p className="footerTextMedia">
                    <h5 className="foot-head">Pages</h5>
                    <div className="redirect-section">
                      <a href="/all-lotteries">
                        <p>Autobet Lotteries</p>
                      </a>
                      <a href="/all-giftcards">
                        <p>Giftcards</p>
                      </a>
                      <a href="/lottery-result">
                        <p>Result</p>
                      </a>
                      <a href="/creators">
                        <p>Lottery Creator</p>
                      </a>
                      <a href="/terms&conditions">
                        <p>Terms & Conditions</p>
                      </a>
                      <a target="_blank" href={Pdf}>
                        <p>Autobet Whitepaper</p>
                      </a>
                    </div>
                  </p>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={12} className="footer-col">
                <div className="copy-right-text">
                  <p className="footerTextMedia">
                    <div className="contact-section">
                      <h5 className="foot-head">Contact Us</h5>
                      <div className="details">
                        <RiWhatsappFill />
                        <span>675 75647642</span>
                      </div>
                      <div className="details ">
                        <MdLocalPostOffice />
                        <span>info@autobetlottery.com</span>
                      </div>
                      <div className="details">
                        <MdLocationOn />
                        <span className="address">
                        Barawagi- Kerowagi District, Chimbu Province, Papua New Guinea{" "}
                        </span>
                      </div>
                    </div>
                  </p>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={12} className="footer-col">
                <div className="social-section">
                  <h5 className="foot-head">Social Media</h5>
                  <ul
                    className="footer-social-links d-flex justify-content-center"
                    style={{ marginBottom: "0px" }}
                  >
                    <li>
                      <a href="https://www.facebook.com/Autobet-Lottery-529783064597758/?__tn__=C-R" target="_blank">
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>

                    <li>
                      <a href="https://www.linkedin.com/company/autobet-lottery-ecosystem/" target="_blank">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/AutobetLottery?t=eWnSpFjkAgkFmvNtIo67cg&s=09" target="_blank">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://t.me/+aouibOFxwW9hOTZlwa" target="_blank">
                        <i className="fa fa-telegram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="mailto:info@autobetlottery.com" target="_blank">
                        <i className="fa fa-envelope"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </div>
          </Container>
        </div>

        <div className="copyrights">
          <Container>
            <Row>
              <Col xs={12}>
                <div className="copy-right-text copyright-section">
                  <p className="footerTextMedia">
                    ©{currentYear} <a href={Path.root}>AutoBET</a> - All Rights
                    Reserved.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Container>
  );
};
export default FooterStrip;
