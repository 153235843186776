import React, { Component } from "react";
import { Container, Col, Row, Card, Button, Modal } from "react-bootstrap";
import cinstance from "../../Service/randomcinstance";
import web3 from "../../Service/web3";
import axios from "axios";
import NavBar from "../../Components/NavBar";
import SpinWheel from "../../Components/SpinWheel/SpinWheel";
class AllSpinlottery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seed: "",
      loading: false,
      Randomresult: "",
      winner: "",
      lotterylist: [],
      partnerLogoData: [],
      show: false,
    };
  }
  async componentDidMount() {
    var lotterylist = [];
    var partnerlists = [];
    const apiUrl = process.env.REACT_APP_API_KEY;
    if (window.ethereum) {
      window.web3 = web3;
      cinstance.methods
        .lotteryId()
        .call()
        .then((res) => {
          for (var i = 1; i < res; i++) {
            cinstance.methods
              .lottery(i)
              .call()
              .then((response) => {
                if (response.status === "0" || response.status === "5") {
                  if (response.lotteryType == 0) {
                    lotterylist.push(response);
                    this.setState({ lotterylist });
                    cinstance.methods
                      .partnerbyaddr(response.partnerAddress)
                      .call()
                      .then((responsee) => {
                        // console.log("data listttt", responsee);
                        partnerlists.push(responsee);
                        this.setState({ partnerlists });
                      })
                      .catch((err) => console.log("error", err));
                  }
                }
              })
              .catch((err) => console.log("pepep", err));
          }
        })
        .catch((err) => console.log("res", err));
    } else {
      alert("Connect to wallet");
    }
    axios.get(`${apiUrl}/s3imageupload/getAllPartnerLogos`).then((response) => {
      const partnerData = response.data.result;
      this.setState({ partnerLogoData: partnerData });
    });
  }

  render() {
    const { lotterylist, partnerlists, partnerLogoData } = this.state;
    const sortedLotteryList = [...lotterylist].sort(
      (a, b) => b.lotteryId - a.lotteryId
    );

    const findPartnerByAddress = (address, partnerList) => {
      return (
        partnerList?.find((partner) => partner.partnerAddress === address) || {}
      );
    };

    return (
      <div fluid className="p-0 m-0 allLottery">
        <NavBar />

        <Container>
          <div className="mx-auto mt-3 ">
            <Row className="lottery-section">
              <Col>
                <h2
                  className="section-title"
                  style={{ fontWeight: "600", fontSize: "28px" }}
                  // onClick={()=>this.setState({ show: true })}
                >
                  Autobet Revolver
                </h2>
              </Col>
            </Row>
            <Row className="mx-auto lottery-section2 text-center">
              {lotterylist.length == 0 && (
                <h4 className="spacingleft">No Active Lotteries !</h4>
              )}
              {sortedLotteryList.map((item, index) => {
                const partnerData = findPartnerByAddress(
                  item.partnerAddress,
                  partnerlists
                );
                return (
                  <Col lg={4} key={`id${index}`} className="revolver-col">
                    <SpinWheel
                      lotteryData={item}
                      partner={partnerData}
                      partnerLogo={partnerLogoData}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </Container>
        {/* <FAQ/> */}

        {/* MODAL */}

        <Modal
          className="PopupStyle"
          show={this.state.show}
          centered
          onHide={() => this.setState({ show: false })}
        >
          <Modal.Body>
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="message-text">
                    I will not close if you click outside me. Don't even try to
                    press escape key.
                  </div>
                </Col>
                <Col lg={12} sm={12} xs={12}>
                  <div className="btn-div">
                    <Button
                      className="submit-btn btn1"
                      onClick={() => this.setState({ show: false })}
                    >
                      Close
                    </Button>
                    <Button className="submit-btn" onClick={() => {}}>
                      Understood
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default AllSpinlottery;
