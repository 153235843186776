import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import cinstance from "../../Service/randomcinstance";
import usercinstance from "../../Service/usercinstance";
import web3 from "../../Service/web3";
import { PiHandshakeDuotone, PiGiftDuotone, PiHandCoins } from "react-icons/pi";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { RiRefreshLine } from "react-icons/ri";
import {
  FaRegMoneyBill1,
  FaRegNewspaper,
} from "react-icons/fa6";
import { BsTicketDetailed, BsCashCoin } from "react-icons/bs";
import { GiPodiumWinner } from "react-icons/gi";
import { LiaGiftsSolid } from "react-icons/lia";
import "./NotificationStyle.css";

const Notification = ({ notifications }) => {
  let history = useHistory();
  const [adminAccount, setAdminAccount] = useState(false);
  const [creatorAccount, setCreatorAccount] = useState(false);
  const [accountAddress, setAccountAddress] = useState("");

  function formatTimeAgo(dateTimeString) {
    const date = new Date(dateTimeString);
    return formatDistanceToNow(date, { addSuffix: true });
  }

  useEffect(() => {
    const fetchUser = async () => {
      let account = await web3.eth.getAccounts();
      setAccountAddress(account[0])
      const adminAdd = await cinstance.methods.admin().call();
      if (account[0] === adminAdd) {
        setAdminAccount(true);
      } else {
        const res = await usercinstance.methods.ownerId().call();
        for (let i = res - 1; i > 0; i--) {
          const creatorAdd = await usercinstance.methods
            .organisationbyid(i)
            .call();
          if (account[0] === creatorAdd) {
            setCreatorAccount(true);
            break;
          }
        }
      }
    };
    fetchUser();
  }, []);

  function handleNotificationClick(notification) {
    switch (notification.type) {
      case "LotteryCreated":
        if (notification.lotteryName === "Missile") {
          history.push(`/missilelottery/${notification.genId}`);
        } else if (notification.lotteryName === "Mine") {
          history.push(`/minelottery/${notification.genId}`);
        } else if (notification.lotteryName === "MRL") {
          history.push(`/lottery/${notification.genId}`);
        } else {
          history.push("/spinnerlottery");
        }
        break;

      case "LotteryCreatedAdmin":
        if (notification.lotteryName === "Missile") {
          history.push(`/missilelottery/${notification.genId}`);
        } else if (notification.lotteryName === "Mine") {
          history.push(`/minelottery/${notification.genId}`);
        } else if (notification.lotteryName === "MRL") {
          history.push(`/lottery/${notification.genId}`);
        } else {
          history.push("/spinnerlottery");
        }
        break;
      case "RolloverHappened'":
        if (notification.lotteryName === "Missile") {
          history.push(`/missilelottery/${notification.genId}`);
        } else if (notification.lotteryName === "Mine") {
          history.push(`/minelottery/${notification.genId}`);
        } else if (notification.lotteryName === "MRL") {
          history.push(`/lottery/${notification.genId}`);
        } else {
          history.push("/spinnerlottery");
        }
        break;
      case "LotteryResult":
        if (adminAccount === true) {
          history.push(`/admin/lotterydetails/${notification.genId}`);
        } else if (creatorAccount === true && accountAddress === notification.owner) {
          history.push(`/creator/lotterydetails/${notification.genId}`);
        }else {
          history.push(`/lottery-result`);
        }
        break;
      case "SpinLotteryResult":
        if (adminAccount === true) {
          history.push(`/admin/lotterydetails/${notification.genId}`);
        } else if (creatorAccount === true && accountAddress === notification.owner) {
          history.push(`/creator/lotterydetails/${notification.genId}`);
        }else {
          history.push(`/lottery-result`);
        }
        break;
      case "LotteryWinner":
        if (adminAccount === true) {
          history.push(`/admin/lotterydetails/${notification.genId}`);
        } else if (creatorAccount === true && accountAddress === notification.owner) {
          history.push(`/creator/lotterydetails/${notification.genId}`);
        }else {
          history.push(`/lottery-result`);
        }
        break;
      case "GiftCardCreated":
        history.push(`/giftcard/${notification.genId}`);
        break;
      case "OrganisationAdded":
        history.push(`/admin/creatorslist`);
        break;
      case "LotteryBought":
        if (adminAccount === true) {
          history.push(`/admin/lotterydetails/${notification.genId}`);
        } else if (creatorAccount === true && accountAddress === notification.owner) {
          history.push(`/creator/lotterydetails/${notification.genId}`);
        } else if (accountAddress === notification.user) {
          history.push(`/profile`)
        }
        break;
      case "GiftCardPurchased":
        history.push(`/admin/giftcarddetails/${notification.genId}`);
        break;
      case "PartnerCreated":
        if (adminAccount === true) {
        history.push(`/admin/partnerslist`);
        } else {
          history.push(`/#partners`)
        }
        break;

      default:

        break;
    }
  }

  return (
    <div className="notification">
      <div className="notification_heading">
        <span className="heading">Notifications</span>
        <span
          className="see_all"
          onClick={() => history.push("/notifications")}
        >
          See All
        </span>
      </div>
      {notifications?.result?.length > 0 ? (
        notifications.result.map((notification, index) => (
          <div className="notification_list mt-2" key={index} onClick={() => handleNotificationClick(notification)}>
            <div className="icon-section">
              {notification.type === "OrganisationAdded" ? (
                <AiOutlineUsergroupAdd className="notify_icon" />
              ) : notification.type === "PartnerCreated" ? (
                <PiHandshakeDuotone className="notify_icon" />
              ) : notification.type === "LotteryBought" ? (
                <FaRegMoneyBill1 className="notify_icon" />
              ) : notification.type === "LotteryWinner" ? (
                <GiPodiumWinner className="notify_icon" />
              ) : notification.type === "GiftCardCreated" ? (
                <PiGiftDuotone className="notify_icon" />
              ) : notification.type === "WinnerPaid" ? (
                <BsCashCoin className="notify_icon" />
              ) : notification.type === "SpinLotteryResult" ? (
                <GiPodiumWinner className="notify_icon" />
              ) : notification.type === "PartnerPaid" ? (
                <PiHandCoins className="notify_icon" />
              ) : notification.type === "PlatformUpdate" ? (
                <FaRegNewspaper className="notify_icon" />
              ) : notification.type === "LotteryCreated" ? (
                <BsTicketDetailed className="notify_icon" />
              ) : notification.type === "LotteryCreatedAdmin" ? (
                <BsTicketDetailed className="notify_icon" />
              ) : notification.type === "GiftCardPurchased" ? (
                <LiaGiftsSolid className="notify_icon" />
              ) : notification.type === "LotteryResult" ? (
                <GiPodiumWinner className="notify_icon" />
              ) : (
                <RiRefreshLine className="notify_icon" />
              )}
            </div>
            <div className="details-section">
              <p className="notification_title">{notification.title}</p>
              <p className="notification_content">{notification.content}</p>
              <p className="notification_time">
                {formatTimeAgo(notification.createdOn)}
              </p>
            </div>
          </div>
        ))
      ) : (
        <p>No notifications available</p>
      )}
    </div>
  );
};

export default Notification;
