import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import cinstance from "../../Service/randomcinstance";
import giftcardcinstance from "../../Service/giftcardcinstance";
import web3 from "../../Service/web3";
import axios from "axios";
import getImageUrlByHash from "../../../static/logoUtils";
import OrgNavBar from "../../Components/OrgNavBar";
import moment from "moment";
import Loaders from "../../Components/Loaders";
import AutobetLogo from "../../../static/Images/logo.png";
import ButtonCommon from "../../Components/ButtonCommon";
import { GiTrousers } from "react-icons/gi";

function GiftCardDetails(props) {
  const [loading, setLoading] = useState(true);
  const [giftcard, setGiftCard] = useState([]);
  const NoBuyer = "0x0000000000000000000000000000000000000000";
  const currentDT = moment().unix();
  const [giftcardLogoData, setGiftcardLogoData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    async function fetchData() {
      if (window.ethereum) {
        window.web3 = web3;
        try {
          const response = await giftcardcinstance.methods
            .giftCards(props.match.params.id)
            .call();
          setGiftCard(response);
          setLoading(false);
        } catch (err) {
          console.log("error", err);
        }

        let account = await web3.eth.getAccounts();
      } else {
        alert("Connect to wallet");
      }
    }

    fetchData();
  }, [props.match.params.id]);

  useEffect(() => {
    axios.get(`${apiUrl}/giftcardlogos/getAllGiftcardLogos`).then((response) => {
      const giftcardData = response.data.result;
      setGiftcardLogoData(giftcardData);
    });
  }, []);

  const imageUrl = getImageUrlByHash(giftcard.logoHash, giftcardLogoData);

  return (
    <div className="lottery-details">
      {loading && <Loaders />}
      <OrgNavBar />
      <Container className=" bgImage  p-o " fluid>
        <div className="sidebars">
          <Row>
            <Col lg="12">
              <h5 style={{ textAlign: "center", marginTop: 20 }}>
                Gift Card Details
              </h5>
            </Col>
          </Row>
          <Row className=" mt-5 mb-2 mx-auto">
            <Col lg="6">
              <p className="mt-1 mb-1 ">
                Card Image : <br />
                {giftcard.logoHash !== undefined && giftcard.logoHash !== "" ? (
                  <img
                    src={imageUrl}
                    className="img-fluid"
                    style={{ height: "160px" }}
                  />
                ) : (
                  <img
                    src={AutobetLogo}
                    className="img-fluid"
                    style={{ height: "160px" }}
                  />
                )}
              </p>
            </Col>
            <Col lg="6">
              <p className="mt-1 mb-1 address-text">
                Created By : {giftcard && giftcard.creator}
              </p>

              {giftcard && giftcard.BETValue && (
                <p className="mt-1 mb-1 ">
                  Amount: {web3.utils.fromWei(`${giftcard.BETValue}`, "ether")}{" "}
                  BET
                </p>
              )}
              {giftcard && giftcard.ETHPrice && (
                <p className="mt-1 mb-1 ">
                  Price : {web3.utils.fromWei(`${giftcard.ETHPrice}`, "ether")}{" "}
                  ETH
                </p>
              )}
              {giftcard.isSold === false && giftcard.expDate > currentDT && (
                <p className="mt-1 mb-1 ">Status : Active</p>
              )}

              {giftcard.isSold === true && (
                <p className="mt-1 mb-1 ">Status : Redeemed</p>
              )}
              {giftcard.isSold === false && giftcard.expDate < currentDT && (
                <p className="mt-1 mb-1 ">Status : Expired</p>
              )}
              <p className="mt-1 mb-1 ">
                Expiry Date:{" "}
                {moment
                  .unix(giftcard && giftcard.expDate)
                  .format("DD/MM/YYYY hh:mma")}
              </p>
              {giftcard.voucherCode !== "" && (
                <p className="mt-1 mb-1 ">Voucher Code : {giftcard.voucherCode}</p>
              )}
               {giftcard.isSold === false && giftcard.expDate < currentDT && (
                <p className="mt-1 mb-1 ">Token Transfered : {giftcard.status === "0" ? "No" : giftcard.status ==="1"? "NA" : "Yes"} </p>
              )}
            </Col>
            <Col lg="12">
              <p className="mt-1 mb-1 ">
                Description: {giftcard && giftcard.Desc}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>Bought By</th>
                  </tr>
                </thead>
                <tbody>
                  {giftcard && giftcard.buyer === NoBuyer && (
                    <tr>
                      <td colspan={12} style={{ textAlign: "center" }}>
                        {" "}
                        <h4> No Data !</h4>{" "}
                      </td>
                    </tr>
                  )}
                  <>
                    {giftcard && giftcard.buyer !== NoBuyer && (
                      <tr key={giftcard.giftCardId}>
                        <td className="address-text">{giftcard && giftcard.buyer}</td>
                      </tr>
                    )}
                  </>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default GiftCardDetails;
