import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import NavBar from "../../../Components/NavBar";
import "./TermsAndConditionStyle.css"

const TermsAndConditions = () => {
  return (
    <div id="terms-container">
        <NavBar/>
      <Container className="mt-4">
        <Row>
          <Col xs={12}>
            <h2
                  className="section-title text-center mb-4"
                  style={{ fontWeight: "600", fontSize: "28px" }}
                >
                 Terms and Conditions
                </h2>
            <ol className="terms">
              <li>
                <strong>Acceptance of Terms</strong>
                <p>
                  Welcome to the Autobet Lottery platform. By accessing and
                  using this Platform, you agree to comply with and be bound by
                  the following terms and conditions ("Terms"). If you do not
                  agree with these Terms, please refrain from using the
                  Platform.
                </p>
              </li>
              <li>
                <strong>User Registration and Responsibilities</strong>
                <ol className="ml-4">
                  <li>
                    To unlock the full potential of the Platform, including
                    creating and participating in lotteries, users must register
                    and provide accurate information.
                  </li>
                  <li>
                    Users must possess a MetaMask browser extension or use the
                    DApps (Decentralized Application) browser, configuring it to
                    the ETH mainnet for seamless interactions.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Platform Features</strong>
                <ol className="ml-4">
                <li>
                  Discover a spectrum of features on the
                  Platform, spanning lotteries, gift cards, and real-time news
                  updates.
                </li>
                <li>
                  Dive into an immersive experience where
                  users can explore lotteries, purchase tickets, and redeem gift
                  cards, all in alignment with the Platform's guidelines.
                </li>
                </ol>
                
              </li>
              <li>
                <strong>Creator Registration and Responsibilities</strong>
                <ol className="ml-4">
                <li>
                  Creators play a pivotal role; thus, they
                  must register and submit a registration fee equivalent to 10%
                  of the median between the minimum and maximum winning amounts
                  for lotteries.
                </li>
                <li>
                 Precision matters: Creators commit to
                  providing accurate information during registration, covering
                  details such as phone number, date of birth, email, and
                  resident address.
                </li>
                <li>
                Referral Incentives: Creators have the
                  opportunity to enter a referral address. If the referred
                  address evolves into a creator, the referrer earns a 1% share
                  of the new creator's profit.
                </li>
                </ol>
                
              </li>
              <li>
                <strong>Lottery Creation and Participation</strong>
                <ol className="ml-4">
                <li>
                  Unleash creativity with various lottery
                  types like Autobet Revolver, Autobet Mine, Autobet MRL, and
                  Autobet Missile, each defined by unique rules and conditions.
                </li>
                <li>
                   Empower creators during the lottery
                  creation process, allowing them to specify winning amounts,
                  ticket fees, and other pertinent details.
                </li>
                <li>
                  User empowerment: Engage in thrilling
                  lotteries by acquiring tickets that adhere to the prescribed
                  rules.
                </li>
                </ol>
                
              </li>
              <li>
                <strong>Gift Cards</strong>
                <ol className="ml-4">
                <li>
                   Creators hold the key to crafting gift
                  cards, utilizing BET tokens, for either sale or donation,
                  adding a dynamic layer to the Platform's ecosystem.
                </li>
                <li>
                  User benefits: Buyers can seamlessly
                  procure or redeem gift cards based on creator stipulations,
                  with BET tokens transferred accordingly.
                </li>
                </ol>
                
              </li>
              <li>
                <strong>User Profile</strong>
                <ol className="ml-4">
                <li>
                  Personalization at its best: Users can
                  delve into their lottery and gift card history within their
                  user profile.
                </li>
                <li>
                 Financial flexibility: Withdraw BET
                  tokens and relish referral bonuses earned through a
                  user-friendly profile section.
                </li>
                </ol>
                
              </li>
              <li>
                <strong>Admin Responsibilities</strong>
                <ol className="ml-4">
                <li>
                 The orchestrator of excellence: The
                  platform owner (Admin) oversees the seamless execution of
                  processes, managing creator details, lotteries, and partner
                  relationships.
                </li>
                <li>
                 Information hub: Admin retains the
                  ability to withdraw platform earnings and curate news and
                  announcements for enhanced user engagement.
                </li>
                </ol> 
              </li>
              <li>
                <strong>Partnerships</strong>
                <ol className="ml-4">
                <li>
                  Collaboration matters: Partners undergo
                  a meticulous verification process and, once approved,
                  seamlessly integrate into the platform.
                </li>
                <li>
                  Mutual growth: Partners are entitled to
                  a share percentage of the winning amount, as mutually agreed
                  upon with the creator.
                </li>
                </ol>
                
              </li>
              <li>
                <strong>Notifications</strong>
                <ol className="ml-4">
                <li>
                 Stay informed: The Platform ensures
                  timely notifications related to lotteries, gift cards, news,
                  and more, disseminated to users, creators, and admin.
                </li>
                <li>
                 User-centric: Notifications can be
                  conveniently exported in Excel format for enhanced user
                  control and management.
                </li>
                </ol>
                
              </li>
              <li>
                <strong>Disclaimers and Limitation of Liability</strong>
                <ol className="ml-4">
                <li>
                  Innovation meets responsibility: The
                  platform delivers services "as is," with users and creators
                  acknowledging usage at their own risk. The Platform disclaims
                  liability for any direct or indirect damages.
                </li>
                </ol>
                
              </li>
              <li>
                <strong>Governing Law</strong>
                <p>
                  These Terms are governed by and construed in accordance with
                  the laws of [your jurisdiction], ensuring a transparent and
                  fair legal framework for all interactions on the Platform.
                </p>
              </li>
              <li>
                <strong>Changes to Terms</strong>
                <p>
                  The Platform reserves the right to modify these Terms at any
                  time. Users and creators will be duly notified of any changes,
                  fostering an atmosphere of transparency and user awareness.
                </p>
              </li>
            </ol>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TermsAndConditions;
