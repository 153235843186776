import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import web3 from "../../../Service/web3";
import { PiHandshakeDuotone, PiGiftDuotone, PiHandCoins } from "react-icons/pi";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { RiRefreshLine } from "react-icons/ri";
import {
  FaRegMoneyBill1,
  FaRegNewspaper,
} from "react-icons/fa6";
import { BsTicketDetailed, BsCashCoin } from "react-icons/bs";
import { GiPodiumWinner } from "react-icons/gi";
import { LiaGiftsSolid } from "react-icons/lia";
import { formatDistanceToNow } from "date-fns";
import NavBar from "../../../Components/NavBar";
import { ReactComponent as PreviousIcon } from "../../../../static/Images/previous.svg";
import { ReactComponent as NextIcon } from "../../../../static/Images/next.svg";
import cinstance from "../../../Service/randomcinstance";
import usercinstance from "../../../Service/usercinstance";
import "./NotificationScreenStyle.css";

function NotificationScreen() {
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const limit = 10;
  const apiUrl = process.env.REACT_APP_API_KEY;
  const history = useHistory();
  const [adminAccount, setAdminAccount] = useState(false);
  const [creatorAccount, setCreatorAccount] = useState(false);
  const [accountAddress, setAccountAddress] = useState("");

  function formatTimeAgo(dateTimeString) {
    const date = new Date(dateTimeString);
    return formatDistanceToNow(date, { addSuffix: true });
  }

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let account = await web3.eth.getAccounts();
        setAccountAddress(account[0])
        const skip = currentPage * limit;

        const response = await fetch(
          `${apiUrl}/notifications?limit=${limit}&skip=${skip}`,
          {
            method: "GET",
            headers: {
              Authorization: account[0],
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setNotifications(data.result);
          setTotalPages(Math.ceil(data.count / limit));
          localStorage.setItem("notifications", JSON.stringify(data.result));

          const adminAdd = await cinstance.methods.admin().call();
          if (account[0] === adminAdd) {
            setAdminAccount(true);
          } else {
            const res = await usercinstance.methods.ownerId().call();
            for (let i = res - 1; i > 0; i--) {
              const creatorAdd = await usercinstance.methods
                .organisationbyid(i)
                .call();
              if (account[0] === creatorAdd) {
                setCreatorAccount(true);
                break;
              }
            }
          }
        } else {
          console.error("Error fetching notifications:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };
    fetchData();
  }, [currentPage]);

  function handleNotificationClick(notification) {
    switch (notification.type) {
      case "LotteryCreated":
        if (notification.lotteryName === "Missile") {
          history.push(`/missilelottery/${notification.genId}`);
        } else if (notification.lotteryName === "Mine") {
          history.push(`/minelottery/${notification.genId}`);
        } else if (notification.lotteryName === "MRL") {
          history.push(`/lottery/${notification.genId}`);
        } else {
          history.push("/spinnerlottery");
        }
        break;

      case "LotteryCreatedAdmin":
        if (notification.lotteryName === "Missile") {
          history.push(`/missilelottery/${notification.genId}`);
        } else if (notification.lotteryName === "Mine") {
          history.push(`/minelottery/${notification.genId}`);
        } else if (notification.lotteryName === "MRL") {
          history.push(`/lottery/${notification.genId}`);
        } else {
          history.push("/spinnerlottery");
        }
        break;
      case "RolloverHappened'":
        if (notification.lotteryName === "Missile") {
          history.push(`/missilelottery/${notification.genId}`);
        } else if (notification.lotteryName === "Mine") {
          history.push(`/minelottery/${notification.genId}`);
        } else if (notification.lotteryName === "MRL") {
          history.push(`/lottery/${notification.genId}`);
        } else {
          history.push("/spinnerlottery");
        }
        break;
      case "LotteryResult":
        if (adminAccount === true) {
          history.push(`/admin/lotterydetails/${notification.genId}`);
        } else if (creatorAccount === true && accountAddress === notification.owner) {
          history.push(`/creator/lotterydetails/${notification.genId}`);
        }else {
          history.push(`/lottery-result`);
        }
        break;
      case "SpinLotteryResult":
        if (adminAccount === true) {
          history.push(`/admin/lotterydetails/${notification.genId}`);
        } else if (creatorAccount === true && accountAddress === notification.owner) {
          history.push(`/creator/lotterydetails/${notification.genId}`);
        } else {
          history.push(`/lottery-result`);
        }
        break;
      case "LotteryWinner":
        if (adminAccount === true) {
          history.push(`/admin/lotterydetails/${notification.genId}`);
        } else if (creatorAccount === true && accountAddress === notification.owner) {
          history.push(`/creator/lotterydetails/${notification.genId}`);
        } else {
          history.push(`/lottery-result`);
        }
        break;
      case "GiftCardCreated":
        history.push(`/giftcard/${notification.genId}`);
        break;
      case "OrganisationAdded":
        history.push(`/admin/creatorslist`);
        break;
      case "LotteryBought":
        if (adminAccount === true) {
          history.push(`/admin/lotterydetails/${notification.genId}`);
        } else if (creatorAccount === true && accountAddress === notification.owner) {
          history.push(`/creator/lotterydetails/${notification.genId}`);
        } else if (accountAddress === notification.user) {
          history.push(`/profile`)
        }
        break;
      case "GiftCardPurchased":
        history.push(`/admin/giftcarddetails/${notification.genId}`);
        break;
      case "PartnerCreated":
        if (adminAccount === true) {
        history.push(`/admin/partnerslist`);
        } else {
          history.push(`/#partners`)
        }
        break;

      default:

        break;
    }
  }
  // console.log("check notifications", notifications);
  // console.log("account", accountAddress);
  return (
    <div id="notifications" className="mb-5">
      <NavBar />
      <Container>
        <Row className="justify-content-center">
          <Col lg={10}>
            <div className="notification-section">
              <h2
                className="section-title mb-3"
                style={{ fontWeight: "600", fontSize: "28px" }}
              >
                Notifications
              </h2>
              {notifications?.length > 0 ? (
                notifications.map((notification, index) => (
                  <div
                    className="notification_list mt-2"
                    key={index}
                    onClick={() => handleNotificationClick(notification)}
                  >
                    <div className="icon-section">
                      {notification.type === "OrganisationAdded" ? (
                        <AiOutlineUsergroupAdd className="notify_icon" />
                      ) : notification.type === "PartnerCreated" ? (
                        <PiHandshakeDuotone className="notify_icon" />
                      ) : notification.type === "LotteryBought" ? (
                        <FaRegMoneyBill1 className="notify_icon" />
                      ) : notification.type === "LotteryWinner" ? (
                        <GiPodiumWinner className="notify_icon" />
                      ) : notification.type === "GiftCardCreated" ? (
                        <PiGiftDuotone className="notify_icon" />
                      ) : notification.type === "WinnerPaid" ? (
                        <BsCashCoin className="notify_icon" />
                      ) : notification.type === "SpinLotteryResult" ? (
                        <GiPodiumWinner className="notify_icon" />
                      ) : notification.type === "PartnerPaid" ? (
                        <PiHandCoins className="notify_icon" />
                      ) : notification.type === "PlatformUpdate" ? (
                        <FaRegNewspaper className="notify_icon" />
                      ) : notification.type === "LotteryCreated" ? (
                        <BsTicketDetailed className="notify_icon" />
                      ) : notification.type === "LotteryCreatedAdmin" ? (
                        <BsTicketDetailed className="notify_icon" />
                      ) : notification.type === "GiftCardPurchased" ? (
                        <LiaGiftsSolid className="notify_icon" />
                      ) : notification.type === "LotteryResult" ? (
                        <GiPodiumWinner className="notify_icon" />
                      ) : (
                        <RiRefreshLine className="notify_icon" />
                      )}
                    </div>
                    <div className="details-section">
                      <p className="notification_title">{notification.title}</p>
                      <p className="notification_content">
                        {notification.content}
                      </p>
                      <p className="notification_time">
                        {formatTimeAgo(notification.createdOn)}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <h4>No notifications available</h4>
              )}
            </div>
          </Col>
          {totalPages > 1 && (
            <Col xs={12} className="m-0">
              <div className="paginationsection">
                <span className="noofpagetxt">
                  Showing {currentPage + 1} From {totalPages}
                </span>
                <div className="paginationallignment">
                  <ReactPaginate
                    previousLabel={<PreviousIcon className="paginationicon" />}
                    nextLabel={<NextIcon className="paginationicon" />}
                    breakLabel={"..."}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    breakClassName={"break-me"}
                    pageCount={totalPages}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
}

export default NotificationScreen;
