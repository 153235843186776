import React, { useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import { Form, Container, Row, Col, Modal, Button } from "react-bootstrap";
import ButtonCommon from "../../Components/ButtonCommon";
import { useHistory, useLocation } from "react-router-dom";
import NavBar from "../../Components/NavBar";
import cinstance from "../../Service/usercinstance";
import web3 from "../../Service/web3";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import BigNumber from "bignumber.js";
import { Path } from "../../../static/Constants";
import { min } from "moment";
import { networkID } from "../../../static/utils";
import FailedPopup from "../../Components/FailedPopup";
import Loaders from "../../Components/Loaders";

const RegisterAsorganisation = (props) => {
  const [name, setFirstname] = useState("");
  const [phoneno, setphoneno] = useState("");
  const [resiAddress, setResiaddress] = useState("");
  const [dob, setDob] = useState(new Date(moment().subtract("months", 18)));
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  let history = useHistory();
  let location = useLocation();
  const [error, seterror] = useState("");
  const [address, setAddress] = useState("");
  const [refaddress, setrefAddress] = useState("");
  const [loading, setloading] = useState(false);

  const [modalOpen, setmodalOpen] = useState(false);

  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState("");
  const [active, setactive] = useState(false);
  const [edit, setEdit] = useState(false);
  const [fees, setFees] = useState(1.25);
  const [minfees, setminFees] = useState(10);
  const [maxfees, setmaxFees] = useState(15);
  const feeMultiplier = new BigNumber(10).exponentiatedBy(18);
  const [showFail, setShowFail] = useState(false);

  const onSendmsg = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      setloading(true);
      setactive(true);
      event.preventDefault();
      setEdit(false);
      let account = await web3.eth.getAccounts();
      let dt = moment(dob).unix();
      let currentDT = moment().unix();
      let feeP = web3.utils.toWei(`${fees}`, "ether");
      let minFeesP = web3.utils.toWei(`${minfees}`, "ether");
      let maxFeesP = web3.utils.toWei(`${maxfees}`, "ether");
      // console.log("fee", feeP, minFeesP, maxFeesP);
      let currentNetworkID = await web3.eth.net.getId();
      let tradd =
        refaddress != ""
          ? refaddress
          : "0x0000000000000000000000000000000000000000";
      if (currentDT < dt) {
        alert("Wrong birth date!");
        event.preventDefault();
        setloading(false);
      } else if (minFeesP <= 0) {
        alert("Minimum fee should be greater than 0!");
        event.preventDefault();
        setloading(false);
      } else if (maxFeesP <= 0) {
        alert("Maximum fee should be greater than 0!");
        event.preventDefault();
        setloading(false);
      } else {
        if (currentNetworkID !== networkID) {
          alert("You are not in the correct network!");
          event.preventDefault();
          setloading(false);
        } else {
          cinstance.methods
            .addOrganisation(
              address,
              tradd,
              name,
              phoneno,
              dt,
              email,
              resiAddress,
              minFeesP,
              maxFeesP
            )
            .send({
              from: account[0],
              value: feeP,
            })
            .then((res) => {
              setmodalOpen(true);
              setloading(false);
              // history.push(Path.org);
            })
            .catch((error) => {
              setShowFail(true);
              setloading(false);
            });
        }
      }
    }
  };

  if (window.ethereum) {
    window.ethereum.on("accountsChanged", async () => {
      let account = await web3.eth.getAccounts();
      setAddress(account[0]);
    });
  }
  useEffect(() => {
    async function fetchData() {
      const queryParams = new URLSearchParams(location.search);
      const refAddressFromURL = queryParams.get("ref");

      if (refAddressFromURL) {
        setrefAddress(refAddressFromURL);
        // console.log("stored reg address", refaddress)
      }

      cinstance.methods
        .bregisterFee()
        .call()
        .then((response) => {
          setShow(response);
        });
      if (window.ethereum) {
        window.ethereum.on("accountsChanged", async () => {
          let account = await web3.eth.getAccounts();
          setAddress(account[0]);
        });
        window.web3 = web3;
        let account = await web3.eth.getAccounts();
        setAddress(account[0]);
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      <NavBar />
      {loading && <Loaders />}

      <Modal
        className="PopupStyle"
        show={modalOpen}
        centered
        onHide={() => setmodalOpen(false)}
      >
        <Modal.Body>
          <Container>
            <Row>
              <Col lg={12}>
                <div className="message-text">
                  Account activated successfully!
                </div>
              </Col>
              <Col lg={12} sm={12} xs={12}>
                <div className="btn-div">
                  <Button
                    className="submit-btn"
                    onClick={() => {
                      window.location.reload();
                      setmodalOpen(false);
                    }}
                  >
                    Close
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <section
        className="register-section "
        style={{ alignItems: "start", paddingTop: 80 }}
      >
        <div className="container  ">
          <div>
            <Row>
              <Col lg="12">
                <Card
                  className="Card-style"
                  style={{
                    margin: "auto",
                    maxWidth: "550px",
                    borderRadius: "20px",
                    padding: "20px",
                    boxShadow: "0 0 10px 0 rgb(0 0 0 / 15%)",
                  }}
                >
                  <h1
                    style={{
                      marginTop: 30,
                      fontSize: "28px",
                      fontWeight: 600,

                      textAlign: "center",
                    }}
                  >
                    Register As Creator
                  </h1>
                  <CardBody>
                    <Form noValidate validated={validated} onSubmit={onSendmsg}>
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Full name"
                            defaultValue="Mark"
                            value={name}
                            onChange={(event) =>
                              setFirstname(event.target.value)
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Enter Name
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Form.Label>Wallet Address</Form.Label>
                          <Form.Control required value={address} />
                          <Form.Control.Feedback type="invalid">
                            Connect to wallet
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Form.Label>Minimum Winning Amount</Form.Label>
                          <Form.Control
                            required
                            type="number"
                            value={minfees}
                            onChange={(event) => {
                              let mn = Number(event.target.value);
                              let mx = Math.ceil(
                                Number(event.target.value) +
                                  Number(event.target.value / 2)
                              );

                              setmaxFees(mx);
                              setminFees(mn);
                              if (mx !== "" && mn !== "") {
                                let minFeesP = web3.utils.toWei(
                                  `${mn}`,
                                  "ether"
                                );
                                let maxFeesP = web3.utils.toWei(
                                  `${mx}`,
                                  "ether"
                                );
                                let med = BigNumber(
                                  BigNumber(maxFeesP).plus(BigNumber(minFeesP))
                                ).dividedBy(2);
                                let regfee = BigNumber(
                                  BigNumber(med).multipliedBy(show)
                                ).dividedBy(100);
                                let regfeeP = web3.utils.fromWei(
                                  `${regfee}`,
                                  "ether"
                                );
                                setFees(regfeeP);
                              }
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Connect to wallet
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Form.Label>Maximum Winning Amount</Form.Label>
                          <Form.Control
                            required
                            value={maxfees}
                            type="number"
                            onChange={(event) => {
                              let mx = event.target.value;
                              let mn =
                                Number(event.target.value) -
                                Number(event.target.value / 3);
                              setmaxFees(mx);
                              setminFees(mn);
                              if (mx !== "" && mn !== "") {
                                let minFeesP = web3.utils.toWei(
                                  `${mn}`,
                                  "ether"
                                );
                                let maxFeesP = web3.utils.toWei(
                                  `${mx}`,
                                  "ether"
                                );
                                let med = BigNumber(
                                  BigNumber(maxFeesP).plus(BigNumber(minFeesP))
                                ).dividedBy(2);
                                let regfee = BigNumber(
                                  BigNumber(
                                    BigNumber(med).multipliedBy(show)
                                  ).dividedBy(100)
                                ).toFixed(0);
                                let regfeeP = web3.utils.fromWei(
                                  `${regfee}`,
                                  "ether"
                                );
                                setFees(regfeeP);
                              }
                            }}
                          />
                        </Form.Group>
                      </Form.Row>

                      <Form.Row className="register-date">
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Form.Label>Referral Address</Form.Label>
                          <Form.Control
                            value={refaddress}
                            placeholder="Referral address"
                            onChange={(event) => {
                              setrefAddress(event.target.value);
                            }}
                          />
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Form.Label>Date Of Birth</Form.Label>
                          <DateTimePicker
                            className="date-input"
                            format={"dd-MM-yy"}
                            maxDate={new Date(moment().subtract("months", 18))}
                            calendarIcon={null}
                            clearIcon={null}
                            disableClock={true}
                            onChange={(value) => setDob(value)}
                            value={dob}
                          />
                          {/* <Form.Control.Feedback type="invalid">
                            Enter Date Of Birth
                          </Form.Control.Feedback> */}
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Email"
                            defaultValue="Mark"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                          />
                          <Form.Control.Feedback type="invalid">
                            Enter Email
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Form.Label>Phone No</Form.Label>
                          <Form.Control
                            required
                            type="number"
                            placeholder="Phone no"
                            defaultValue="Mark"
                            value={phoneno}
                            onChange={(event) => setphoneno(event.target.value)}
                          />
                          <Form.Control.Feedback type="invalid">
                            Enter Phone no
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom01"
                        >
                          <Form.Label>Resident Address</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Resident address"
                            defaultValue="Mark"
                            value={resiAddress}
                            onChange={(event) =>
                              setResiaddress(event.target.value)
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Enter Resident Address
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>

                      <h6 className="mt-3 text-center">
                        Registration Fee {fees}ETH
                      </h6>
                      <div style={{ margin: "auto", textAlign: "center" }}>
                        <ButtonCommon
                          type="Submit"
                          className="signup-btn mt-2"
                          label="Sign Up"
                        />
                      </div>
                      <Row>
                        {error && (
                          <label
                            style={{
                              color: "red",
                              textAlign: "center",
                              margin: "20px",
                            }}
                          >
                            {error}
                          </label>
                        )}
                      </Row>
                      <Row style={{ textAlign: "center", marginTop: "20px" }}>
                        <label
                          style={{
                            color: "green",
                            textAlign: "center",
                            margin: "auto",
                          }}
                        >
                          {message}
                        </label>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <FailedPopup show={showFail} setShowModal={(e) => setShowFail(e)} />
    </div>
  );
};

export default RegisterAsorganisation;
