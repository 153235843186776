import React from 'react'
import './AboutStyles.css';
import { Container, Row, Col } from 'react-bootstrap';
import NavBar from "../../../Components/NavBar";

const About = () => {
    return (
        <div id="aboutscreen" className="mb-5">
            <NavBar />
            <Container>
                <h1 className="title mt-5" style={{textAlign:'left', fontWeight:'600'}}>Decentralized Lottery Ecosystem</h1>
                <p>AutoBET is online lottery platform fantasy of the ultimate lottery platfrom.</p>
                <Row>
                    <Col className="cardwrappercolumn" lg={4} md={6} sm={6} xs={12}>
                        <div className='cardWrapper'>
                            <p>Blockchain enabled lottery platform possessing the power of decentralisation makes it possible to withdraw the lone authority, making it a trustless system for all the parties involved.</p>
                        </div>
                    </Col>
                    <Col className="cardwrappercolumn" lg={4} md={6} sm={6} xs={12}>
                        <div className='cardWrapper'>
                            <p>Transparency in the platform helps users to take a look at all the records stored on blockchain such as number of participants, available numbers for the draw, genuine winners and historical data.</p>
                        </div>
                    </Col>
                    <Col className="cardwrappercolumn" lg={4} md={6} sm={6} xs={12}>
                        <div className='cardWrapper'>
                            <p>Lottery platform involves automation to reduce human involvement, which tends to make the system more secure and fair.</p>
                        </div>
                    </Col>
                    <Col className="cardwrappercolumn" lg={4} md={6} sm={6} xs={12}>
                        <div className='cardWrapper'>
                            <p>Platforms inherit flexibility in allowing users not just to participate in lottery but even allows them to become an organizer for lottery to create and earn through ticket sale.</p>
                        </div>
                    </Col>
                    <Col className="cardwrappercolumn" lg={4} md={6} sm={6} xs={12}>
                        <div className='cardWrapper'>
                            <p>Elegant interface with minimal and simple design makes the platform adaptable to users of traditional lottery systems.</p>
                        </div>
                    </Col>
                    <Col className="cardwrappercolumn" lg={4} md={6} sm={6} xs={12}>
                        <div className='cardWrapper'>
                            <p>Using a random number generator to draw the winning number, without any tampering or human involvement helps to deliver unbiased and fair decisions.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default About
