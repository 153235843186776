import React, { useState, useEffect } from "react";
import {
  Form,
  Container,
  Row,
  Col,
  Modal,
  Button,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Image,
  Dropdown,
} from "react-bootstrap";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import moment from "moment";
import { Path } from "../../static/Constants";
import web3 from "../Service/web3";
import cinstance from "../Service/randomcinstance";
import { networkID } from "../../static/utils";
import { LuBellDot, LuBell } from "react-icons/lu";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Notification from "./notification/Notification";
import LotteryDropdown from "./lottery-dropdown/LotteryDropdown";

const NavBar = () => {
  const [user, setUser] = useState("");
  const [modalOpen, setmodalOpen] = useState(false);
  const [content, setContent] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  
  const [notifications, setNotifications] = useState([]);
  
  let history = useHistory();
  const apiUrl = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    if (window.ethereum) {
      window.web3 = web3;
      window.ethereum.on("accountsChanged", () => {
        getuser();
      });
      getuser();
    }
  }, []);

  const getuser = async () => {
    let account = await web3.eth.getAccounts();
    if (account.length !== 0) {
      setUser(account[0]);
    }
  };

  const connectwalletpress = () => {
    if (window.ethereum) {
      window.ethereum.enable();

      web3.eth.net.getId().then((netId) => {
        if (netId == networkID) {
        } else {
          setmodalOpen(true);
          setContent(`Please Connect to Main net (Network Id ${networkID})`);
        }
      });
    } else {
      setmodalOpen(true);
      setContent(
        "Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!"
      );
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200) {
        setIsDropdownVisible(true);
      } else {
        setIsDropdownVisible(false);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchData = async () => {
    // console.log("called fetch");
    try {
      const getNotificationsFromLocalStorage = () => {
        const savedNotifications = localStorage.getItem("notifications");
        if (savedNotifications) {
          setNotifications(JSON.parse(savedNotifications));
        }
      };
      let account = await web3.eth.getAccounts();
      const response = await fetch(`${apiUrl}/notifications?limit=5&skip=0`, {
        method: "GET",
        headers: {
          Authorization: account[0],
        },
      });
      if (response.ok) {
        const data = await response.json();
        setNotifications(data);
        localStorage.setItem("notifications", JSON.stringify(data));
      } else {
        console.error("Error fetching notifications:", response.statusText);
        getNotificationsFromLocalStorage();
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    const fetchWSData = async () => {
      try {
        let account = await web3.eth.getAccounts();
        let ws = new WebSocket("wss://api.autobetlottery.com/ws");
        ws.onopen = () => {
          // console.log("connected");
          ws.send(JSON.stringify({ id: account[0] }));
          ws.onmessage = (e) => {
            let resp = JSON.parse(e.data);
            // console.warn("check web socket", resp);
            const storedWSNotifications =
              localStorage.getItem("wsnotifications") || "[]";
            const wsnotifications = JSON.parse(storedWSNotifications);
            if (!wsnotifications.includes(resp.title)) {
              wsnotifications.push(resp.title);
              localStorage.setItem(
                "wsnotifications",
                JSON.stringify(wsnotifications)
              );
              toast.success(resp.title, {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: false,
                onClose: () => {
                  const updatedWSNotifications = wsnotifications.filter(
                    (item) => item !== resp.title
                  );
                  localStorage.setItem(
                    "wsnotifications",
                    JSON.stringify(updatedWSNotifications)
                  );
                },
              });
            }
          };
        };
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchWSData();
  }, []);
  
  return (
    <Navbar collapseOnSelect expand="xl">
      <Nav className="mr-auto row">
        <Nav.Link href={Path.root} className="Logo ml-sm-3 ml-0">
          <Image
            onClick={() => history.push("/")}
            src={require("../../static/Images/logo.png").default}
            style={{
              width: 50,
              height: 50,
              cursor: "pointer",
              marginRight: "5px",
            }}
          />
          <span style={{ marginBottom: "6px" }}>
            <p
              style={{ color: "rgba(0,0,0,.5)", fontSize: "20px" }}
              className="autobetLg"
            >
              AutoBET -{" "}
              <p className="lotteryEngine">
                Decentralized Lottery Ecosystem for Fundraising
              </p>
            </p>
          </span>
        </Nav.Link>
      </Nav>
      {(user !== "" && (
        <Image
          className="mx-2 d-block d-xl-none"
          onClick={() => history.push("/profile")}
          href={Path.profile}
          src={require("../../static/Images/profile.svg").default}
          style={{ cursor: "pointer" }}
        />
      )) || (
          <div
            // onClick={() => window.ethereum.enable()}
            onClick={() => connectwalletpress()}
            className=" mybtn1 d-block d-xl-none"
          >
            {"Connect"}
          </div>
        )}
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse className="justify-content-end navbarcollapse">
        <Nav>
          <Nav.Link className="hovernav">
            {isDropdownVisible ? (
          <LotteryDropdown />
            ) : (
              <Dropdown
              className="lotteryDp"
              onMouseEnter={() => setIsDropdownOpen(true)}
              onMouseLeave={() => setIsDropdownOpen(false)}
            >
              <Dropdown.Toggle id="dropdown-autoclose-true">
              Explosive Lotteries
              </Dropdown.Toggle>
              <Dropdown.Menu show={isDropdownOpen}>
                <Dropdown.Item
                  className={
                    window.location.pathname.includes(Path.alllottery)
                      ? "active"
                      : ""
                  }
                  as={Link}
                  to={Path.alllottery}
                >
                  {" "}
                  Autobet MRL
                </Dropdown.Item>
                <Dropdown.Item
                  className={
                    window.location.pathname.includes(Path.Spinnerlottery)
                      ? "active"
                      : ""
                  }
                  as={Link}
                  to={Path.Spinnerlottery}
                >
                  Autobet Revolver
                </Dropdown.Item>
                <Dropdown.Item
                  className={
                    window.location.pathname.includes(Path.allminelottery)
                      ? "active"
                      : ""
                  }
                  as={Link}
                  to={Path.allminelottery}
                >
                  Autobet Mine
                </Dropdown.Item>
                <Dropdown.Item
                  className={
                    window.location.pathname.includes(Path.allmissilelottery)
                      ? "active"
                      : ""
                  }
                  as={Link}
                  to={Path.allmissilelottery}
                >
                  Autobet Missile
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            )}
          </Nav.Link>
          <Nav.Link
            className={`hovernav ${window.location.pathname.includes(Path.allgiftcards)
              ? "activenav"
              : ""
              }`}
            href={Path.allgiftcards}
          >
            Gift Cards
          </Nav.Link>
          <Nav.Link
            className={`hovernav ${window.location.pathname.includes(Path.result) ? "activenav" : ""
              }`}
            href={Path.result}
          >
            Results
          </Nav.Link>
          <Nav.Link
            className={`hovernav ${window.location.pathname.includes(Path.org) ? "activenav" : ""
              }`}
            href={Path.org}
          >
            Lottery Creator
          </Nav.Link>
          <Nav.Link
            className={`hovernav ${window.location.pathname.includes(Path.news) ? "activenav" : ""
              }`}
            href={Path.news}
          >
            News
          </Nav.Link>
          <Nav.Link
            className={`hovernav ${window.location.pathname.includes(Path.help) ? "activenav" : ""
              }`}
            href={Path.help}
          >
            Help
          </Nav.Link>
          <Nav.Link
            className={`hovernav ${window.location.pathname.includes(Path.about) ? "activenav" : ""
              }`}
            href={Path.about}
          >
            About Us
          </Nav.Link>
          <Nav.Link className="hovernav">
            {isDropdownVisible ? (
              <Dropdown
                alignRight={true}
                onToggle={(e) => {
                  if (e) fetchData();
                }}
              >
                <Dropdown.Toggle id="dropdown-autoclose-true">
                  <LuBellDot className="bell-icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Notification notifications={notifications} />
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <div
                className="bell-icon"
                onClick={() => {
                  history.push("/notifications");
                  fetchData();
                }}
              >
                <LuBellDot />
              </div>
            )}
          </Nav.Link>

          {user === "" && (
            <Nav.Link>
              <div
                // onClick={() => window.ethereum.enable()}
                onClick={() => connectwalletpress()}
                className=" mybtn1 d-none d-xl-block"
              >
                {"Connect"}
              </div>
            </Nav.Link>
          )}
          {user !== "" && (
            <Nav.Link href={Path.profile} className="accNAdd">
              <span class="AccountAdress" style={{ color: "unset" }}>
                {`${user.substring(0, 2)}.... ${user.substring(
                  user.length - 4,
                  user.lenght
                )}`}
              </span>
              <Image
                className="mx-2"
                onClick={() => history.push("/profile")}
                href={Path.profile}
                src={require("../../static/Images/profile.svg").default}
                style={{ cursor: "pointer" }}
              />
            </Nav.Link>
          )}
        </Nav>
      </Navbar.Collapse>
      <Modal
        className="PopupStyle"
        show={modalOpen}
        centered
        onClose={() => setmodalOpen(false)}
      >
        <Modal.Body>
          <Container>
            <Row>
              <Col lg={12}>
                <div className="message-text">{content}</div>
                <p className="link-textt">
                  <a href="/help" style={{ color: "blue" }}>
                    Get Help
                  </a>
                </p>
              </Col>
              <Col xs={12}>
                <div className="btn-div">
                  <Button
                    className="submit-btn"
                    onClick={() => {
                      setmodalOpen(false);
                    }}
                  >
                    OK
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <ToastContainer autoClose={5000} />
    </Navbar>
  );
};

export default NavBar;
