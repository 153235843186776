import React, { Component } from "react";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import cinstance from "../../Service/randomcinstance";
import web3 from "../../Service/web3";
import AdminNavBar from "../../Components/AdminNavBar";
import moment from "moment";
import Lotterydetails from "../../Components/Lotterydetails";
import ShareIcon from "../../../static/Images/share.png";
import { Nav, NavItem, NavLink } from "reactstrap";

class LotteryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seed: "",
      loading: false,
      Randomresult: "",
      winner: "",
      lotterylist: [],
      datelist: [],
      dropdownOpen: false,
      show: false,
      Lotteriestypes: "Open Lotteries",
      lotteryTypeMapper: {
        0: "Autobet Revolver",
        1: "Autobet Mine",
        2: "Autobet MRL",
        3: "Autobet Missile",
      },
      resultStatusTypeMapper: {
        0: "Open",
        1: "Close",
        2: "Result Done",
        3: "Rollover",
        4: "Blocked",
        5: "Rollover Open",
        6: "Creation in progress",
      },
      activeTab: "1",
    };
  }

  async componentDidMount() {
    var lotterylist = [];
    var datelist = [];
    if (window.ethereum) {
      window.web3 = web3;
      cinstance.methods
        .admin()
        .call()
        .then(async (p) => {
          let account = await web3.eth.getAccounts();
          if (p == account[0]) this.setState({ show: true });
          cinstance.methods
            .lotteryId()
            .call()
            .then((res) => {
              for (var i = res - 1; i > 0; i--) {
                cinstance.methods
                  .lottery(i)
                  .call()
                  .then((response) => {
                    lotterylist.push(response);
                    this.setState({ lotterylist });
                    cinstance.methods
                      .lotteryDates(response.lotteryId)
                      .call()
                      .then((responsee) => {
                        datelist.push(responsee);
                        this.setState({ datelist });
                      })
                      .catch((err) => console.log("error", err));
                  })
                  .catch((err) => console.log("pepep", err));
              }
            })
            .catch((err) => console.log("res", err));
        });
    } else {
      alert("Connect to wallet");
    }
  }
  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  render() {
    const { lotterylist, datelist, show, activeTab, Lotteriestypes } =
      this.state;

    const mergeArray = (lotterylist, datelist, prop) => {
      datelist?.forEach((datelistElement) => {
        let lotterylistElement = lotterylist.find((lotterylistElement) => {
          return datelistElement[prop] === lotterylistElement[prop];
        });
        lotterylistElement
          ? Object.assign(lotterylistElement, datelistElement)
          : lotterylist.push(datelistElement);
      });
    };

    mergeArray(lotterylist, datelist, "lotteryId");

    const filteredLotteryList = lotterylist
      ?.filter(
        (item) =>
          ((item.status == "0" || item.status === "5") && activeTab === "1") ||
          ((item.status == "2" || item.status == "1") && activeTab === "2") ||
          (item.status == "3" && activeTab === "3") ||
          (item.status == "6" && activeTab === "4")
      )
      .sort((a, b) => b.lotteryId - a.lotteryId);

    return (
      <>
        <AdminNavBar />
        {!show && (
          <Container className="bgImage  p-o">
            <div className="mspace mt-5">
              <h2>Not an admin</h2>
            </div>
          </Container>
        )}
        {show && (
          <Container className="bgImage  p-o m-0" fluid>
            <div className="mspace1">
              <h3
                className="text-center section-title mt-2"
                style={{ fontWeight: "600", fontSize: "28px", color: "#444" }}
              >
                {" "}
                Lotteries List
              </h3>

              <Row>
                <Col className="adminlotteryList">
                  <div className="text-left">
                    <div className="content">
                      {lotterylist.length == 0 && (
                        <tr>
                          <td colspan={8} style={{ textAlign: "center" }}>
                            {" "}
                            <h4> No Data !</h4>{" "}
                          </td>
                        </tr>
                      )}
                      {lotterylist.length !== 0 && (
                        <>
                          <Row className="mx-auto ml-4 justify-content-between">
                            <Nav tabs className="lottery-tabs">
                              <NavItem>
                                <NavLink
                                  className={activeTab === "1" ? "active" : ""}
                                  onClick={() => {
                                    this.toggleTab("1");
                                  }}
                                >
                                  Open Lotteries
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={activeTab === "2" ? "active" : ""}
                                  onClick={() => {
                                    this.toggleTab("2");
                                  }}
                                >
                                  Closed Lotteries
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={activeTab === "3" ? "active" : ""}
                                  onClick={() => {
                                    this.toggleTab("3");
                                  }}
                                >
                                  Rollover Closed Lotteries
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={activeTab === "4" ? "active" : ""}
                                  onClick={() => {
                                    this.toggleTab("4");
                                  }}
                                >
                                  Creation In Progress
                                </NavLink>
                              </NavItem>
                            </Nav>
                          </Row>
                          <Row className="mx-auto mt-5">
                            {filteredLotteryList.length === 0 && (
                              <Col lg={12}>
                                <h4>No Data!</h4>
                              </Col>
                            )}
                            {filteredLotteryList.map((item) => (
                              <Col
                                lg="4"
                                md="6"
                                className="lotterylistcard"
                                key={item.lotteryId}
                              >
                                <Lotterydetails
                                  item={item}
                                  state={this.state}
                                  history={this.props.history}
                                  userRole={"admin"}
                                />
                              </Col>
                            ))}
                          </Row>
                        </>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        )}
      </>
    );
  }
}
export default LotteryList;
