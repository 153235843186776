import React, { Component } from "react";
import { Container, Col, Row, Modal, Button } from "react-bootstrap";
import cinstance from "../../Service/randomcinstance";
import web3 from "../../Service/web3";
import axios from "axios";
import getImageUrlByHash from "../../../static/logoUtils";
import NavBar from "../../Components/NavBar";
import moment from "moment";
import { Path } from "../../../static/Constants";
import Loaders from "../../Components/Loaders";
import Warnings from "../../Components/Warning";
import SuccessPopup from "../../Components/SuccessPopup";
import FailedPopup from "../../Components/FailedPopup";
import SpinWheel from "../../Components/SpinWheel/SpinWheel";
import Mine from "../../Components/mine/Mine";
import Missile from "../../Components/missile/Missile";

var md5 = require("md5");

class Buylottery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seed: "",
      loading: true,
      Randomresult: "",
      error: false,
      winner: "",
      selectednum: [],
      lotteryboughts: [],
      lotterylist: { entryFee: 0, totalPrize: 0 },
      showFailed: false,
      showSuccess: false,
      lotterytype: "",
      partnerDetails: {},
      partnerLogoData: [],
    };
  }
  async componentDidMount() {
    const apiUrl = process.env.REACT_APP_API_KEY;
    if (window.ethereum) {
      window.web3 = web3;
      let lotteryboughts = [];
      let account = await web3.eth.getAccounts();
      if (account.length !== 0) {
        cinstance.methods
          .lottery(this.props.match.params.id)
          .call()
          .then((response) => {
            this.setState({
              lotterylist: response,
              loading: false,
              lotterytype: response.lotteryType,
            });
            if (response.lotteryType != "1") {
              cinstance.methods
                .partnerbyaddr(response.partnerAddress)
                .call()
                .then((partnerDetails) => {
                  this.setState({ partnerDetails });
                })
                .catch((err) => console.log("error", err));
              cinstance.methods
                .lotteryDates(this.props.match.params.id)
                .call()
                .then((responsee) => {
                  this.setState({ datelist: responsee });
                })
                .catch((err) => console.log("error", err));
              cinstance.methods
                .partnerbyaddr(response.partnerAddress)
                .call()
                .then((responsee) => {
                  this.setState({ partnerlists: responsee });
                })
                .catch((err) => console.log("error", err));
            }
          })
          .catch((err) => console.log("pepep", err));
      } else {
        this.setState({ error: true });
      }
    } else {
      this.setState({ error: true });
    }

    axios.get(`${apiUrl}/s3imageupload/getAllPartnerLogos`).then((response) => {
      const partnerData = response.data.result;
      this.setState({ partnerLogoData: partnerData})
    });
  }

  buy = async () => {
    if (window.ethereum) {
      window.web3 = web3;
      this.setState({ loading: true });
      let account = await web3.eth.getAccounts();
      let selectedNUM = [];
      this.state.selectednum.map((ids) => {
        selectedNUM.push(`${ids}`);
      });

      let hash = md5(
        `${this.state.selectednum}lottery${this.props.match.params.id}`
      );
      if (account.length != 0)
        cinstance.methods
          .buyNormalLottery(this.state.selectednum, this.props.match.params.id)
          .send({ from: account[0], value: this.state.lotterylist.entryFee })
          .then((res) => {
            this.setState({ loading: false });
            this.setState({ showSuccess: true });
          })
          .catch((er) => {
            this.setState({ loading: false });
            this.setState({ showFailed: true });
          });
      else {
        window.ethereum.enable();
      }
    }
  };
  render() {
    const {
      loading,
      lotterylist,
      datelist,
      partnerlists,
      selectednum,
      error,
      lotteryboughts,
      lotterytype,
      partnerDetails,
      partnerLogoData
    } = this.state;

    const imageUrl = getImageUrlByHash(
      partnerlists?.logoHash,
      partnerLogoData
    );
    return (
      <React.Fragment>
        {lotterytype == "1" ? (
          <Mine {...this.props} />
        ) : lotterytype == "3" ? (
          <Missile {...this.props} />
        ) : (
          <div className="Autobet-MRL">
            {loading && <Loaders />}
            {error && <Warnings />}
            <NavBar />
            <Container style={{ minHeight: "95vh" }}>
              <Row className=" mb-4 mx-auto justify-content-center">
                <Col lg="12">
                  <div className=" mb-3 justify-content-center">
                    <h3 className="mb-6 mt-4 font-weight-bold text-danger">
                      {" "}
                      Get Chance to win '
                      {web3.utils.fromWei(`${lotterylist.totalPrize}`, "ether")}
                      ' ETH
                    </h3>
                    {lotterylist?.status == 0 && lotterytype == "0" ? (
                      <Col lg="6" className={"mt-4 mb-4 mx-auto"}>
                        <SpinWheel
                          lotteryData={lotterylist}
                          partner={partnerDetails}
                        />
                      </Col>
                    ) : lotterylist?.status == 2 ? (
                      <Col lg="8" className={" mx-auto "}>
                        <div class=" w-100 " style={{ height: "100%" }}>
                          <div class="  text-center ">
                            <h5 class="text-danger  font-weight-bold  mt-4 mb-4">
                              Lottery Winner is {lotterylist?.lotteryWinner}
                            </h5>
                          </div>
                        </div>
                      </Col>
                    ) : (
                      lotterytype == "0" && (
                        <Col lg="4 mb-5" className={" mx-auto "}>
                          <div class="buy-card  " style={{ height: "100%" }}>
                            <div class="card-body text-center ">
                              <h5 class="text-danger  font-weight-bold p-4 mt-4 mb-4">
                                Lottery Not Available !!
                              </h5>
                            </div>
                          </div>
                        </Col>
                      )
                    )}
                    {lotterytype == "2" && (
                      <>
                        <Col lg="6" className={"mt-4 mb-4 mx-auto"}>
                          <div class="buy-card bgcol p-0 mb-4 ">
                            <div class="card-body p-2 ">
                              <p class="text-light text-center">
                                Draw Date :{" "}
                                {moment
                                  .unix(datelist?.endTime)
                                  .format("DD/MM/YYYY hh:mma")}{" "}
                                {/* -{" "}
                                {moment
                                  .unix(datelist?.endTime)
                                  .format("DD/MM/YYYY hh:mma")} */}
                              </p>
                            </div>
                          </div>
                        </Col>
                        {lotterylist.status != 2 && (
                          <Col lg="6 m-5" className=" card-inner p-4 mx-auto">
                            <h4 className="mt-2 mb-2 p-2 text-danger">
                              Pick {lotterylist.pickNumbers} numbers
                            </h4>
                            <Row className="m-2 ">
                              <img
                                src={imageUrl}
                                className="partnerlogo"
                              />
                              <ul className="number-list ">
                                {Array.from(
                                  { length: lotterylist.capacity },
                                  (i, b) => (
                                    <li
                                      key={i}
                                      onClick={() => {
                                        if (
                                          selectednum.length ==
                                            lotterylist.pickNumbers &&
                                          !selectednum.includes(b + 1)
                                        ) {
                                          selectednum[
                                            lotterylist.pickNumbers - 1
                                          ] = b + 1;
                                          this.setState({ selectednum });
                                        } else {
                                          if (selectednum.includes(b + 1))
                                            this.setState({
                                              selectednum: selectednum.filter(
                                                (item) => item != b + 1
                                              ),
                                            });
                                          else
                                            this.setState({
                                              selectednum: [
                                                ...selectednum,
                                                b + 1,
                                              ],
                                            });
                                        }
                                      }}
                                      className={`${
                                        selectednum.includes(b + 1)
                                          ? "active"
                                          : " "
                                      } `}
                                    >
                                      {b + 1}
                                    </li>
                                  )
                                )}
                              </ul>
                            </Row>
                          </Col>
                        )}
                        {lotterylist.status == 2 && (
                          <Col lg="4 mb-5" className={" mx-auto "}>
                            <div class="buy-card  " style={{ height: "100%" }}>
                              <div class="card-body text-center ">
                                <h5 class="text-danger  font-weight-bold p-4 mt-4 mb-4">
                                  Lottery Not Available !!
                                </h5>
                              </div>
                            </div>
                          </Col>
                        )}

                        {lotterylist.status != 2 && (
                          <Col lg="6 mb-5" className={"bottom-section mx-auto"}>
                            <div class="card-inner  ">
                              <div class="card-body text-center p-0 ">
                                <h5 className=" text-danger  font-weight-bold mb-4">
                                  Expected Draw date{" "}
                                  {moment
                                    .unix(datelist?.drawTime)
                                    .format("DD/MM/YYYY hh:mma")}
                                </h5>
                                <Button
                                  // variant="danger"
                                  onClick={this.buy}
                                  style={{
                                    cursor: "pointer",
                                    height: "auto",
                                    width: "230px",
                                  }}
                                  className={
                                    selectednum.length !=
                                    lotterylist.pickNumbers
                                  }
                                  disabled={
                                    selectednum.length !=
                                    lotterylist.pickNumbers
                                  }
                                >
                                  {lotterylist && lotterylist.entryFee && (
                                    <b style={{ wordBreak: "break-all" }}>
                                      {" "}
                                      Buy for{" "}
                                      {web3.utils.fromWei(
                                        `${lotterylist.entryFee}`,
                                        "ether"
                                      )}{" "}
                                      ETH
                                    </b>
                                  )}
                                </Button>
                              </div>
                            </div>
                          </Col>
                        )}
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>

            <SuccessPopup
              show={this.state.showSuccess}
              setShowModal={() => this.setState({ showSuccess: false })}
              message={"Lottery bought succesfully !"}
              redirectPath={Path.profile}
              btnName={"Go to profile"}
            />

            <FailedPopup
              show={this.state.showFailed}
              setShowModal={() => this.setState({ showFailed: false })}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Buylottery;
