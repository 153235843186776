import React from 'react';
import { Row, Col, Container, Button, Modal, } from "react-bootstrap";
import { useHistory } from "react-router";
import "../../static/css/popup.css"

const FailedPopup = ({ show, setShowModal, lotteryType }) => {
  let history = useHistory();
  return (
    <Modal
        className={lotteryType === "revolver"? "RevolverPopup PopupStyle" : "PopupStyle"}
        show={show}
        onHide={() => {
          if (lotteryType === "revolver") {
            setShowModal(false);
            window.location.reload();
            history.push(`/`);
          } else {
            setShowModal(false);
          }
        }}
        centered
      >
        <Modal.Body>
          <Container>
            <Row>
              <Col lg={12}>
                <div className="message-text">Oops something went wrong !</div>
              </Col>
              <Col lg={12} sm={12} xs={12}>
                {lotteryType === "revolver" ? ( <div className="btn-div">
                  <Button
                    className="submit-btn"
                    onClick={() => {setShowModal(false); history.push(`/`);  window.location.reload();}}
                  >
                    Close
                  </Button>
                </div>):(
                <div className="btn-div">
                  <Button
                    className="submit-btn"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </Button>
                </div>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
  )
}

export default React.memo(FailedPopup);