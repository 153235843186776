import React from "react";
import { Container, Col, Row, Card, Image } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import HowImage from "../../static/Images/howitworks-Image.svg";
import How1 from "../../static/Images/how1.svg";
import Calendar from "../../static/Images/calender.svg";
import WinPrize from "../../static/Images/winprize.svg";

const HowToPlay = ({ item, history }) => {
  return (
    <div className="howitworks mb-4">
      <Container style={{ paddingBottom: "30px" }}>
        <Row>
          <Container>
            <Row className="justify-content-center">
              <Col xs={12}>
                <h2
                  className="text-center section-title"
                  style={{ color: "#444", fontWeight: "600" }}
                >
                  How it works
                </h2>
              </Col>
            </Row>
            <Row className="mx-auto align-items-center pt-2">
              <Col lg={6}>
                <Fade bottom>
                  <img src={HowImage} className="howitworks-img" />
                </Fade>
              </Col>

              <Col lg={6} className="howitworks-col">
                <Fade bottom>
                  <Card className="how-works-card">
                    <Card.Img src={Calendar} className="cardImg" />
                    <div className="right-section">
                      <p className="title">Select lottery</p>
                      <p className="desc">
                        When choosing a lottery ticket, consider a mix of
                        personal numbers and random picks. Avoid common
                        combinations, as sharing a jackpot is less fun.
                      </p>
                    </div>
                  </Card>
                </Fade>
                <Fade bottom>
                  <Card className="how-works-card">
                    <Card.Img src={How1} className="cardImg" />
                    <div className="right-section">
                      <p className="title">Pick Numbers</p>
                      <p className="desc">
                        Mix it up when picking numbers—combine personal ones
                        like birthdays with random selections.
                      </p>
                    </div>
                  </Card>
                </Fade>
                <Fade bottom>
                  <Card className="how-works-card">
                    <Card.Img src={WinPrize} className="cardImg" />
                    <div className="right-section">
                      <p className="title">Win Prize</p>
                      <p className="desc">
                        Celebrate your lottery win with joy hare the news with
                        close ones, plan a modest celebration, and consider the
                        long-term implications of your newfound luck.
                      </p>
                    </div>
                  </Card>
                </Fade>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    </div>
  );
};
export default HowToPlay;
