import React from "react";
import { Container, Col, Row, Card, Image } from "react-bootstrap";
import "./counter.css";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import Automated from "../../static/Images/Automated.png";
import Decentralized from "../../static/Images/Decentralized.png";
import Flexible from "../../static/Images/Flexible.png";
import Transparent from "../../static/Images/Transparent.png";
import Unbaised from "../../static/Images/Unbiased.png";
import UserFriendly from "../../static/Images/User- friendly.png";


const Whyus = ({}) => {
  const data = [
    {
      id: 1,
      title: "Decentralized",
      desc: "Blockchain enabled lottery platform possessing the power of decentralisation makes it possible to withdraw the lone authority, making it a trustless system for all the parties involved.",
      icon: Decentralized,
    },
    {
      id: 2,
      title: "Flexible",
      desc: "Platforms inherit flexibility in allowing users not just to participate in lottery but even allows them to become an organizer for lottery to create and earn through ticket sale.",
      icon: Flexible,
    },
    {
      id: 3,
      title: "Transparent",
      desc: "Transparency in the platform helps users to take a look at all the records stored on blockchain such as number of participants, available numbers for the draw, genuine winners and historical data.",
      icon: Transparent,
    },
    {
      id: 4,
      title: "User- friendly",
      desc: "Elegant interface with minimal and simple design makes the platform adaptable to users of traditional lottery systems.",
      icon: UserFriendly,
    },
    {
      id: 5,
      title: "Automated",
      desc: "Lottery platform involves automation to reduce human involvement, which tends to make the system more secure and fair.",
      icon: Automated,
    },
    {
      id: 6,
      title: "Unbiased",
      desc: "Using a random number generator to draw the winning number, without any tampering or human involvement helps to deliver unbiased and fair decisions.",
      icon: Unbaised,
    },
  ];
  return (
    <div className="whyus">
 <Container className={"section-padding"}>
      <Row className="mx-auto   text-center">
        <Col xs={12}>
          <h2
            className="text-center section-title"
            style={{ fontWeight: "600", color: "#444" }}
          >
            Why us
          </h2>
        </Col>
      </Row>
        <Row className="mt-2 pt-4 whyus-row">
          {data.map((item) => (
            <Col md={6} sm={12} className="whyus-col">
              <Fade bottom>
                <Card className="whyus-card">
                  <Card.Img
                    src={item.icon}
                    className="cardImg"
                  />
                  <div className="right-section">
                    <p className="title">{item.title}</p>
                    <p className="desc">{item.desc}</p>
                  </div>
                </Card>
              </Fade>
            </Col>
          ))}
          {/* <FadeCards
            src={require("../../static/Images/Decentralized.svg")}
            title={"Decentralized"}
            description={
              "Blockchain enabled lottery platform possessing the power of decentralisation makes it possible to withdraw the lone authority, making it a trustless system for all the parties involved."
            }
          />

          <FadeCards
            src={require("../../static/Images/Transparent.svg")}
            title={"Transparent"}
            description={
              "Transparency in the platform helps users to take a look at all the records stored on blockchain such as number of participants, available numbers for the draw, genuine winners and historical data."
            }
          />
          <FadeCards
            src={require("../../static/Images/Automated 2.svg")}
            title={"Automated"}
            description={
              "Lottery platform involves automation to reduce human involvement, which tends to make the system more secure and fair."
            }
          />

          <FadeCards
            src={require("../../static/Images/Flexible.svg")}
            title={"Flexible"}
            description={
              "Platforms inherit flexibility in allowing users not just to participate in lottery but even allows them to become an organizer for lottery to create and earn through ticket sale."
            }
          />
          <FadeCards
            src={require("../../static/Images/User-friendly.svg")}
            title={"User-friendly"}
            description={
              "Elegant interface with minimal and simple design makes the platform adaptable to users of traditional lottery systems."
            }
          />

          <FadeCards
            src={require("../../static/Images/Unbaised 1.svg")}
            title={"Unbiased"}
            description={
              "Using a random number generator to draw the winning number, without any tampering or human involvement helps to deliver unbiased and fair decisions."
            }
          /> */}
        </Row>
    </Container>
    </div>
  );
};
export default Whyus;
