import React, { useState, useEffect } from "react";
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import ReactPaginate from "react-paginate";
import cinstance from "../../Service/randomcinstance";
import giftcardcinstance from "../../Service/giftcardcinstance";
import web3 from "../../Service/web3";
import moment from "moment";
import axios from "axios";
import AdminNavBar from "../../Components/AdminNavBar";
import Lotterydetails from "../../Components/Lotterydetails";
import Loaders from "../../Components/Loaders";
import MyGiftCard from "../../Components/my-gift-card/MyGiftCard";
import { ReactComponent as PreviousIcon } from "../../../static/Images/previous.svg";
import { ReactComponent as NextIcon } from "../../../static/Images/next.svg";

const AdminGiftCardList = (props) => {
  const [loading, setLoading] = useState(true);
  const [giftcardList, setGiftCardList] = useState([]);
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const currentDT = moment().unix();
  const [currentPage, setCurrentPage] = useState(0);
  const ITEMS_PER_PAGE = 6;
  const [giftcardLogoData, setGiftcardLogoData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchData = async () => {
      try {
        var fetchedGiftCardList = [];
        if (window.ethereum) {
          window.web3 = web3;
          const p = await cinstance.methods.admin().call();
          let account = await web3.eth.getAccounts();
          if (p === account[0]) setShow(true);
          const res = await giftcardcinstance.methods.giftCardId().call();
          for (var i = 0; i <= res; i++) {
            const response = await giftcardcinstance.methods
              .giftCards(i)
              .call();
            fetchedGiftCardList.push(response);
          }
          setGiftCardList(fetchedGiftCardList);
        } else {
          alert("Connect to wallet");
        }
      } catch (err) {
        console.log("Error", err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const filteredGiftCards = giftcardList
    ?.filter(
      (item) =>
        (item.isSold === false &&
          item.expDate > currentDT &&
          activeTab === "1") ||
        (item.isSold === true && activeTab === "2") ||
        (item.isSold === false && item.expDate < currentDT && activeTab === "3")
    )
    .sort((a, b) => b.giftCardId - a.giftCardId);

  const pageCount = Math.ceil(filteredGiftCards.length / ITEMS_PER_PAGE);
  const offset = currentPage * ITEMS_PER_PAGE;
  const currentItems = filteredGiftCards?.slice(
    offset,
    offset + ITEMS_PER_PAGE
  );

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    axios.get(`${apiUrl}/giftcardlogos/getAllGiftcardLogos`).then((response) => {
      const giftcardData = response.data.result;
      setGiftcardLogoData(giftcardData);
    });
  }, []);


  return (
    <>
      {loading && <Loaders />}
      <AdminNavBar />
      {!show && (
        <Container className="bgImage p-o">
          <div className="mspace mt-5">
            <h2>Not an admin</h2>
          </div>
        </Container>
      )}
      {show && (
        <Container className="bgImage p-o m-0" fluid>
          <div className="mspace1">
            <h3
              className="text-center section-title mt-2"
              style={{ fontWeight: "600", fontSize: "28px", color: "#444" }}
            >
              Gift Cards List
            </h3>

            <Row>
              <Col className="adminlotteryList">
                <div className="text-left">
                  <div className="content">
                    {giftcardList.length === 0 && (
                      <tr>
                        <td colSpan={8} style={{ textAlign: "center" }}>
                          <h4> No Data !</h4>
                        </td>
                      </tr>
                    )}
                    {giftcardList.length !== 0 && (
                      <Row className="mx-auto ml-4 justify-content-between">
                        <Nav tabs className="lottery-tabs">
                          <NavItem>
                            <NavLink
                              className={activeTab === "1" ? "active" : ""}
                              onClick={() => {
                                toggleTab("1");
                              }}
                            >
                              Created Gift Cards
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={activeTab === "2" ? "active" : ""}
                              onClick={() => {
                                toggleTab("2");
                              }}
                            >
                              Redeemed Gift Cards
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={activeTab === "3" ? "active" : ""}
                              onClick={() => {
                                toggleTab("3");
                              }}
                            >
                              Expired Gift Cards
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Row>
                    )}

                    <Row className="mx-auto mt-5">
                      {giftcardList.length !== 0 &&
                        currentItems.length === 0 && (
                          <Col className="m-0">
                            <h4> No Data !</h4>
                          </Col>
                        )}
                      {giftcardList.length !== 0 &&
                        currentItems.length !== 0 &&
                        currentItems.map((item) => (
                          <Col
                            lg="4"
                            md="6"
                            className="lotterylistcard"
                            key={item.giftCardId}
                          >
                            <MyGiftCard
                              item={item}
                              history={props.history}
                              userRole={"admin"}
                              isExpired={item.isSold === false && item.expDate < currentDT && activeTab === "3"}
                              giftcardLogo={giftcardLogoData}
                            />
                          </Col>
                        ))}
                      {pageCount > 1 && (
                        <Col xs={12} className="m-0">
                          <div className="paginationsection">
                            <span className="noofpagetxt">
                              Showing {currentPage + 1} From {pageCount}
                            </span>
                            <div className="paginationallignment">
                              <ReactPaginate
                                previousLabel={
                                  <PreviousIcon className="paginationicon" />
                                }
                                nextLabel={
                                  <NextIcon className="paginationicon" />
                                }
                                breakLabel={"..."}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                breakClassName={"break-me"}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                              />
                            </div>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </>
  );
};

export default AdminGiftCardList;
