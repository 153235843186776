export const Path ={
    root : '/',
    login : '/login' , 
    help : '/help' , 
    about : '/aboutus' , 
    register: '/registers', 
    registerasorg:'/register',
    singlelottery:'/lottery/:id', 
    admin:'/admins',
    adminorglist:'/admin/creatorslist',  
    adminlotterylist:'/admin/lotterylist',  
    adminlotterydetail:'/admin/lotterydetails/:id',  
    org:'/creators', 
    orgcreatelottery:'/creator/createlottery', 
    orglotterydetail:'/creator/lotterydetails/:id',  
    orgmylottery:'/creator/mylottery',
    alllottery:'/all-lottery',
    result:'/lottery-result',
    profile:'/profile',
    Spinnerlottery:'/spinnerlottery',
    adminpartnerslist: '/admin/partnerslist',
    minelottery:'/minelottery/:id',
    allminelottery:'/all-minelottery',
    missilelottery:'/missilelottery/:id',
    allmissilelottery:'/all-missilelottery',
    allgiftcards:'/all-giftcards',
    giftcard:'/giftcard/:id',
    orgcreatecard:'/creator/create-giftcard',
    mygiftcard:'/creator/mygiftcard',
    admingiftcard:'/admin/giftcardlist',
    admincarddetails: '/admin/giftcarddetails/:id',
    orgcarddetails:'/creator/giftcarddetails/:id',
    adminNews:'/admin/news',
    notifications: '/notifications',
    adminNotifications: '/admin/notifications',
    orgNotifications: '/creator/notifications',
    news: '/all-news',
    newsId:'/news/:id',
    lotterydetail:'/lotterydetails/:id',
    allLotteries: '/all-lotteries',
    terms: '/terms&conditions'
}