import React, { useState } from "react";
import { ReactComponent as DownArrow } from "../../static/Images/downArrow.svg";

const DetailSummary = ({ data }) => {
  const [show, setShow] = useState(false);
  return (
    <div>
      <div className="dropWrap">
        <div
          className="d-flex justify-content-between align-items-center pointer"
          onClick={() => setShow(!show)}
        >
          <p className="detailStyle">{data?.question}</p>
          <p>
            <DownArrow className={`dropDownSvg mb-2 ${show && "rotateXTo0"}`} />
          </p>
        </div>
        <div className={`summaryWrap ${show && "showSummary"}`}>
          {data.type === "multiple" ? (
            <>
              {data.solution?.map((data) => (
                <p className="summaryStyle">
                  <b>{data?.subTitle}</b>
                  {data?.subSolution}
                </p>
              ))}
            </>
          ) : (
            <p className="summaryStyle">{data.solution}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailSummary;
