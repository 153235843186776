import React, { useState, useEffect } from "react";
import Dropdown from "react-multilevel-dropdown";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import cinstance from "../../Service/randomcinstance";
import { PiCaretDown, PiCaretRight } from "react-icons/pi";
import "./LotteryDropdownStyle.css";

const LotteryDropdown = () => {
  const [lotterylist1, setLotteryList1] = useState([]);
  const [lotterylist2, setLotteryList2] = useState([]);
  const [lotterylist3, setLotteryList3] = useState([]);
  const [lotterylist4, setLotteryList4] = useState([]);
  let history = useHistory();

  useEffect(() => {
    cinstance.methods
      .lotteryId()
      .call()
      .then((res) => {
        for (var i = 1; i < res; i++) {
          cinstance.methods
            .lottery(i)
            .call()
            .then((response) => {
              if (response.status !== "2") {
                if (response.lotteryType == 0) {
                  if (response.status === "0" || response.status === "5") {
                    lotterylist1.push(response);
                    setLotteryList1(() => [...lotterylist1].sort((a, b) => b.lotteryId - a.lotteryId));
                  }
                } else if (response.lotteryType == 2) {
                  if (
                    response.status === "0" ||
                    response.status === "3" ||
                    response.status === "5"
                  ) {
                    lotterylist2.push(response);
                    setLotteryList2(() => [...lotterylist2].sort((a, b) => b.lotteryId - a.lotteryId));
                  }
                } else if (response.lotteryType == 1) {
                  if (response.status === "0" || response.status === "5") {
                    if (response?.lotteryId) {
                      lotterylist3.push(response);
                      setLotteryList3(() => [...lotterylist3].sort((a, b) => b.lotteryId - a.lotteryId));
                    }
                  }
                } else {
                  if (
                    response.status === "0" ||
                    response.status === "3" ||
                    response.status === "5"
                  ) {
                    lotterylist4.push(response);
                    setLotteryList4(() => [...lotterylist4].sort((a, b) => b.lotteryId - a.lotteryId));
                  }
                }
              }
            });
        }
      })
      .catch((err) => console.log("error", err));
  }, []);

  return (
    <div className="lotterydropdown">
      <Dropdown
        title={
          <Link to={"/all-lotteries"}>
            Explosive Lotteries <PiCaretDown className="ml-2" />
          </Link>
        }
        openOnHover={true}
        position="right"
        buttonVariant="tertiary"
      >
        <Link to="/all-lottery">
          <Dropdown.Item>
            Autobet MRL
            <PiCaretRight />
            <Dropdown.Submenu position="right">
              {lotterylist2.length === 0 && (
                <Dropdown.Item>No lotteries available</Dropdown.Item>
              )}
              {lotterylist2.length > 0 &&
                lotterylist2
                  .slice(0, 3)
                  .map((item) => (
                    <Link to={`/lottery/${item.lotteryId}`}>
                      <Dropdown.Item

                      >
                        MRL - Lottery {item.lotteryId}
                      </Dropdown.Item>
                    </Link>
                  ))}
              {lotterylist2.length > 3 && (
                <Link to="/all-lottery">
                  <Dropdown.Item
                    className="showMore"
                  >
                    Show More
                  </Dropdown.Item>
                </Link>
              )}
            </Dropdown.Submenu>
          </Dropdown.Item>
        </Link>
        <Link to="/spinnerlottery">
          <Dropdown.Item>
            Autobet Revolver
            <PiCaretRight />
            <Dropdown.Submenu position="right">
              {lotterylist1.length === 0 && (
                <Dropdown.Item>No lotteries available</Dropdown.Item>
              )}
              {lotterylist1.length > 0 &&
                lotterylist1
                  .slice(0, 3)
                  .map((item) => (
                    <Link to={"/spinnerlottery"}>
                      <Dropdown.Item

                      >
                        Revolver - Lottery {item.lotteryId}
                      </Dropdown.Item>
                    </Link>
                  ))}
              {lotterylist1.length > 3 && (
                <Link to="/spinnerlottery">
                  <Dropdown.Item
                    className="showMore"
                  >
                    Show More
                  </Dropdown.Item>
                </Link>
              )}
            </Dropdown.Submenu>
          </Dropdown.Item>
        </Link>
        <Link to="/all-minelottery">
          <Dropdown.Item>
            Autobet Mine
            <PiCaretRight />
            <Dropdown.Submenu position="right">
              {lotterylist3.length === 0 && (
                <Dropdown.Item>No lotteries available</Dropdown.Item>
              )}
              {lotterylist3.length > 0 &&
                lotterylist3
                  .slice(0, 3)
                  .map((item) => (
                    <Link to={`/minelottery/${item.lotteryId}`}>
                      <Dropdown.Item

                      >
                        Mine - Lottery {item.lotteryId}
                      </Dropdown.Item>
                    </Link>
                  ))}
              {lotterylist3.length > 3 && (
                <Link to="/all-minelottery">
                  <Dropdown.Item
                    className="showMore"
                  >
                    Show More
                  </Dropdown.Item>
                </Link>
              )}
            </Dropdown.Submenu>
          </Dropdown.Item>
        </Link>
        <Link to="/all-missilelottery">
          <Dropdown.Item>
            Autobet Missile

            <PiCaretRight />
            <Dropdown.Submenu position="right">
              {lotterylist4.length === 0 && (
                <Dropdown.Item>No lotteries available</Dropdown.Item>
              )}
              {lotterylist4.length > 0 &&
                lotterylist4
                  .slice(0, 3)
                  .map((item) => (
                    <Link to={`/missilelottery/${item.lotteryId}`}>
                      <Dropdown.Item
                      >
                        Missile - Lottery {item.lotteryId}
                      </Dropdown.Item>
                    </Link>
                  ))}
              {lotterylist4.length > 3 && (
                <Link to="/all-missilelottery">
                  <Dropdown.Item
                    className="showMore"
                  >
                    Show More
                  </Dropdown.Item>
                </Link>
              )}
            </Dropdown.Submenu>
          </Dropdown.Item>
        </Link>
      </Dropdown>
    </div>
  );
};
export default LotteryDropdown;
