
function getImageUrlByHash(hash, dataArray) {
    if (!dataArray) {
      console.error('Data array is not available yet.');
      return null;
    }
    const foundItem = dataArray.find((item) => item.logoHash === hash);
    if (foundItem) {
      return foundItem.imageUrl;
    } else {
      console.error('Item not found for hash:', hash);
      return "https://autobetlottery.com/static/media/logo.b714e7ab.png";
    }
  }
  
  export default getImageUrlByHash;
  