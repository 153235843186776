import React, { useState, useEffect } from "react";
import { Container, Col, Row, Modal, Button } from "react-bootstrap";
import cinstance from "../../Service/randomcinstance";
import web3 from "../../Service/web3";
import axios from "axios";
import getImageUrlByHash from "../../../static/logoUtils";
import NavBar from "../../Components/NavBar";
import moment from "moment";
import { Path } from "../../../static/Constants";
import SuccessPopup from "../SuccessPopup";
import FailedPopup from "../FailedPopup";
import Loaders from "../../Components/Loaders";
import Warnings from "../../Components/Warning";
import "../../../static/css/popup.css";
import "./Mine.css";

var md5 = require("md5");

const BuyMineLottery = (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [selectednum, setSelectedNum] = useState([]);
  const [lotterylist, setLotteryList] = useState({
    entryFee: 0,
    totalPrize: 0,
  });
  const [datelist, setDateList] = useState({});
  const [partnerList, setPartnerList] = useState({});
  const [showFail, setShowFail] = useState(false);
  const [alreadySelected, setalreadySelected] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [partnerLogoData, setPartnerLogoData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_KEY;
  
  useEffect(() => {
    const fetchData = async () => {
      if (window.ethereum) {
        window.web3 = web3;

        let account = await web3.eth.getAccounts();
        if (account && account.length !== 0) {
          cinstance.methods
            .lottery(props.match.params.id)
            .call()
            .then((response) => {
              setLotteryList(response);
              cinstance.methods
                .lotteryDates(props.match.params.id)
                .call()
                .then((responsee) => {
                  setDateList(responsee);
                })
                .catch((err) => console.log("error", err));
              cinstance.methods
                .getMineLotteryNumbers(props.match.params.id)
                .call()
                .then((selected) => {
                  selected = selected.map((it) => Number(it));
                  setalreadySelected(selected);
                })
                .catch((err) => console.log("error", err));
              cinstance.methods
                .partnerbyaddr(response.partnerAddress)
                .call()
                .then((responsee) => {
                  setPartnerList(responsee);
                })
                .catch((err) => console.log("error", err));
            })
            .catch((err) => console.log("pepep", err));
        } else {
          setError(true);
        }
      } else {
        setError(true);
      }
      setLoading(false);
    };
    fetchData();
  }, [props.match.params.id]);

  const buy = async () => {
    if (window.ethereum) {
      window.web3 = web3;
      setLoading(true);
      let account = await web3.eth.getAccounts();
      let selectedNUM = [];

      let hash = md5(`${selectednum}lottery${props.match.params.id}`);
      let inc = false;
      if (account.length != 0) {
        cinstance.methods
          .getMineLotteryNumbers(props.match.params.id)
          .call()
          .then((selectedFincalCheck) => {
            selectedFincalCheck = selectedFincalCheck.map((it) => Number(it));
            selectedFincalCheck?.map((items) => {
              if (items == Number(selectedNUM[0])) {
                inc = true;
              }
            });
            setalreadySelected(() => [...selectedFincalCheck]);
            if (!inc) {
              cinstance.methods
                .buyNormalLottery(selectednum, props.match.params.id)
                .send({
                  from: account[0],
                  value: lotterylist.entryFee,
                })
                .then((res) => {
                  setShowSuccess(true);
                  setLoading(false);
                })
                .catch((er) => {
                  setLoading(false);
                  setShowFail(true);
                });
            } else {
              setLoading(false);
              alert(
                "The ticket number you have selected has already been sold. Please choose a different ticket number."
              );
            }
          })
          .catch((err) => console.log("error", err));
        selectednum.map((ids) => {
          selectedNUM.push(`${ids}`);
        });
      } else {
        window.ethereum.enable();
      }
    }
  };

  const handleItemClick = (number) => {
    if (!alreadySelected.includes(number)) {
      setSelectedNum(selectednum.includes(number) ? [] : [number]);
    }
  };

  const listItems = [];

  for (let i = 1; i <= lotterylist.capacity; i++) {
    const isDisabled = alreadySelected.includes(i);
    const isSelected = selectednum.includes(i);
    const itemClassName = `${isDisabled ? "disabled " : ""}${
      isSelected ? "active" : ""
    }`;
    listItems.push(
      <li
        key={i}
        className={itemClassName.trim()}
        onClick={() => handleItemClick(i)}
      >
        <span className="mine-numbers">{i}</span>
      </li>
    );
  }

  useEffect(() => {
    axios.get(`${apiUrl}/s3imageupload/getAllPartnerLogos`).then((response) => {
      const partnerData = response.data.result;
      setPartnerLogoData(partnerData)
    });
  }, []);

  const imageUrl = getImageUrlByHash(
    partnerList.logoHash,
    partnerLogoData
  );
  return (
    <div className="MineLottery">
      {loading && <Loaders />}
      {error && <Warnings />}
      <NavBar />
      <Container style={{ minHeight: "95vh" }}>
        <Row className=" mb-4 mx-auto justify-content-center">
          <Col lg="12">
            <div className=" mb-3 justify-content-center">
              <h3 className="mb-6 mt-4 font-weight-bold text-danger">
                {" "}
                Get Chance to win '
                {web3.utils.fromWei(`${lotterylist.totalPrize}`, "ether")}' ETH
              </h3>
              <Col lg="6" className={"mt-4 mb-4 mx-auto"}>
                <div class="buy-card bgcol p-0 mb-4 ">
                  <div class="card-body p-2 ">
                    <p class="text-light text-center">
                      Start Date :{" "}
                      {moment
                        .unix(datelist?.startTime)
                        .format("DD/MM/YYYY hh:mma")}{" "}
                      {/* -{" "}
                      {moment
                        .unix(datelist?.endTime)
                        .format("DD/MM/YYYY hh:mma")} */}
                    </p>
                  </div>
                </div>
              </Col>
              {lotterylist.status != 2 && (
                <Col lg="6 m-5" className=" card-inner p-4 mx-auto">
                  <h4 className="mt-2 mb-2 p-2 text-title">Pick 1 number</h4>
                  <Row className="m-2 ">
                    <img
                      src={imageUrl}
                      className="partnerlogo"
                    />
                    <ul className="number-list">{listItems}</ul>
                  </Row>
                </Col>
              )}
              {lotterylist.status == 2 && (
                <Col lg="4 mb-5" className={" mx-auto "}>
                  <div class="buy-card  " style={{ height: "100%" }}>
                    <div class="card-body text-center ">
                      <h5 class="text-danger  font-weight-bold p-4 mt-4 mb-4">
                        Lottery Not Available !!
                      </h5>
                    </div>
                  </div>
                </Col>
              )}

              {lotterylist.status != 2 && (
                <Col lg="6 mb-5" className={"mx-auto"}>
                  <div className="card-inner">
                    <div className="card-body text-center p-0">
                      <h5 className="text-title font-weight-bold mb-4">
                        Draw will be annouced after all tickets are sold
                        {/* {moment
                          .unix(datelist?.drawTime)
                          .format("DD/MM/YYYY hh:mma")} */}
                      </h5>
                      <Button
                        onClick={buy}
                        style={{
                          cursor: "pointer",
                          height: "auto",
                          width: "230px",
                        }}
                        disabled={selectednum.length !== 1}
                      >
                        {lotterylist && lotterylist.entryFee && (
                          <b style={{ wordBreak: "break-all" }}>
                            Buy for{" "}
                            {web3.utils.fromWei(
                              `${lotterylist.entryFee}`,
                              "ether"
                            )}{" "}
                            ETH
                          </b>
                        )}
                      </Button>
                    </div>
                  </div>
                </Col>
              )}
            </div>
          </Col>
        </Row>
      </Container>

      <SuccessPopup
        show={showSuccess}
        setShowModal={(e) => setShowSuccess(e)}
        message={"Lottery bought succesfully !"}
        redirectPath={Path.profile}
        btnName={"Go to profile"}
      />

      <FailedPopup show={showFail} setShowModal={(e) => setShowFail(e)} />
    </div>
  );
};
export default BuyMineLottery;
