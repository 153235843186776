import React from "react";
import { Container, Col, Row, Card, Button, CardImg } from "react-bootstrap";
import moment from "moment";
import getImageUrlByHash from "../../../static/logoUtils";
import AutoLogo from "../../../static/Images/logo.png";
import web3 from "../../Service/web3";
import "./GiftCardStyle.css";

const GiftCard = ({ item, history, giftcardLogo }) => {

const imageUrl = getImageUrlByHash(item.logoHash, giftcardLogo)

  return (
    <div className="gift-card">
      <Card className="lottery-card"   onClick={() => history.push(`/giftcard/${item.giftCardId}`)}>
        {item?.logoHash !== undefined && item?.logoHash !== "" ? (
          <Card.Img
            src={imageUrl}
            className="cardlogo"
          />
        ) : (
          <Card.Img src={AutoLogo} className="cardlogo" />
        )}

        <div className="giftcard-tag">
          {item.isDonation === true ? <span>Donation</span> : <span>Sell</span>}
        </div>

        <Card.Body>
          <Card.Title className="amount-text">
            {web3.utils.fromWei(`${item && item.BETValue}`, "ether")} BET
          </Card.Title>
          {item.isDonation === true ? (
            <Card.Subtitle className="price">
              <span style={{ color: "#ea2e49" }}>Free</span>
              <span className="gas-text"> + Gas Fees*</span>
            </Card.Subtitle>
          ) : (
            <Card.Subtitle className="price">
              <span>
                {web3.utils.fromWei(`${item && item.ETHPrice}`, "ether")} ETH
              </span>
            </Card.Subtitle>
          )}

          <Card.Text className="expiry">
            {moment.unix(item && item.expDate).format("DD-MM-YYYY HH:mm")}
          </Card.Text>
          <Card.Text className="address mb-2">
            <b>Creator : </b>
            <span style={{ wordBreak: "break-all" }}>
              {/* {item && item.creator} */}
              {`${item.creator.substring(0, 2)}.... ${item.creator.substring(
                  item.creator.length - 4,
                  item.creator.lenght
                )}`}
            </span>
          </Card.Text>
          <Card.Text className="desc">{item && item.Desc}</Card.Text>
          <div className="button-section text-center">
            <Button
             
              className="buy-btn"
            >
              {item.isDonation === true ? "Redeem" : "Buy"}
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};
export default GiftCard;
