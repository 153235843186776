import React from "react";
import Fade from "react-reveal/Fade";
import "./bookiescard.css";
const BookiesCards = ({ bookiesarray, description }) => {
  return (
    <div class="bookiescontainer">
      {bookiesarray.map(i => <div class="bookiescard">
        <h3 class="bookiestitle">{i.id}</h3>

        <div class="bar">
          <div class="emptybar"></div>
          <div class="filledbar"></div>
        </div>
        <div className="showonhover">
          <h4>{i.name}</h4>
          <h4>{i.amountearned / 1000000000000000000}ETH</h4>
        </div>
      </div>)}
    </div>
  );
};
export default BookiesCards;
