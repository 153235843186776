import React, { useState, useEffect } from "react";
import { Container, Col, Row, Card, Button, Modal } from "react-bootstrap";
import cinstance from "../../Service/randomcinstance";
import web3 from "../../Service/web3";
import axios from "axios";
import NavBar from "../../Components/NavBar";
import MineCard from "../../Components/mine-card/MineCard";

const AllMinelottery = (props) => {
  const [loading, setLoading] = useState(true);
  const [lotteryList, setLotteryList] = useState([]);
  const [partnerLists, setPartnerLists] = useState([]);
  const [partnerLogoData, setPartnerLogoData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const getLotteryList = async () => {
      cinstance.methods
        .lotteryId()
        .call()
        .then((res) => {
          for (var i = 0; i <= res; i++) {
            cinstance.methods
              .lottery(i)
              .call()
              .then((response) => {
                if (
                  (response.status === "0" || response.status === "5") &&
                  response.lotteryType == 1
                ) {
                  lotteryList.push(response);
                  setLotteryList(() =>
                    [...lotteryList].sort((a, b) => b.lotteryId - a.lotteryId)
                  );

                  cinstance.methods
                    .partnerbyaddr(response.partnerAddress)
                    .call()
                    .then((responsee) => {
                      partnerLists.push(responsee);
                      setPartnerLists(() => [...partnerLists]);
                    })
                    .catch((err) => console.log("error", err));
                }
              })
              .catch((err) => console.log("pepep", err));
          }
        })
        .catch((err) => console.log("res", err));

      axios
        .get(`${apiUrl}/s3imageupload/getAllPartnerLogos`)
        .then((response) => {
          const partnerData = response.data.result;
          setPartnerLogoData(partnerData);
        });
    };
    getLotteryList();
  }, []);

  // const mergeArray = (lotteryList, partnerLists, prop) => {
  //   partnerLists?.forEach((partnerlistsElement) => {
  //     const { status, ...partnerlistsElementWithoutStatus } =
  //       partnerlistsElement;
  //     const lotterylistElement = lotteryList.find((lotterylistElement) => {
  //       return partnerlistsElement[prop] === lotterylistElement[prop];
  //     });
  //     if (lotterylistElement) {
  //       Object.assign(lotterylistElement, partnerlistsElementWithoutStatus);
  //       lotterylistElement.partnerStatus = status;
  //     } else {
  //       lotteryList.push({
  //         ...partnerlistsElementWithoutStatus,
  //         partnerStatus: status,
  //       });
  //     }
  //   });
  // };

  // mergeArray(lotteryList, partnerLists, "partnerAddress");

  const findPartnerByAddress = (address, partnerList) => {
    return (
      partnerList?.find((partner) => partner.partnerAddress === address) || {}
    );
  };

  return (
    <div fluid className="p-0 m-0 allLottery">
      <NavBar />
      <Container>
        <div className="mx-auto mt-3 ">
          <Row className="lottery-section">
            <Col>
              <h2
                className="section-title"
                style={{ fontWeight: "600", fontSize: "28px" }}
              >
                Autobet Mine
              </h2>
            </Col>
          </Row>
          <Row className="mx-auto lottery-section2 text-center">
            {lotteryList.length === 0 && (
              <h4 className="spacingleft">No Active Lotteries !</h4>
            )}
            {lotteryList.map((item, index) => {
              const partnerData = findPartnerByAddress(
                item.partnerAddress,
                partnerLists
              );
              return (
                <Col lg={4}>
                  <MineCard
                    key={index}
                    history={props.history}
                    item={item}
                    partner={partnerData}
                    partnerLogo={partnerLogoData}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default AllMinelottery;
