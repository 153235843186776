import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import axios from "axios";
import Carousel from "react-multi-carousel";
import getImageUrlByHash from "../../../static/logoUtils";
import AutoLogo from "../../../static/Images/logo.png";
import "./PartnersCarouselStyle.css";

function PartnersCarousel({ data }) {
  // console.log("carousel item", data);
  const [partnerLogoData, setPartnerLogoData] = useState();
  const apiUrl = process.env.REACT_APP_API_KEY;

  function getClassNames(data) {
    if (window.innerWidth >= 991) {
      return data.length > 3 ? "" : "justify-content-center";
    } else if (window.innerWidth >= 576) {
      return data.length > 2 ? "" : "justify-content-center";
    } else {
      return data.length > 1 ? "" : "justify-content-center";
    }
  }

  useEffect(() => {
    axios.get(`${apiUrl}/s3imageupload/getAllPartnerLogos`).then((response) => {
      const responseData = response.data.result;
      setPartnerLogoData(responseData);
    });
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 991 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 991, min: 576 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="partner-carousel">
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={false}
        responsive={responsive}
        infinite={true}
        keyBoardControl={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        arrows={false}
        className={getClassNames(data)}
      >
        {data !== undefined &&
          data.length > 0 &&
          data
            .filter((item) => item.status === true)
            .map((item) => {
              const imageUrl = getImageUrlByHash(
                item.logoHash,
                partnerLogoData
              );
              return (
                <div className="card-div" key={item.id}>
                  <Card className="fundBox p-0">
                    <Card.Body>
                      <div className="left">
                        <div className="profile-card-img">
                          {item?.logoHash !== undefined &&
                          item?.logoHash !== "" ? (
                            <img
                              src={imageUrl}
                              alt={item.name}
                              className="cardlogo"
                            />
                          ) : (
                            <img src={AutoLogo} className="cardlogo" />
                          )}
                        </div>
                      </div>
                      <div className="partner-details">
                        <div>{item.name}</div>

                        <div>
                          <a href={item.websiteAdd} target="_blank">
                            {item.websiteAdd}
                          </a>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
      </Carousel>
    </div>
  );
}

export default PartnersCarousel;
