import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import web3 from '../Service/web3';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Image } from "react-bootstrap";
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai'; 
import './NavBar.css';
import { IconContext } from 'react-icons';
import { OrgSidebarData } from './OrgSiderbarData';

function OrgNavBar() {
  const [sidebar, setSidebar] = useState(false);
let history = useHistory();
  const showSidebar = () => setSidebar(!sidebar);

  useEffect(() => {
    const fetchWSData = async () => {
      try {
        let account = await web3.eth.getAccounts();
        let ws = new WebSocket("wss://api.autobetlottery.com/ws");
        ws.onopen = () => {
          // console.log("connected");
          ws.send(JSON.stringify({ id: account[0] }));
          ws.onmessage = (e) => {
            let resp = JSON.parse(e.data);
            // console.warn("check web socket", resp);
            const storedWSNotifications =
              localStorage.getItem("wsnotifications") || "[]";
            const wsnotifications = JSON.parse(storedWSNotifications);
            if (!wsnotifications.includes(resp.title)) {
              wsnotifications.push(resp.title);
              localStorage.setItem(
                "wsnotifications",
                JSON.stringify(wsnotifications)
              );
              toast.success(resp.title, {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: false,
                onClose: () => {
                  const updatedWSNotifications = wsnotifications.filter(
                    (item) => item !== resp.title
                  );
                  localStorage.setItem(
                    "wsnotifications",
                    JSON.stringify(updatedWSNotifications)
                  );
                },
              });
            }
          };
        };
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchWSData();
  }, []);

  return (
   <> 

     <IconContext.Provider value={{ color: '#fff' }}>
     {/* <div className="navbar"  style={{    backgroundColor: '#edeef0'}}>
      <Link to="#" className='menu-bars'>
        <FaIcons.FaBars onClick={showSidebar} />  
      </Link>
      
    </div> */}
     <nav  className="sidebar">
     <ul className='nav-menu-items' onClick={showSidebar}>
       <li className='navbar-toggle'>
         <div className='menu-bars textstyle'style={{textDecoration:'none'}}   onClick={() => {history.push("/"); window.location.reload()}}>
         <Image src={require("../../static/Images/logo.png").default} style={{width:50,height:50}} />

         <span className="Logoorg">AutoBET</span>

          </div>
       </li>
       {OrgSidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName} style={{textDecoration:'none'}}>
                  <Link to={item.path}>
                    {item.icon}
                    <span className="titles">{item.title}</span>
                  </Link>
                </li>
              );
            })}
       </ul>
       </nav>
       <ToastContainer autoClose={5000} />
     </IconContext.Provider>
   
  </>
  );
}

export default OrgNavBar
