import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as GiIcons from 'react-icons/gi';

export const OrgSidebarData = [
  // {
  //   title: 'Register',
  //   path: '/register',
  //   icon: <GiIcons.Gi3DStairs />,
  //   cName: 'nav-text'
  // },
    {
      title: 'Homepage',
      path: '/creators',
      icon: <AiIcons.AiFillHome />,
      cName: 'nav-text'
    },
    {
      title: 'Create Lottery',
      path: '/creator/createlottery',
      icon: <FaIcons.FaTicketAlt />,
      cName: 'nav-text'
    },
    {
      title: 'My Lotteries',
      path: '/creator/mylottery',
      icon: <GiIcons.GiPodiumWinner />,
      cName: 'nav-text'
    },
    {
      title: 'Create Gift Card',
      path: '/creator/create-giftcard',
      icon: <FaIcons.FaGift />,
      cName: 'nav-text'
    },
    {
      title: 'My Gift Cards',
      path: '/creator/mygiftcard',
      icon: <FaIcons.FaGifts />,
      cName: 'nav-text'
    },
    {
      title: 'Notifications',
      path: '/creator/notifications',
      icon: <FaIcons.FaRegBell />,
      cName: 'nav-text'
    }
  ];
  