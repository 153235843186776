import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import "./static/css/Main.css";
import "./static/css/Footer.css";
import "./static/css/result.css";
import "./static/css/responsive.css";
import "./App.css";
import "./static/css/banner.css";
import "./static/css/buypage.css";
import Homepage from "./App/Pages/User/Homepage";
import Register from "./App/Pages/User/Register";
import Login from "./App/Pages/User/Login";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-multi-carousel/lib/styles.css";
import RegisterAsorganisation from "./App/Pages/User/RegisterAsOrganisation";
import OrganisationList from "./App/Pages/Admin/Organisationlist";
import Createlottery from "./App/Pages/Organisation/Createlottery";
import Mylottery from "./App/Pages/Organisation/Mylottery";
import LotteryList from "./App/Pages/Admin/LotteryList";
import Buylottery from "./App/Pages/User/Buylottery";
import { Path } from "./static/Constants";
import Alllottery from "./App/Pages/User/Alllottery";
import FooterStrip from "./App/Components/FooterStrip";
import Results from "./App/Pages/User/Results";
import AdminHome from "./App/Pages/Admin/AdminHome";
import LotteryDetails from "./App/Pages/Admin/LotteryDetails";
import OrgHome from "./App/Pages/Organisation/OrgHome";
import Profile from "./App/Pages/User/Profile";
import ScrollToTop from "./App/Components/ScrollToTop";
import OrgLotteryDetails from "./App/Pages/Organisation/LotteryDetails";
import Warnings from "./App/Components/Warning";
import Help from "./App/Pages/User/help/Help";
import About from "./App/Pages/User/about/About";
import web3 from "./App/Service/web3";
import { networkID } from "./static/utils";
import UserProfile from "./App/Pages/User/UserProfile";
import AllSpinlottery from "./App/Pages/User/AllSpinlottery";
import PartnersList from "./App/Pages/Admin/PartnersList";
import Mine from "./App/Components/mine/Mine";
import AllMinelottery from "./App/Pages/User/AllMinelottery";
import Missile from "./App/Components/missile/Missile";
import AllMissilelottery from "./App/Pages/User/AllMissilelottery";
import AllGiftCards from "./App/Pages/User/AllGiftCards";
import CreateCards from "./App/Pages/Organisation/CreateCard";
import BuyCard from "./App/Pages/User/buy-giftcard/BuyGiftCard"
import MyGiftCardPage from "./App/Pages/Organisation/MyGiftCard";
import AdminGiftCardList from "./App/Pages/Admin/AdminGiftCardList";
import AdminGiftCardDetails from "./App/Pages/Admin/GiftCardDetails";
import OrgGiftCardDetails from "./App/Pages/Organisation/GiftCardDetails";
import AdminNewsList from "./App/Pages/Admin/NewsList";
import NotificationScreen from "./App/Pages/User/notification-screen/NotificationScreen";
import NotificationList from "./App/Pages/Admin/NotificationList";
import OrgNotificationList from "./App/Pages/Organisation/NotificationList";
import NewsScreen from "./App/Pages/User/news/News";
import NewsDetails from "./App/Pages/User/news-details/NewsDetails"
import UserLotteryDetails from "./App/Pages/User/LotteryDetails";
import AllLotteries from "./App/Pages/User/all-lotteries/AllLotteries";
import TermsAndConditions from "./App/Pages/User/terms/TermsAndCondition";
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
function App() {
  const [warn, setwarn] = useState(false);
  const [net, setnet] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (
      window.location.pathname !== "/help" &&
      window.location.pathname !== "/aboutus"
    ) {
      //   setTimeout(() => {
      //   if (window.ethereum) {
      //     if (window.ethereum.networkVersion == 137) {
      //     } else {
      //       setIsModalOpen(true);
      //       setnet(true);
      //     }
      //   } else {
      //     setIsModalOpen(true);
      //   }
      // }, 5000);
    }
  }, []);
  return (
    <>
      <Router>
        {/* {warn && <Warnings net={net} />} */}
        {/* <Modal
          show={isModalOpen}
          centered
          // style={{ width: "60%", margin: "0px auto" }}
          onClose={() => setIsModalOpen(false)}
        >
          <Modal.Body>
            {net
              ? `Please Connect to Main net (Network Id ${networkID} )`
              : "Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!"}{" "}
            <p>
              <a href="/help" style={{ color: "blue" }}>Get Help</a>
            </p>
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: "center" }}>
            <Button
              color="green"
              onClick={() => {
                setIsModalOpen(false);
              }}
            //inverted
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal> */}
        <Modal
          className="PopupStyle"
          show={isModalOpen}
          centered
          onClose={() => setIsModalOpen(false)}
        >
          <Modal.Body>
            <Container>
              <ToastContainer />
              <Row>
                <Col lg={12}>
                  {net ? (
                    <div className="message-text">
                      Please Connect to Main net (Network Id ${networkID} )
                    </div>
                  ) : (
                    <div className="message-text">
                      Please install an Ethereum-compatible browser or extension
                      like MetaMask to use this dApp!
                    </div>
                  )}
                  <p className="link-textt">
                    <a href="/help" style={{ color: "blue" }}>
                      Get Help
                    </a>
                  </p>
                </Col>
                <Col xs={12}>
                  <div className="btn-div">
                    <Button
                      className="submit-btn"
                      onClick={() => {
                        setIsModalOpen(false);
                      }}
                    >
                      OK
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
        <ScrollToTop>
          <Switch>
            <Route path={Path.root} exact component={Homepage} />
            <Route path={Path.login} component={Login} />
            <Route path={Path.register} component={Register} />
            <Route
              path={Path.registerasorg}
              component={RegisterAsorganisation}
            />
            <Route path={Path.singlelottery} component={Buylottery} />
            <Route path={Path.admin} component={AdminHome} />
            <Route path={Path.adminorglist} component={OrganisationList} />
            <Route path={Path.adminlotterylist} component={LotteryList} />
            <Route path={Path.adminlotterydetail} component={LotteryDetails} />
            <Route path={Path.org} component={OrgHome} />
            <Route path={Path.orgcreatelottery} component={Createlottery} />
            <Route path={Path.orgmylottery} component={Mylottery} />
            <Route path={Path.orglotterydetail} component={OrgLotteryDetails} />
            <Route path={Path.alllottery} component={Alllottery} />
            <Route path={Path.result} component={Results} />
            <Route path={Path.profile} component={Profile} />
            <Route path={Path.help} component={Help} />
            <Route path={Path.about} component={About} />
            <Route path={Path.Spinnerlottery} component={AllSpinlottery} />
            <Route path={Path.adminpartnerslist} component={PartnersList} />
            <Route path={Path.minelottery} component={Mine} />
            <Route path={Path.allminelottery} component={AllMinelottery} />
            <Route path={Path.missilelottery} component={Missile} />
            <Route path={Path.allmissilelottery} component={AllMissilelottery} />
            <Route path={Path.allgiftcards} component={AllGiftCards} />
            <Route path={Path.orgcreatecard} component={CreateCards}/>
            <Route path={Path.giftcard} component={BuyCard}/>
            <Route path={Path.mygiftcard} component={MyGiftCardPage} /> 
            <Route path={Path.admingiftcard} component={AdminGiftCardList} />
            <Route path={Path.admincarddetails} component={AdminGiftCardDetails} />
            <Route path={Path.orgcarddetails} component={OrgGiftCardDetails} />
            <Route path={Path.adminNews} component={AdminNewsList} />
            <Route path={Path.adminNotifications} component={NotificationList} />
            <Route path={Path.orgNotifications} component={OrgNotificationList} />
            <Route path={Path.notifications} component={NotificationScreen} />
            <Route path={Path.news} component={NewsScreen} />
            <Route path={Path.newsId} component={NewsDetails} />
            <Route path={Path.lotterydetail} component={UserLotteryDetails} />
            <Route path={Path.allLotteries} component={AllLotteries} />
            <Route path={Path.terms} component={TermsAndConditions} />
          </Switch>
        </ScrollToTop>
      </Router>
      <FooterStrip />
    </>
  );
}

export default App;
