import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import web3 from "../../../Service/web3";
import { formatDistanceToNow } from "date-fns";
import { toast, ToastContainer } from "react-toastify";
import NavBar from "../../../Components/NavBar";
import { EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import Loaders from "../../../Components/Loaders";
import ShareIcon from "../../../../static/Images/share.png";
import "./NewsStyle.css";

function NewsScreen() {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_KEY;

  function formatTimeAgo(dateTimeString) {
    const date = new Date(dateTimeString);
    return formatDistanceToNow(date, { addSuffix: true });
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const getNewsListFromLocalStorage = () => {
          const savedNews = localStorage.getItem("news");
          if (savedNews) {
            setNews(JSON.parse(savedNews));
          }
        };
        let account = await web3.eth.getAccounts();

        const response = await fetch(`${apiUrl}/news/getAllNews`, {
          method: "GET",
          headers: {
            Authorization: account[0],
          },
        });
        if (response.ok) {
          const data = await response.json();
          setNews(data.result);
          localStorage.setItem("news", JSON.stringify(data.result));
        } else {
          console.error("Error fetching news:", response.error);
          getNewsListFromLocalStorage();
        }
      } catch (error) {
        console.error("Error fetching news:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <div id="news" className="mb-5">
      <NavBar />
      <Container>
        <Row className="justify-content-center">
          <Col xs={12}>
            <div className="news-section mt-4">
              <h2
                className="section-title"
                style={{ fontWeight: "600", fontSize: "28px" }}
              >
                News
              </h2>
              {loading ? (
                <Loaders />
              ) : news?.length > 0 ? (
                news.map((newsItem, index) => {
                  const contentBlock = htmlToDraft(newsItem.description);
                  const contentState = ContentState.createFromBlockArray(
                    contentBlock.contentBlocks
                  );
                  const editorState =
                    EditorState.createWithContent(contentState);

                  return (
                    <div className="news_list mt-4" key={index}>
                      <div className="details-section">
                        <p className="news_title title_width">
                          {newsItem.title}
                        </p>
                        <img
                          src={ShareIcon}
                          className="share-icon"
                          onClick={(e) => {
                            toast.success("Sharable link Copied !", {
                              position: toast.POSITION.TOP_RIGHT,
                            });
                            navigator.clipboard.writeText(
                              `${window?.location?.origin}/news/${newsItem.id}`
                            );
                          }}
                        />
                        <p className="news_time mt-1">
                          {formatTimeAgo(newsItem.updatedOn)}
                        </p>
                        <div className="newsImage mt-4">
                          <img src={newsItem.imageUrl} className="newsImg" />
                        </div>
                        <Editor
                          editorState={editorState}
                          toolbarHidden
                          readOnly
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No news available</p>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default NewsScreen;
