import React, { useState, useEffect } from "react";
import { Container, Col, Row, Card, Button, Modal } from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import giftcardcinstance from "../../Service/giftcardcinstance";
import web3 from "../../Service/web3";
import NavBar from "../../Components/NavBar";
import GiftCard from "../../Components/gift-card/GiftCard";

const AllGiftCards = (props) => {
  const [loading, setLoading] = useState(true);
  const [giftcardList, setGiftCardList] = useState([]);
  const [partnerLists, setPartnerLists] = useState([]);
  const [giftcardLogoData, setGiftcardLogoData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const getGiftCardList = async () => {
      let account = await web3.eth.getAccounts();
      if(account.length !== 0){
        giftcardcinstance.methods
        .getBetTokenBalance(account[0])
        .call()
        .then((res) => {
          // console.log("check balance", res)
        });
      }
      giftcardcinstance.methods
      .giftCardId()
      .call()
      .then((res) => {
        for (var i = 0; i <= res; i++) {
            giftcardcinstance.methods
              .giftCards(i)
              .call()
              .then((response) => {
                let currentDT = moment().unix();
                if(response.isSold === false && response.expDate > currentDT){
                  giftcardList.push(response);
                  setGiftCardList(() => [...giftcardList]);
                }
              })
              .catch((err) => console.log("pepep", err));
            }
          })
          .catch((err) => console.log("res", err));

          axios.get(`${apiUrl}/giftcardlogos/getAllGiftcardLogos`).then((response) => {
            const giftcardData = response.data.result;
            setGiftcardLogoData(giftcardData);
          });
    };
    getGiftCardList();
  }, []);

  return (
    <div fluid className="p-0 m-0 allLottery">
      <NavBar />
      <Container>
        <div className="mx-auto mt-3 ">
          <Row className="lottery-section">
            <Col>
              <h2
                className="section-title"
                style={{ fontWeight: "600", fontSize: "28px" }}
              >
                Autobet Gift Cards
              </h2>
            </Col>
          </Row>
          <Row className="mx-auto lottery-section2 text-center">
            {giftcardList.length === 0 && (
              <h4 className="spacingleft">No Active Gift Cards !</h4>
            )}
            {giftcardList.map((item, index) => (
              <Col lg={4}>
                <GiftCard key={index} history={props.history} item={item} giftcardLogo={giftcardLogoData}/>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default AllGiftCards;
