import React, { useState, useEffect } from "react";
import web3 from "../Service/web3";
import ShareIcon from "../../static/Images/share.png";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";

const Lotterydetails = ({ item, state, history, userRole }) => {
  const formatRolloverTime = (seconds) => {
    const days = Math.floor(seconds / (60 * 60 * 24));
    const hours = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);

    let formattedTime = "";

    if (days > 0) {
      formattedTime += `${days} day${days > 1 ? "s" : ""} `;
    }
    if (hours > 0) {
      formattedTime += `${hours} hour${hours > 1 ? "s" : ""} `;
    }
    if (minutes > 0) {
      formattedTime += `${minutes} minute${minutes > 1 ? "s" : ""} `;
    }
    return formattedTime.trim();
  };

  return (
    <div className="overview-item text-left">
      <div className="lottery-card-content" key={item.lotteryId}>
        <p>
          <img
            src={ShareIcon}
            className="sharaicon"
            onClick={(e) => {
              toast.success("Sharable link Copied !", {
                position: toast.POSITION.TOP_RIGHT,
              });
              navigator.clipboard.writeText(
                `${window?.location?.origin}/lottery/${item.lotteryId}`
              );
            }}
          />
          <b> Lottery Type : </b> &nbsp;&nbsp;
          {state.lotteryTypeMapper[item?.lotteryType]}
          <br></br>
          <b>ID : </b> &nbsp;&nbsp;{" "}
          {moment.unix(item.deployedOn).format("DDMMYYYY")} - {item.lotteryId}
          <br></br>
          <b>Entry Fee : </b> &nbsp;&nbsp;{" "}
          {web3.utils.fromWei(`${item.entryFee}`, "ether")}
          {item.lotteryType !== "3" && (
            <>
              <br></br> <b>Slot : </b> &nbsp;&nbsp; {item.pickNumbers}
            </>
          )}
          <br></br> <b>Prize Amount : </b>
          &nbsp;&nbsp;
          {web3.utils.fromWei(`${item.totalPrize}`, "ether")}
          {item.lotteryType !== "3" && (
            <>
              <br></br>
              <b> Capacity : </b> &nbsp;&nbsp;
              {item.capacity}
            </>
          )}
          {item.lotteryType == "2" && (
            <>
              <br></br>
              <b> Minimum players : </b> &nbsp;&nbsp;
              {item?.minPlayers}
            </>
          )}
          <br></br>
          {item.lotteryType !== "1" && (
            <>
              <b> Rollover % : </b> &nbsp;&nbsp;
              {item.rolloverperct}%<br></br>
            </>
          )}
          {(item.lotteryType === "2" || item.lotteryType === "3") && (
            <>
              <b> Rollover Duration : </b> &nbsp;&nbsp;
              {formatRolloverTime(item.rolloverdays)}
              <br />
            </>
          )}
          <b> Partners Share : </b> &nbsp;&nbsp;
          {item.partnershare}%<br></br>
          <b> Result Status : </b> &nbsp;&nbsp;
          {state.resultStatusTypeMapper[item?.status]}
          <br></br>
          <b> Start Date : </b>&nbsp;&nbsp;
          {moment.unix(item.startTime).format("DD/MM/YYYY  hh:mma")}
          <br></br>
          {item.lotteryType != 0 && (
            <>
              {" "}
              <b> Roll Date : </b>&nbsp;&nbsp;
              {moment.unix(item.drawTime).format("DD/MM/YYYY  hh:mma")}
            </>
          )}
          <br></br>
          {/* {item.lotteryType !== "0" && (
          <>
            <b> Draw Date : </b>&nbsp;&nbsp;{" "}
            <span className="txttransform">
              {" "}
              {moment.unix(item.drawTime).format("DD/MM/YYYY  hh:mma")}
            </span>
            <br/>
          </>
        )} */}
          {userRole === "admin" && (
            <>
              <b> Creator : </b>&nbsp;&nbsp;{" "}
              <span>
                {" "}
                {item.ownerAddress?.slice(0, 8)} ...{" "}
                {item.ownerAddress?.slice(
                  item.ownerAddress?.length - 8,
                  item.ownerAddress?.length
                )}
              </span>
            </>
          )}
          <div
            className="pt-2"
            onClick={() => {
              if (userRole === "admin") {
                history.push(`/admin/lotterydetails/${item.lotteryId}`);
              } else {
                history.push(`/creator/lotterydetails/${item.lotteryId}`);
              }
            }}
          >
            {" "}
            <p className="underlinelink">View Details</p>
          </div>
        </p>
        {/* <ToastContainer autoClose={2000} /> */}
      </div>
    </div>
  );
};
export default Lotterydetails;
