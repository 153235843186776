import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import * as XLSX from "xlsx";
import { ExportSheet } from "react-xlsx-sheet";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import web3 from "../../Service/web3";
import AdminNavBar from "../../Components/AdminNavBar";
import cinstance from "../../Service/randomcinstance";
import moment from "moment";
import Loaders from "../../Components/Loaders";
import { PiHandshakeDuotone, PiGiftDuotone, PiHandCoins } from "react-icons/pi";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { RiRefreshLine } from "react-icons/ri";
import {
  FaRegMoneyBill1,
  FaRegNewspaper,
} from "react-icons/fa6";
import { BsTicketDetailed, BsCashCoin } from "react-icons/bs";
import { GiPodiumWinner } from "react-icons/gi";
import { LiaGiftsSolid } from "react-icons/lia";
import { formatDistanceToNow } from "date-fns";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { ReactComponent as PreviousIcon } from "../../../static/Images/previous.svg";
import { ReactComponent as NextIcon } from "../../../static/Images/next.svg";

const NotificationList = () => {
  const [show, setShow] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const limit = 10;
  const apiUrl = process.env.REACT_APP_API_KEY;
  const history = useHistory();
  const [excelHeader, setExcelHeader] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [accountAddress, setAccountAddress] = useState("");

  function formatTimeAgo(dateTimeString) {
    const date = new Date(dateTimeString);
    return formatDistanceToNow(date, { addSuffix: true });
  }

  const handlePageChange = (selectedPage) => {
    const nextPage = selectedPage.selected * limit;
    setCurrentPage(nextPage);
  };

  const handleDateChange = (event, picker) => {
    const start = picker.startDate.toDate();
    const end = picker.endDate.toDate();
    setStartDate(start);
    setEndDate(end);
    setSelectedStartDate(format(start, "MM/dd/yyyy"));
    setSelectedEndDate(format(end, "MM/dd/yyyy"));
    setCurrentPage(0);
    setTotalPages(0);
  };

  const fetchExportData = async () => {
    try {
      setLoading(true);

      let account = await web3.eth.getAccounts();
      setAccountAddress(account[0])
      let skip = 0;
      let allNotifications = [];

      const fetchNotifications = async (startDate, endDate) => {
        try {
          const response = await fetch(
            `${apiUrl}/notifications?limit=10&skip=${skip}`,
            {
              method: "GET",
              headers: {
                Authorization: account[0],
              },
            }
          );
          if (response.ok) {
            const data = await response.json();
            const notificationData = data.result;

            if (notificationData.length > 0) {
              allNotifications = [...allNotifications, ...notificationData];
              skip += 10;
              await fetchNotifications(startDate, endDate);
            } else {
              const filteredNotifications =
                startDate && endDate
                  ? allNotifications.filter((notification) => {
                      const notificationDate = new Date(notification.createdOn);
                      return (
                        notificationDate >= startDate &&
                        notificationDate <= endDate
                      );
                    })
                  : allNotifications;
              await prepareDataForExport(filteredNotifications);
              setNotifications(filteredNotifications);
              // console.log("notifications", filteredNotifications)
              setTotalPages(Math.ceil(filteredNotifications.length / limit));
              setLoading(false);
            }
          } else {
            console.error("Error fetching notifications:", response.statusText);
          }
        } catch (error) {
          console.error("Error fetching notifications:", error);
        } finally {
          // setLoading(false);
        }
      };
      await fetchNotifications(startDate, endDate);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const userAdmin = async () => {
    if (window.ethereum) {
      window.web3 = web3;
      cinstance.methods
        .admin()
        .call()
        .then(async (p) => {
          let account = await web3.eth.getAccounts();
          if (p == account[0]) {
            setShow(true);
          }
        });
    }
  };

  useEffect(() => {
    fetchExportData();
    userAdmin();
  }, []);

  useEffect(() => {
    fetchExportData();
  }, [startDate, endDate, currentPage]);

  const paginatedNotifications = notifications.slice(
    currentPage,
    currentPage + limit
  );

  const prepareDataForExport = async (dataForExcel) => {
    const data =
      dataForExcel?.length > 0 &&
      dataForExcel.map((notification) => ({
        Title: notification.title,
        Content: notification.content,
        Date: new Date(notification.createdOn).toLocaleString(),
      }));

    if (data && data.length > 0) {
      const header = Object.keys(data[0]);
      const headerList = [];
      for (let i of Object.keys(data[0])) {
        let newOBJ = {
          title: i,
          dataIndex: i,
        };
        headerList.push(newOBJ);
      }

      let pack = {
        data: data,
        head: headerList,
      };
      setExcelHeader(pack.head);
      setExcelData(pack.data);
    } else {
      // alert("No data available for export.");
      console.error("No data available for export.");
    }
  };

  function handleNotificationClick(notification) {
    switch (notification.type) {
      case "LotteryCreated":
        if (notification.lotteryName === "Missile") {
          history.push(`/missilelottery/${notification.genId}`);
        } else if (notification.lotteryName === "Mine") {
          history.push(`/minelottery/${notification.genId}`);
        } else if (notification.lotteryName === "MRL") {
          history.push(`/lottery/${notification.genId}`);
        } else {
          history.push("/spinnerlottery");
        }
        break;

      case "LotteryCreatedAdmin":
        if (notification.lotteryName === "Missile") {
          history.push(`/missilelottery/${notification.genId}`);
        } else if (notification.lotteryName === "Mine") {
          history.push(`/minelottery/${notification.genId}`);
        } else if (notification.lotteryName === "MRL") {
          history.push(`/lottery/${notification.genId}`);
        } else {
          history.push("/spinnerlottery");
        }
        break;
      case "RolloverHappened'":
        if (notification.lotteryName === "Missile") {
          history.push(`/missilelottery/${notification.genId}`);
        } else if (notification.lotteryName === "Mine") {
          history.push(`/minelottery/${notification.genId}`);
        } else if (notification.lotteryName === "MRL") {
          history.push(`/lottery/${notification.genId}`);
        } else {
          history.push("/spinnerlottery");
        }
        break;
      case "LotteryResult":
        if (notification.admin === true) {
          history.push(`/admin/lotterydetails/${notification.genId}`);
        } else if (accountAddress === notification.owner) {
          history.push(`/creator/lotterydetails/${notification.genId}`);
        } else {
          history.push(`/lottery-result`);
        }
        break;
      case "SpinLotteryResult":
        if (notification.admin === true) {
          history.push(`/admin/lotterydetails/${notification.genId}`);
        } else if ( accountAddress === notification.owner) {
          history.push(`/creator/lotterydetails/${notification.genId}`);
        } else {
          history.push(`/lottery-result`);
        }
        break;
      case "LotteryWinner":
        if (notification.admin === true) {
          history.push(`/admin/lotterydetails/${notification.genId}`);
        } else if (accountAddress === notification.owner) {
          history.push(`/creator/lotterydetails/${notification.genId}`);
        } else {
          history.push(`/lottery-result`);
        }
        break;
      case "GiftCardCreated":
        history.push(`/giftcard/${notification.genId}`);
        break;
      case "OrganisationAdded":
        history.push(`/admin/creatorslist`);
        break;
      case "LotteryBought":
        if (notification.admin === true) {
          history.push(`/admin/lotterydetails/${notification.genId}`);
        } else if (accountAddress === notification.owner) {
          history.push(`/creator/lotterydetails/${notification.genId}`);
        } else if (accountAddress === notification.user) {
          history.push(`/profile`)
        }
        break;
      case "GiftCardPurchased":
        history.push(`/admin/giftcarddetails/${notification.genId}`);
        break;
      case "PartnerCreated":
        if (notification.admin === true) {
        history.push(`/admin/partnerslist`);
        } else {
          history.push(`/#partners`)
        }
        break;

      default:

        break;
    }
  }

  return (
    <div id="notifications" className="notificationList">
      {loading && <Loaders />}
      <AdminNavBar />
      {show == false && (
        <Container className="bgImage  p-o">
          <div className="mspace mt-5">
            <h2>Not an admin</h2>
          </div>
        </Container>
      )}
      {show && (
        <Container className="bgImage p-o m-0" fluid>
          <div className="mspace1">
            <Row className="justify-content-center notify-row">
              <Col xs={12}>
                <div className="notification-section p-0 my-4">
                  <div className="notification_heading mb-4">
                    <h3
                      className="text-center section-title mt-2"
                      style={{
                        fontWeight: "600",
                        fontSize: "28px",
                        color: "#444",
                      }}
                    >
                      {" "}
                      Notifications
                    </h3>
                  </div>
                  <div className="filter-section mb-4">
                  <DateRangePicker
                    initialSettings={{
                      autoUpdateInput: false,
                    }}
                    onApply={handleDateChange}
                  >
                    <input
                      type="text"
                      placeholder={
                        selectedStartDate && selectedEndDate
                          ? `${selectedStartDate} - ${selectedEndDate}`
                          : "Sort by Date"
                      }
                      className="form-control date-range-input"
                    />
                  </DateRangePicker>
                    <ExportSheet
                      header={excelHeader}
                      fileName="Notifications"
                      dataSource={excelData}
                      xlsx={XLSX}
                    >
                      <Button className="mb-2">Export to Excel</Button>
                    </ExportSheet>
                  </div>

                  {paginatedNotifications?.length > 0 ? (
                    paginatedNotifications.map((notification, index) => (
                      <div
                        className="notification_list mt-2"
                        key={index}
                        onClick={() => handleNotificationClick(notification)}
                      >
                        <div className="icon-section">
                          {notification.type === "OrganisationAdded" ? (
                            <AiOutlineUsergroupAdd className="notify_icon" />
                          ) : notification.type === "PartnerCreated" ? (
                            <PiHandshakeDuotone className="notify_icon" />
                          ) : notification.type === "LotteryBought" ? (
                            <FaRegMoneyBill1 className="notify_icon" />
                          ) : notification.type === "LotteryWinner" ? (
                            <GiPodiumWinner className="notify_icon" />
                          ) : notification.type === "GiftCardCreated" ? (
                            <PiGiftDuotone className="notify_icon" />
                          ) : notification.type === "WinnerPaid" ? (
                            <BsCashCoin className="notify_icon" />
                          ) : notification.type === "SpinLotteryResult" ? (
                            <GiPodiumWinner className="notify_icon" />
                          ) : notification.type === "PartnerPaid" ? (
                            <PiHandCoins className="notify_icon" />
                          ) : notification.type === "PlatformUpdate" ? (
                            <FaRegNewspaper className="notify_icon" />
                          ) : notification.type === "LotteryCreated" ? (
                            <BsTicketDetailed className="notify_icon" />
                          ) : notification.type === "LotteryCreatedAdmin" ? (
                            <BsTicketDetailed className="notify_icon" />
                          ) : notification.type === "GiftCardPurchased" ? (
                            <LiaGiftsSolid className="notify_icon" />
                          ) : notification.type === "LotteryResult" ? (
                            <GiPodiumWinner className="notify_icon" />
                          ) : (
                            <RiRefreshLine className="notify_icon" />
                          )}
                        </div>
                        <div className="details-section">
                          <p className="notification_title">
                            {notification.title}
                          </p>
                          <p className="notification_content">
                            {notification.content}
                          </p>
                          <p className="notification_time">
                            {formatTimeAgo(notification.createdOn)}
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <h4>No notifications available</h4>
                  )}
                </div>
              </Col>
              {totalPages > 1 && (
                <Col xs={12} className="m-0 mb-5">
                  <div className="paginationsection">
                    <span className="noofpagetxt">
                      Showing {currentPage / limit + 1} From {totalPages}
                    </span>
                    <div className="paginationallignment">
                      <ReactPaginate
                        previousLabel={
                          <PreviousIcon className="paginationicon" />
                        }
                        nextLabel={<NextIcon className="paginationicon" />}
                        breakLabel={"..."}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        breakClassName={"break-me"}
                        pageCount={totalPages}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </Container>
      )}
    </div>
  );
};

export default NotificationList;
