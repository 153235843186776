const Web3 = require("web3");
var web3;

if (window.ethereum) {
  web3 = new Web3(window.ethereum);
}
else {
  // web3 = new Web3('https://mainnet.infura.io/v3/7473fbc3ac8a4ea9a00e3cf2a7f30125');
  web3 = new Web3('https://sepolia.infura.io/v3/7473fbc3ac8a4ea9a00e3cf2a7f30125');

}

export default web3;




//WEB3MODAL
// import Web3Modal from 'web3modal';
// import Web3 from 'web3';

// const web3Modal = new Web3Modal({
//   cacheProvider: true,
//   providerOptions: {},
// });

// let web3;

// web3Modal.connect().then((provider) => {
//   web3 = new Web3(provider);
//   web3.eth.getAccounts().then(console.log);
// });

// export default web3;
