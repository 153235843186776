import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import web3 from "../../../Service/web3";
import { formatDistanceToNow } from "date-fns";
import NavBar from "../../../Components/NavBar";
import { EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import Loaders from "../../../Components/Loaders";

function NewsDetails() {
  const [news, setNews] = useState("");
  const [loading, setLoading] = useState(true);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const apiUrl = process.env.REACT_APP_API_KEY;
  const { id } = useParams();

  function formatTimeAgo(dateTimeString) {
    const date = new Date(dateTimeString);
    return formatDistanceToNow(date, { addSuffix: true });
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        let account = await web3.eth.getAccounts();
        const response = await fetch(`${apiUrl}/news/${id}`, {
          method: "GET",
          headers: {
            Authorization: account[0],
          },
        });
        if (response.ok) {
          const data = await response.json();
          setNews(data);
          const contentBlock = htmlToDraft(data?.description);
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState);
        } else {
          const data = await response.json();
          console.error("Error fetching news:", data.message);
        }
      } catch (error) {
        console.error("Error fetching news:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <div id="news" className="mb-5">
      <NavBar />
      {loading && <Loaders />}
      <Container>
        <Row className="justify-content-center">
          <Col xs={12}>
            <div className="news-section mt-4">
              <h2
                className="section-title"
                style={{ fontWeight: "600", fontSize: "28px" }}
              >
                News
              </h2>
              {news !== undefined && news !== "" ? (
                <div className="news_list mt-4">
                  <div className="details-section">
                    <p className="news_title">{news.title}</p>
                    <p className="news_time mt-1">
                      {formatTimeAgo(news.updatedOn)}
                    </p>
                    <div className="newsImage mt-4">
                      <img src={news.imageUrl} className="newsImg" />
                    </div>
                    <Editor editorState={editorState} toolbarHidden readOnly />
                  </div>
                </div>
              ) : (
                <h4>No news available</h4>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default NewsDetails;
