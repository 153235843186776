import React, { Component } from "react";
import { Container, Col, Row, Card, Button } from "react-bootstrap";
import cinstance from "../../Service/randomcinstance";
import web3 from "../../Service/web3";
import NavBar from "../../Components/NavBar";
import ResultsCard from "../../Components/ResultsCard";
import FAQ from "../../Components/FAQ";

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seed: "",
      loading: false,
      Randomresult: "",
      winner: "",
      lotterylist: [],
      datelist: [],
    };
  }
  async componentDidMount() {
    var lotterylist = [];
    var datelist = [];
    if (window.ethereum) {
      window.web3 = web3;
      cinstance.methods
        .lotteryId()
        .call()
        .then((res) => {
          this.setState({ totallotteries: res });
          for (var i = 1; i < res; i++) {
            cinstance.methods
              .lottery(i)
              .call()
              .then((response) => {
                if (response.status == 2) {
                  // if (response.lotteryType == 1) {
                  lotterylist.push(response);
                  // } else {
                  //   lotterylist2.push(response);
                  // }
                  this.setState({ lotterylist });
                  cinstance.methods
                    .lotteryDates(response.lotteryId)
                    .call()
                    .then((responsee) => {
                      datelist.push(responsee);
                      this.setState({ datelist });
                    })
                    .catch((err) => console.log("error", err));
                }
              })
              .catch((err) => console.log("pepep", err));
          }
        })
        .catch((err) => console.log("res", err));
    } else {
      alert("Connect to wallet");
    }
  }

  render() {
    const { lotterylist, datelist } = this.state;

    const mergeArray = (lotterylist, datelist, prop) => {
      datelist?.forEach((datelistElement) => {
        let lotterylistElement = lotterylist.find((lotterylistElement) => {
          return datelistElement[prop] === lotterylistElement[prop];
        });
        lotterylistElement
          ? Object.assign(lotterylistElement, datelistElement)
          : lotterylist.push(datelistElement);
      });
    };

    mergeArray(lotterylist, datelist, "lotteryId");

    // console.log("date", datelist);
    return (
      <div fluid className="p-0 m-0">
        <NavBar />
        <Container style={{ minHeight: "100vh" }}>
          <div className="mx-auto mt-3 ">
            <Row className="lottery-section">
              <Col>
                <h2
                  className="section-title"
                  style={{ fontWeight: "600", fontSize: "28px" }}
                >
                  {" "}
                  Lottery results
                </h2>
              </Col>
            </Row>
            <Row className="mx-auto lottery-section2 text-center">
              {lotterylist.map((item, index) => (
                <Col lg={6}>
                  <ResultsCard
                    key={index}
                    history={this.props.history}
                    item={item}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </Container>
        {/* <FAQ/> */}
      </div>
    );
  }
}

export default Results;
