import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as GiIcons from 'react-icons/gi';
import * as BiIcons from 'react-icons/bi';
import * as FaIcons6 from "react-icons/fa6";

export const AdminSidebarData = [
    {
      title: 'Homepage',
      path: '/admins',
      icon: <AiIcons.AiFillHome />,
      cName: 'nav-text'
    },
    {
      title: 'Creators List',
      path: '/admin/creatorslist',
      icon: <FaIcons.FaOpera />,
      cName: 'nav-text'
    },
    {
      title: 'Lotteries List',
      path: '/admin/lotterylist',
      icon: <FaIcons.FaTicketAlt />,
      cName: 'nav-text'
    },
    {
      title: 'Partners List',
      path: '/admin/partnerslist',
      icon: <FaIcons.FaHandshake />,
      cName: 'nav-text'
    },
    {
      title: 'Gift Cards List',
      path: '/admin/giftcardlist',
      icon: <FaIcons.FaGift />,
      cName: 'nav-text'
    },
    {
      title: 'News',
      path: '/admin/news',
      icon: <FaIcons6.FaNewspaper />,
      cName: 'nav-text'
    },
    {
      title: 'Notifications',
      path: '/admin/notifications',
      icon: <FaIcons.FaRegBell />,
      cName: 'nav-text'
    }
  ];
  