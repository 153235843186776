import React from "react";
import { Container, Col, Row, Card, Image } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import "./counter.css";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

const Roadmap = () => {
  return (
    <Container className="section-bgrd roadmap m-0 section-padding " fluid>
                 <section>
                 <h2 className="text-center section-title" style={{color: '#444', fontWeight:'600'}}>Roadmap</h2>
              <VerticalTimeline className="line" >
                
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#F71000",
                    color: "#fff",
                    borderBottom:'solid 3px #ffffff'
                  }}
                  contentArrowStyle={{
                    borderRight: "7px solid  rgb(247, 16, 0)",
                  }}
                  iconStyle={{ background: "#F71000", color: "#fff" }}
                >
                  <h4 className="vertical-timeline-element-title roadmap-title" style={{color:'white'}}>
                    Lottery Pilot on Testnet
                  </h4>

                  <p className="roadmap-desc" style={{color:'white'}}>
                    Lottery pilot with core features will be launched on
                    testnet for community to try and give feedback. This pilot
                    will be semi automated
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  contentStyle={{
                    background: "#4F80E1",
                    color: "#fff",
                    borderBottom:'solid 3px #ffffff'
                  }}
                  contentArrowStyle={{
                    borderRight: "7px solid  rgb(33, 150, 243)",
                  }}
                  className="vertical-timeline-element--work"
                  iconStyle={{ background: "#4F80E1", color: "#fff" }}
                >
                  <h4 className="vertical-timeline-element-title roadmap-title" style={{color:'white'}}>
                    Lottery on Mainnet
                  </h4>

                  <p className="roadmap-desc" style={{color:'white'}}>
                    Lottery module will be launched on mainnet with fully
                    functional core lottery features. This platform will
                    use governance token of network as currency
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  contentStyle={{
                    background: "#F71000",
                    color: "#fff",
                    borderBottom:'solid 3px #ffffff'
                  }}
                  contentArrowStyle={{
                    borderRight: "7px solid  rgb(247, 16, 0)",
                  }}
                  iconStyle={{ background: "#F71000", color: "#fff" }}
                >
                  <h4 className="vertical-timeline-element-title roadmap-title" style={{color:'white'}}>
                    Tokenization
                  </h4>

                  <p className="roadmap-desc" style={{color:'white'}}>
                    Platform token(BET) will be made available as currency for
                    Lottery module. The token will be  backed by it’s own
                    swapping and liquidity pool modules And , Different assets
                    on the platform will be available as NFT
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  contentStyle={{
                    background: "#4F80E1",
                    color: "#fff",
                    borderBottom:'solid 3px #ffffff'
                  }}
                  contentArrowStyle={{
                    borderRight: "7px solid  rgb(33, 150, 243)",
                  }}
                  className="vertical-timeline-element--work"
                  iconStyle={{ background: "#4F80E1", color: "#fff" }}
                >
                  <h4 className="vertical-timeline-element-title roadmap-title" style={{color:'white'}}>
                    LotteryHub
                  </h4>

                  <p className="roadmap-desc" style={{color:'white'}}>
                    Different variations and different types of lotteries will be
                    introduced such as single player draw, multiplayer draw
                    and scheduled automated draw.
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  contentStyle={{
                    background: "#F71000",
                    color: "#fff",
                    borderBottom:'solid 3px #ffffff'
                  }}
                  contentArrowStyle={{
                    borderRight: "7px solid  rgb(247, 16, 0)",
                  }}
                  className="vertical-timeline-element--work"
                  iconStyle={{ background: "#F71000", color: "#fff" }}
                >
                  <h4 className="vertical-timeline-element-title roadmap-title" style={{color:'white'}}>
                    DeFi Ecosystem
                  </h4>

                  <p className="roadmap-desc" style={{color:'white'}}>
                    BET token will be connected to various existing DeFi
                    platforms to provide investment opportunities for community.
                    Ecosystem will provide opportunity to earn rewards and
                    passive income through BET as well as NFT
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  contentStyle={{
                    background: "#4F80E1",
                    color: "#fff",
                    borderBottom:'solid 3px #ffffff'
                  }}
                  contentArrowStyle={{
                    borderRight: "7px solid  rgb(33, 150, 243)",
                  }}
                  className="vertical-timeline-element--work"
                  iconStyle={{ background: "#4F80E1", color: "#fff" }}
                >
                  <h4 className="vertical-timeline-element-title roadmap-title" style={{color:'white'}}>
                    Cryptogames
                  </h4>

                  <p className="roadmap-desc" style={{color:'white'}}>
                    Followed by lottery various cryptogames will be launched
                    including gambling and casino games.
                  </p>
                </VerticalTimelineElement>
              </VerticalTimeline>
        
            </section>
     
         </Container>
  );
};
export default Roadmap;
