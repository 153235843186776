import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router";
import axios from "axios";
import NavBar from "../../../Components/NavBar";
import { Link } from "react-router-dom";
import cinstance from "../../../Service/randomcinstance";
import ListCard from "../../../Components/ListCard";
import LotteryCard from "../../../Components/mine-card/MineCard";
import SpinWheel from "../../../Components/SpinWheel/SpinWheel";
import "./AllLotteriesStyle.css";

const AllLotteries = (props) => {
  const [lotterylist1, setLotteryList1] = useState([]);
  const [lotterylist2, setLotteryList2] = useState([]);
  const [lotterylist3, setLotteryList3] = useState([]);
  const [lotterylist4, setLotteryList4] = useState([]);
  const [partnerList1, setPartnerList1] = useState([]);
  const [partnerList2, setPartnerList2] = useState([]);
  const [partnerList3, setPartnerList3] = useState([]);
  const [partnerList4, setPartnerList4] = useState([]);
  const [partnerLogoData, setPartnerLogoData] = useState([]);
  let history = useHistory();
  const apiUrl = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    axios.get(`${apiUrl}/s3imageupload/getAllPartnerLogos`).then((response) => {
      const partnerData = response.data.result;
      setPartnerLogoData(partnerData);
    });
    cinstance.methods
      .lotteryId()
      .call()
      .then((res) => {
        for (var i = 1; i < res; i++) {
          cinstance.methods
            .lottery(i)
            .call()
            .then((response) => {
              if (response.status !== "2") {
                if (response.lotteryType == 0) {
                  if (response.status === "0" || response.status === "5") {
                    lotterylist1.push(response);
                    setLotteryList1(() =>
                      [...lotterylist1].sort(
                        (a, b) => b.lotteryId - a.lotteryId
                      )
                    );
                    cinstance.methods
                      .partnerbyaddr(response.partnerAddress)
                      .call()
                      .then((responsee) => {
                        partnerList1.push(responsee);
                        setPartnerList1(() => [...partnerList1]);
                      })
                      .catch((err) => console.log("error", err));
                  }
                } else if (response.lotteryType == 2) {
                  if (
                    response.status === "0" ||
                    response.status === "3" ||
                    response.status === "5"
                  ) {
                    lotterylist2.push(response);
                    setLotteryList2(() =>
                      [...lotterylist2].sort(
                        (a, b) => b.lotteryId - a.lotteryId
                      )
                    );
                    cinstance.methods
                      .partnerbyaddr(response.partnerAddress)
                      .call()
                      .then((responsee) => {
                        partnerList2.push(responsee);
                        setPartnerList2(() => [...partnerList2]);
                      })
                      .catch((err) => console.log("error", err));
                  }
                } else if (response.lotteryType == 1) {
                  if (response.status === "0" || response.status === "5") {
                    if (response?.lotteryId) {
                      lotterylist3.push(response);
                      setLotteryList3(() =>
                        [...lotterylist3].sort(
                          (a, b) => b.lotteryId - a.lotteryId
                        )
                      );
                      cinstance.methods
                        .partnerbyaddr(response.partnerAddress)
                        .call()
                        .then((responsee) => {
                          partnerList3.push(responsee);
                          setPartnerList3(() => [...partnerList3]);
                        })
                        .catch((err) => console.log("error", err));
                    }
                  }
                } else {
                  if (
                    response.status === "0" ||
                    response.status === "3" ||
                    response.status === "5"
                  ) {
                    lotterylist4.push(response);
                    setLotteryList4(() =>
                      [...lotterylist4].sort(
                        (a, b) => b.lotteryId - a.lotteryId
                      )
                    );
                    cinstance.methods
                      .partnerbyaddr(response.partnerAddress)
                      .call()
                      .then((responsee) => {
                        partnerList4.push(responsee);
                        setPartnerList4(() => [...partnerList4]);
                      })
                      .catch((err) => console.log("error", err));
                  }
                }
              }
            });
        }
      })
      .catch((err) => console.log("error", err));
  }, []);

  const findPartnerByAddress = (address, partnerList) => {
    return (
      partnerList?.find((partner) => partner.partnerAddress === address) || {}
    );
  };

  return (
    <div fluid className="p-0 m-0 allLottery">
      <NavBar />
      <Container>
        <div className="mx-auto mt-3 ">
          <Row className="lottery-section">
            <Col>
              <h2
                className="section-title"
                style={{ fontWeight: "600", fontSize: "28px" }}
              >
                Explosive Lotteries
              </h2>
            </Col>
          </Row>
          <Row className="mx-auto lottery-section2 text-center">
            <Col xs="12" className=" mb-2">
              <h3 className="text-left">
                <b>Autobet MRL</b>
              </h3>
            </Col>
            {lotterylist2.length === 0 && (
              <Col xs="12">
                <h5 className="text-left mt-2">No Active Lotteries !</h5>
              </Col>
            )}
            {lotterylist2.length > 0 &&
              lotterylist2.map((item, index) => {
                const partnerData = findPartnerByAddress(
                  item.partnerAddress,
                  partnerList2
                );
                return (
                  <Col lg={4} key={index + "lotterylist"}>
                    <ListCard
                      key={index}
                      item={item}
                      history={props.history}
                      partner={partnerData}
                      partnerLogo={partnerLogoData}
                    />
                  </Col>
                );
              })}
          </Row>
          <Row className="mx-auto lottery-section2 text-center">
            <Col xs="12" className=" mb-2">
              <h3 className="text-left">
                <b>Autobet Revolver</b>
              </h3>
            </Col>
            {lotterylist1.length === 0 && (
              <Col xs="12">
                <h5 className="text-left mt-2">No Active Lotteries !</h5>
              </Col>
            )}
            {lotterylist1.length > 0 &&
              lotterylist1.map((item, index) => {
                const partnerData = findPartnerByAddress(
                  item.partnerAddress,
                  partnerList1
                );
                return (
                  <Col lg={4} key={`spinner${index}`}>
                    <SpinWheel
                      lotteryData={item}
                      partner={partnerData}
                      partnerLogo={partnerLogoData}
                    />
                  </Col>
                );
              })}
          </Row>
          <Row className="mx-auto lottery-section2 text-center">
            <Col xs="12" className=" mb-2">
              <h3 className="text-left">
                <b>Autobet Mine</b>
              </h3>
            </Col>
            {lotterylist3.length === 0 && (
              <Col xs="12">
                <h5 className="text-left mt-2">No Active Lotteries !</h5>
              </Col>
            )}
            {lotterylist3.length > 0 &&
              lotterylist3?.map((item, index) => {
                const partnerData = findPartnerByAddress(
                  item.partnerAddress,
                  partnerList3
                );
                return (
                  <Col lg={4} key={index + "lotterylist"}>
                    <LotteryCard
                      key={index}
                      history={props.history}
                      item={item}
                      partner={partnerData}
                      partnerLogo={partnerLogoData}
                    />
                  </Col>
                );
              })}
          </Row>
          <Row className="mx-auto lottery-section2 text-center">
            <Col xs="12" className="mb-2">
              <h3 className="text-left">
                <b>Autobet Missile</b>
              </h3>
            </Col>
            {lotterylist4.length === 0 && (
              <Col xs="12">
                <h5 className="text-left mt-2">No Active Lotteries !</h5>
              </Col>
            )}
            {lotterylist4.length > 0 &&
              lotterylist4?.map((item, index) => {
                const partnerData = findPartnerByAddress(
                  item.partnerAddress,
                  partnerList4
                );
                return (
                  <Col lg={4} key={index + "lotterylist"}>
                    <LotteryCard
                      key={index}
                      history={props.history}
                      item={item}
                      lotteryType={"missile"}
                      partner={partnerData}
                      partnerLogo={partnerLogoData}
                    />
                  </Col>
                );
              })}
          </Row>
        </div>
      </Container>
    </div>
  );
};
export default AllLotteries;
