import React, { Component } from "react";
import { Container, Col, Row, Card, Button } from "react-bootstrap";
import axios from "axios";
import cinstance from "../../Service/randomcinstance";
import usercinstance from "../../Service/usercinstance";
import giftcardcinstance from "../../Service/giftcardcinstance";
import web3 from "../../Service/web3";
import NavBar from "../../Components/NavBar";
import ListCard from "../../Components/ListCard";
import HowToPlay from "../../Components/HowToPlay";
import Roadmap from "../../Components/Roadmap";
import Background from "../../../static/Images/bgnew.png";
import LotteryCard2 from "../../Components/mine-card/MineCard";
import Banner from "../../Components/Banner";
import { Path } from "../../../static/Constants";
import FooterLinks from "../../Components/FooterLinks";
import moment from "moment";
import FadeCards from "../../Components/FadeCards";
import BookiesCards from "../../Components/BookiesCard";
import Whyus from "../../Components/Whyus";
import SpinWheel from "../../Components/SpinWheel/SpinWheel";
import Warnings from "../../Components/Warning";
import GiftCard from "../../Components/gift-card/GiftCard";
import FundRaise from "../../Components/FundRaise";
import { networkID } from "../../../static/utils";
import PartnersCarousel from "../../Components/partners-carousel/PartnersCarousel";
class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seed: "",
      loading: false,
      Randomresult: "",
      bookiesarray: [],
      winner: "",
      lotterylist: [],
      lotterylist2: [],
      lotterylist3: [],
      giftcardlist: [],
      partnerlist: [],
      partnerLogoData: [],
      giftcardLogoData: [],
      buytotal: 0,
      totallotteries: 0,
      bookies: 0,
      draws: 0,
      wins: 0,
      net: false,
      warn: false,
    };
  }

  async componentDidMount() {
    let lotterylist = [];
    const apiUrl = process.env.REACT_APP_API_KEY;
    if (window.ethereum) {
      this.getglobaldata();
      web3.eth.net.getId().then((netId) => {
        if (netId == networkID) {
          window.web3 = web3;

          this.setState({ warn: false });
        } else {
          this.setState({ warn: true, net: true });
        }
      });
    } else {
      //  this.getglobaldata();
    }

    axios.get(`${apiUrl}/s3imageupload/getAllPartnerLogos`).then((response) => {
      const partnerData = response.data.result;
      this.setState({ partnerLogoData: partnerData });
    });

    axios.get(`${apiUrl}/giftcardlogos/getAllGiftcardLogos`).then((response) => {
      const giftcardData = response.data.result;
      this.setState({ giftcardLogoData: giftcardData });
    });
  }

  getglobaldata = () => {
    var lotterylist1 = [];
    var lotterylist2 = [];
    var lotterylist3 = [];
    var lotterylist4 = [];
    var partnerlists1 = [];
    var partnerlists2 = [];
    var partnerlists3 = [];
    var partnerlists4 = [];
    var giftcardlist = [];
    var partnerlist = [];
    var s2 = [],
      bookiesarray = [];
    var counts = 0;
    Promise.all([
      cinstance.methods
        .totalSale()
        .call()
        .then((res) => {
          this.setState({ buytotal: res });
        }),
      cinstance.methods
        .totalDraws()
        .call()
        .then((res) => {
          this.setState({ draws: res });
        }),
      cinstance.methods
        .totalWinners()
        .call()
        .then((res) => {
          this.setState({ wins: res });
        }),
      usercinstance.methods
        .ownerId()
        .call()
        .then((res) => {
          this.setState({ bookies: res });
          for (var i = res - 1; i > 0; i--) {
            usercinstance.methods
              .organisationbyid(i)
              .call()
              .then((responseAddress) => {
                usercinstance.methods
                  .organisationbyaddr(responseAddress)
                  .call()
                  .then((response) => {
                    bookiesarray.push(response);
                    this.setState({ bookiesarray });
                  });
              })
              .catch((err) => console.log("pepep", err));
          }
        })
        .catch((err) => console.log("pepep", err)),

      cinstance.methods
        .lotteryId()
        .call()
        .then((res) => {
          this.setState({ totallotteries: res });
          for (var i = 1; i < res; i++) {
            if (counts == 3) break;
            cinstance.methods
              .lottery(i)
              .call()
              .then((response) => {
                if (response.status !== "2") {
                  if (response.lotteryType == 0) {
                    if (response.status === "0" || response.status === "5") {
                      lotterylist1.push(response);
                      cinstance.methods
                        .partnerbyaddr(response.partnerAddress)
                        .call()
                        .then((responsee) => {
                          partnerlists1.push(responsee);
                          this.setState({ partnerlists1 });
                        })
                        .catch((err) => console.log("error", err));
                    }
                  } else if (response.lotteryType == 2) {
                    if (
                      response.status === "0" ||
                      response.status === "3" ||
                      response.status === "5"
                    ) {
                      lotterylist2.push(response);
                      cinstance.methods
                        .partnerbyaddr(response.partnerAddress)
                        .call()
                        .then((responsee) => {
                          partnerlists2.push(responsee);
                          this.setState({ partnerlists2 });
                        })
                        .catch((err) => console.log("error", err));
                    }
                  } else if (response.lotteryType == 1) {
                    if (response.status === "0" || response.status === "5") {
                      if (response?.lotteryId) lotterylist3.push(response);

                      cinstance.methods
                        .partnerbyaddr(response.partnerAddress)
                        .call()
                        .then((responsee) => {
                          partnerlists3.push(responsee);
                          this.setState({ partnerlists3 });
                        })
                        .catch((err) => console.log("error", err));
                    }
                  } else {
                    // handle missile lottery
                    if (
                      response.status === "0" ||
                      response.status === "3" ||
                      response.status === "5"
                    ) {
                      lotterylist4.push(response);
                      cinstance.methods
                        .partnerbyaddr(response.partnerAddress)
                        .call()
                        .then((responsee) => {
                          partnerlists4.push(responsee);
                          this.setState({ partnerlists4 });
                        })
                        .catch((err) => console.log("error", err));
                    }
                  }

                  lotterylist1.sort((a, b) => b.lotteryId - a.lotteryId);
                  lotterylist2.sort((a, b) => b.lotteryId - a.lotteryId);
                  lotterylist3.sort((a, b) => b.lotteryId - a.lotteryId);
                  lotterylist4.sort((a, b) => b.lotteryId - a.lotteryId);

                  this.setState({
                    lotterylist1,
                    lotterylist2,
                    lotterylist3,
                    lotterylist4,
                  });
                  counts++;
                }
              })
              .catch((err) => console.log("pepep", err));
          }
        })
        .catch((err) => console.log("res", err)),

      cinstance.methods
        .partnerId()
        .call()
        .then((res) => {
          for (var i = 0; i <= res; i++) {
            cinstance.methods
              .partnerids(i)
              .call()
              .then((responseAdd) => {
                cinstance.methods
                  .partnerbyaddr(responseAdd)
                  .call()
                  .then((response) => {
                    if (
                      response.partnerAddress !=
                      "0x0000000000000000000000000000000000000000"
                    ) {
                      partnerlist.push(response);
                      this.setState({ partnerlist });
                    }
                  });
              })
              .catch((err) => console.log("pepep", err));
          }
        })
        .catch((err) => console.log("res", err)),

      giftcardcinstance.methods
        .giftCardId()
        .call()
        .then((res) => {
          for (var i = 0; i < res; i++) {
            giftcardcinstance.methods
              .giftCards(i)
              .call()
              .then((response) => {
                let currentDT = moment().unix();
                if (response.isSold === false && response.expDate > currentDT) {
                  giftcardlist.push(response);
                  this.setState({ giftcardlist });
                }
              })
              .catch((err) => console.log("pepep", err));
          }
          giftcardlist.sort((a, b) => b.giftCardId - a.giftCardId);
          this.setState({ giftcardlist });
        })
        .catch((err) => console.log("pepep", err)),
    ])
      .then(() => {
        const scrollToSection = window.location.hash.slice(1);
        if (scrollToSection === "partners") {
          const partnerSection = document.getElementById("partner");
          if (partnerSection) {
            partnerSection.scrollIntoView({ behavior: "smooth" });
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  render() {
    var sectionStyle = {
      backgroundImage: "url(" + { Background } + ")",
    };
    const {
      warn,
      lotterylist1,
      lotterylist2,
      lotterylist3,
      lotterylist4,
      bookies,
      partnerlists1,
      partnerlists2,
      partnerlists3,
      partnerlists4,
      giftcardlist,
      partnerlist,
      partnerLogoData,
      giftcardLogoData,
      totallotteries,
      buytotal,
      draws,
      wins,
      net,
    } = this.state;

    //Merge lottery array
    //  const mergeArray = (lotterylist, partnerlists, prop) => {
    //   partnerlists?.forEach((partnerlistsElement) => {
    //     const { status, ...partnerlistsElementWithoutStatus } =
    //       partnerlistsElement;
    //     const lotterylistElement = lotterylist.find((lotterylistElement) => {
    //       return partnerlistsElement[prop] === lotterylistElement[prop];
    //     });
    //     if (lotterylistElement) {
    //       Object.assign(lotterylistElement, partnerlistsElementWithoutStatus);
    //       lotterylistElement.partnerStatus = status;
    //     } else {
    //       lotterylist.push({
    //         ...partnerlistsElementWithoutStatus,
    //         partnerStatus: status,
    //       });
    //     }
    //   });
    // };

    // mergeArray(lotterylist1, partnerlists1, "partnerAddress");
    // mergeArray(lotterylist2, partnerlists2, "partnerAddress");
    // mergeArray(lotterylist3, partnerlists3, "partnerAddress");
    // mergeArray(lotterylist4, partnerlists4, "partnerAddress");

    // console.log("partner list", partnerlist);

    const findPartnerByAddress = (address, partnerList) => {
      return (
        partnerList?.find((partner) => partner.partnerAddress === address) || {}
      );
    };

    return (
      <div fluid className="p-0 m-0 " id="homePage" style={sectionStyle}>
        <NavBar />
        <Banner
          bookies={bookies === 0 ? "0" : Math.abs(bookies - 1)}
          lotteries={totallotteries === 0 ? "0" : Math.abs(totallotteries - 1)}
          buytotal={buytotal}
          draws={draws}
          wins={wins}
        />

        <Container className="lottery-container container-p0">
          {lotterylist2 !== undefined && lotterylist2?.length !== 0 && (
            <div className="mx-auto mt-3 mb-4 ">
              <Row className="mx-auto lottery-section text-center">
                <Col>
                  <h2
                    className="text-center section-title"
                    style={{ fontWeight: "600", color: "#444" }}
                  >
                    Autobet MRL
                  </h2>
                  {/* <p>Try for a chance to win a big cash prize</p> */}
                </Col>
              </Row>
              <Row className="mx-auto lottery-section2 text-center">
                {lotterylist2?.slice(0, 3)?.map((item, index) => {
                  const partnerData = findPartnerByAddress(
                    item.partnerAddress,
                    partnerlists2
                  );
                  return (
                    <Col lg={4} key={index + "lotterylist"}>
                      <ListCard
                        key={index}
                        history={this.props.history}
                        item={item}
                        partner={partnerData}
                        partnerLogo={partnerLogoData}
                      />
                    </Col>
                  );
                })}
              </Row>

              {lotterylist2?.length !== 0 && (
                <Row className="mx-auto text-center">
                  <a
                    href={Path.alllottery}
                    className="text-btn"
                    style={{ textDecoration: "none" }}
                  >
                    Show all autobet MRL
                  </a>
                </Row>
              )}
            </div>
          )}
        </Container>
        <Container className="lottery-container container-p0">
          {lotterylist1 !== undefined && lotterylist1?.length !== 0 && (
            <div className="mx-auto mt-3 mb-4 ">
              <Row className="mx-auto lottery-section text-center">
                <Col>
                  <h2
                    className="text-center section-title"
                    style={{ fontWeight: "600", color: "#444" }}
                  >
                    Autobet Revolver
                  </h2>
                </Col>
              </Row>
              <Row className="mx-auto lottery-section2 revolver-section text-center">
                {lotterylist1 &&
                  lotterylist1?.slice(0, 3)?.map((items, index) => {
                    const partnerData = findPartnerByAddress(
                      items.partnerAddress,
                      partnerlists1
                    );
                    return (
                      <Col lg={4} key={`spinner${index}`}>
                        <SpinWheel
                          lotteryData={items}
                          partner={partnerData}
                          partnerLogo={partnerLogoData}
                        />
                      </Col>
                    );
                  })}
              </Row>
              {lotterylist1?.length !== 0 && (
                <Row className="mx-auto text-center">
                  <a
                    href={Path.Spinnerlottery}
                    className="text-btn"
                    style={{ textDecoration: "none" }}
                  >
                    Show all Autobet Revolver
                  </a>
                </Row>
              )}
            </div>
          )}
        </Container>
        <Container className="lottery-container container-p0">
          {lotterylist3 !== undefined && lotterylist3?.length !== 0 && (
            <div className="mx-auto mt-3 mb-4 ">
              <Row className="mx-auto lottery-section text-center">
                <Col>
                  <h2
                    className="text-center section-title"
                    style={{ fontWeight: "600", color: "#444" }}
                  >
                    Autobet Mine
                  </h2>
                </Col>
              </Row>
              <Row className="mx-auto lottery-section2 text-center">
                {lotterylist3?.slice(0, 3)?.map((item, index) => {
                  const partnerData = findPartnerByAddress(
                    item.partnerAddress,
                    partnerlists3
                  );
                  return (
                    <Col lg={4} key={index + "lotterylist"}>
                      <LotteryCard2
                        key={index}
                        history={this.props.history}
                        item={item}
                        partner={partnerData}
                        partnerLogo={partnerLogoData}
                      />
                    </Col>
                  );
                })}
              </Row>

              {lotterylist3?.length !== 0 && (
                <Row className="mx-auto text-center">
                  <a
                    href={Path.allminelottery}
                    className="text-btn"
                    style={{ textDecoration: "none" }}
                  >
                    Show all Autobet Mine
                  </a>
                </Row>
              )}
            </div>
          )}
        </Container>
        <Container className="lottery-container container-p0">
          {lotterylist4 !== undefined && lotterylist4?.length !== 0 && (
            <div className="mx-auto mt-3 mb-4 ">
              <Row className="mx-auto lottery-section text-center">
                <Col>
                  <h2
                    className="text-center section-title"
                    style={{ fontWeight: "600", color: "#444" }}
                  >
                    Autobet Missile
                  </h2>
                </Col>
              </Row>
              <Row className="mx-auto lottery-section2 text-center">
                {lotterylist4?.slice(0, 3)?.map((item, index) => {
                  const partnerData = findPartnerByAddress(
                    item.partnerAddress,
                    partnerlists4
                  );
                  return (
                    <Col lg={4} key={index + "lotterylist"}>
                      <LotteryCard2
                        key={index}
                        history={this.props.history}
                        item={item}
                        lotteryType={"missile"}
                        partner={partnerData}
                        partnerLogo={partnerLogoData}
                      />
                    </Col>
                  );
                })}
              </Row>

              {lotterylist4?.length !== 0 && (
                <Row className="mx-auto text-center">
                  <a
                    href={Path.allmissilelottery}
                    className="text-btn"
                    style={{ textDecoration: "none" }}
                  >
                    Show all Autobet Missile
                  </a>
                </Row>
              )}
            </div>
          )}
        </Container>

        <Container className="lottery-container container-p0">
          {giftcardlist !== undefined && giftcardlist?.length !== 0 && (
            <div className="mx-auto mt-3 mb-4 ">
              <Row className="mx-auto lottery-section text-center">
                <Col>
                  <h2
                    className="text-center section-title"
                    style={{ fontWeight: "600", color: "#444" }}
                  >
                    Autobet Gift Card
                  </h2>
                </Col>
              </Row>
              <Row className="mx-auto lottery-section2 text-center">
                {Array.isArray(giftcardlist) &&
                  giftcardlist?.slice(0, 3)?.map((item, index) => (
                    <Col lg={4} key={index + "lotterylist"}>
                      <GiftCard
                        key={index}
                        history={this.props.history}
                        item={item}
                        giftcardLogo={giftcardLogoData}
                      />
                    </Col>
                  ))}
              </Row>

              {giftcardlist?.length !== 0 && (
                <Row className="mx-auto text-center">
                  <a
                    href={Path.allgiftcards}
                    className="text-btn"
                    style={{ textDecoration: "none" }}
                  >
                    Show all Autobet Gift Cards
                  </a>
                </Row>
              )}
            </div>
          )}
        </Container>

        <FundRaise />
        <Whyus />
        <HowToPlay />
        <Roadmap />
        <div id="partner">
          {/* {partnerlist !== undefined && partnerlist?.length !== 0 && ( */}
          <div className="section-padding">
            <Container className="lottery-container container-p0">
              <div className="mx-auto mt-3 mb-4 ">
                <Row className="mx-auto lottery-section text-center">
                  <Col xs={12}>
                    <h1
                      className="text-center section-title"
                    >
                      <span className="red-text-color">AutoBET</span> invites eligible partners that aim to support
                      charity and web3 development organizations
                    </h1>
                    <p
                      className="section-desc text-center my-3"
                    >
                      Organizations that meet AutoBET set criteria are eligible
                      to Join and earn on the platform. You can check the
                      Frequently Asked Questions <a href="/help">(FAQ)</a> for
                      more details or enquire{" "}
                      <a href="mailto:info@autobetlottery.com">
                        info@autobetlottery.com
                      </a>{" "}
                      for more details about partnership.
                    </p>
                  </Col>
                </Row>
                <Row className="mx-auto text-center">
                  <Col className="p-0 m-0">
                    <PartnersCarousel data={partnerlist} />
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          {/* )} */}
        </div>
        {/* <FooterLinks /> */}
      </div>
    );
  }
}

export default Homepage;
