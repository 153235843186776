import React from "react";
import { Row, Col, Container, Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import "../../static/css/popup.css";

const SuccessPopup = ({ show, setShowModal, redirectPath, message, btnName }) => {
    let history = useHistory();
  return (
    <Modal
      className="PopupStyle"
      show={show}
      onHide={() => {
        setShowModal(false);
      }}
      centered
    >
      <Modal.Body>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="message-text">{message}</div>
            </Col>
            <Col lg={12} sm={12} xs={12}>
                {redirectPath !== undefined ? (<div className="btn-div">
                <Button
                  className="submit-btn success-btn"
                  onClick={() => {
                    history.push(redirectPath); setShowModal(false);
                  }}
                >
                 {btnName}
                </Button>
              </div>):(<div className="btn-div">
                <Button
                  className="submit-btn success-btn"
                  onClick={() => {
                  setShowModal(false);
                  }}
                >
                  Close
                </Button>
              </div>)}
              
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(SuccessPopup);
