import { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Modal,
} from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import AdminNavBar from "../../Components/AdminNavBar";
import cinstance from "../../Service/randomcinstance";
import web3 from "../../Service/web3";
import moment from "moment";
import getImageUrlByHash from "../../../static/logoUtils";
import Loaders from "../../Components/Loaders";
import Autobet from "../../../static/Images/logo.png";
import { FaTimes, FaEdit } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import ButtonCommon from "../../Components/ButtonCommon";
import axios from "axios";
import SuccessPopup from "../../Components/SuccessPopup";
import FailedPopup from "../../Components/FailedPopup";
import "../../../static/css/partnerlist.css";

function PartnersList() {
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [showEditContent, setEdit] = useState("");
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [file, setfile] = useState(null);
  const [partnerName, setPartnerName] = useState("");
  const [validated, setValidated] = useState(false);
  const [PartnerAddress, setPartnerAddress] = useState("");
  const [partnerURL, setPartnerURL] = useState("");
  const [status, setStatus] = useState(false);
  const [LogoHash, setLogoHash] = useState("");
  const [loading, setloading] = useState(false);
  const [active, setactive] = useState(false);
  const [CreatedOn, setCreatedOn] = useState(new Date());
  const [error, seterror] = useState(false);
  const [partnerlist, setPartnerlist] = useState([]);
  const [partnerLogoData, setPartnerLogoData] = useState("");
  var tempWalletAddress = "0x14bC544080E77958F2D87e9Bf72C885BD67335A2";
  const [showAddSuccess, setShowAddSuccess] = useState(false);
  const [showEditSuccess, setShowEditSuccess] = useState(false);
  const [showFailed, setShowFailed] = useState(false);
  const apiUrl = process.env.REACT_APP_API_KEY;

  const handleSwitchChange = (e) => {
    setStatus(!status);
  };
  const handleClosePopup = () => {
    setPartnerName("");
    setStatus(false);
    setPartnerAddress("");
    setValidated(false);
    setShowAddPopup(false);
  };

  const onSendmsg = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      setactive(true);
      event.preventDefault();
      let account = await web3.eth.getAccounts();
      let formData = new FormData();
      formData.append("file", file);
      setloading(true);
      seterror("");

      if (file != null) {
        axios
          .post("https://ipfs.infura.io:5001/api/v0/add?pin=false", formData, {
            headers: {
              "Content-Type": `multipart/form-data`,
            },
            auth: {
              username: "2Itj30QgBHxl9PizuBlwM6vhd77",
              password: "271d404efd8c78baae273b4a7a5b6747",
            },
          })
          .then((response) => {
            if (response.status == 200) {
              const ipfsHash = response?.data?.Hash;
              let newFormData = new FormData();
              newFormData.append("file", file);
              newFormData.append("logoHash", ipfsHash);
              axios
                .post(
                  `${apiUrl}/s3imageupload/uploadPartnerLogo`,
                  newFormData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
                .then((apiResponse) => {
                  // console.log("API Response", apiResponse);

                  cinstance.methods
                    .addEditPartnerDetails(
                      partnerName,
                      response?.data?.Hash,
                      status,
                      partnerURL,
                      PartnerAddress,
                      moment(CreatedOn).unix()
                    )
                    .send({ from: account[0], value: 0 })
                    .then((res) => {
                      setPartnerName("");
                      setStatus(false);
                      setPartnerAddress("");
                      setfile(null);
                      setloading(false);
                      getList();
                      setShowAddPopup(false);
                      setShowAddSuccess(true);
                    })
                    .catch((e) => {
                      console.log("e", e);
                      setloading(false);
                      setShowFailed(true);
                    });
                })
                .catch((apiError) => {
                  console.error("API Error", apiError);
                  setloading(false);
                  setShowFailed(true);
                });
            }
          })
          .catch((er) => {
            console.log(er);
          });
      } else {
        seterror("Select Logo Image");
        setloading(false);
      }
    } else {
      event.preventDefault();
      setloading(false);

      seterror("All fields are mandatory");
    }
  };

  const onEditSend = async (event) => {
    // console.log("edit called");
    // console.log(partnerName, PartnerAddress, status, CreatedOn, LogoHash);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      setactive(true);
      event.preventDefault();
      let account = await web3.eth.getAccounts();
      let formData = new FormData();
      formData.append("file", file);
      console.log("check file ", formData);
      setloading(true);
      seterror("");
      if (file != null) {
        axios
          .post("https://ipfs.infura.io:5001/api/v0/add?pin=false", formData, {
            headers: {
              "Content-Type": `multipart/form-data`,
            },
            auth: {
              username: "2Itj30QgBHxl9PizuBlwM6vhd77",
              password: "271d404efd8c78baae273b4a7a5b6747",
            },
          })
          .then((response) => {
            if (response.status == 200) {
              const ipfsHash = response?.data?.Hash;
              let newFormData = new FormData();
              newFormData.append("file", file);
              newFormData.append("logoHash", ipfsHash);
              axios
                .post(
                  `${apiUrl}/s3imageupload/uploadPartnerLogo`,
                  newFormData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
                .then((apiResponse) => {
                  // console.log("API Response", apiResponse);
                  cinstance.methods
                    .addEditPartnerDetails(
                      partnerName,
                      response?.data?.Hash,
                      status,
                      partnerURL,
                      PartnerAddress,
                      moment(CreatedOn).unix()
                    )
                    .send({ from: account[0], value: 0 })
                    .then((res) => {
                      setloading(false);
                      getList();
                      setfile(null);
                      setShowEditPopup(false);
                    })
                    .catch((e) => {
                      console.log("e", e);
                      setloading(false);
                    });
                })
                .catch((apiError) => {
                  console.error("API Error", apiError);
                  setloading(false);
                  setShowFailed(true);
                });
            }
          })
          .catch((er) => {
            console.log(er);
            setloading(false);
          });
      } else {
        cinstance.methods
          .addEditPartnerDetails(
            partnerName,
            LogoHash,
            status,
            partnerURL,
            PartnerAddress,
            moment(CreatedOn).unix()
          )
          .send({ from: account[0], value: 0 })
          .then((res) => {
            setloading(false);
            setShowEditPopup(false);
            getList();
            setfile(null);
            setShowEditSuccess(true);
          })
          .catch((e) => {
            console.log("e", e);
            setloading(false);
            setShowFailed(true);
          });
      }
    } else {
      event.preventDefault();
      setloading(false);

      seterror("All fields are mandatory");
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    var partnerlists = [];
    if (window.ethereum) {
      window.web3 = web3;
      cinstance.methods
        .admin()
        .call()
        .then(async (p) => {
          let account = await web3.eth.getAccounts();
          if (p == account[0]) {
            setShow(true);
            axios
              .get(`${apiUrl}/s3imageupload/getAllPartnerLogos`)
              .then((response) => {
                const partnerData = response.data.result;
                setPartnerLogoData(partnerData);
                console.log("api partner data", partnerData);
              });
            cinstance.methods
              .partnerId()
              .call()
              .then((res) => {
                for (var i = 0; i <= res; i++) {
                  cinstance.methods
                    .partnerids(i)
                    .call()
                    .then((responseAdd) => {
                      cinstance.methods
                        .partnerbyaddr(responseAdd)
                        .call()
                        .then((response) => {
                          if (
                            response.partnerAddress !=
                            "0x0000000000000000000000000000000000000000"
                          ) {
                            partnerlists.push(response);
                            setPartnerlist(() => [...partnerlists]);
                          }
                        });
                    })
                    .catch((err) => console.log("pepep", err));
                }
              })
              .catch((err) => console.log("res", err));
          }
        });
    }
  };
  console.log("partnerslist", partnerlist);

  return (
    <div className="PartnersList">
      <AdminNavBar />
      {loading && <Loaders />}
      {show == false && (
        <Container className="bgImage  p-o">
          <div className="mspace mt-5">
            <h2>Not an admin</h2>
          </div>
        </Container>
      )}
      {show && (
        <Container className="bgImage  p-o m-0" fluid>
          <div className="mspace1">
            <h3
              className="text-center section-title mt-2"
              style={{ fontWeight: "600", fontSize: "28px", color: "#444" }}
            >
              {" "}
              Partners List
            </h3>
            <Row>
              <Col>
                <div className="add-btn-section">
                  <Button
                    onClick={() => {
                      setShowAddPopup(true);
                    }}
                    className="btn1"
                  >
                    Add Partners
                  </Button>
                </div>
              </Col>
            </Row>
            <div className="ml-4 card-section">
              <Row>
                {partnerlist.length == 0 && (
                  <Col>
                    <div className="text-left">
                      <div className="content">
                        <h4> No Data !</h4>{" "}
                      </div>
                    </div>
                  </Col>
                )}

                {partnerlist.map((item, i) => {
                  const imageUrl = getImageUrlByHash(
                    item.logoHash,
                    partnerLogoData
                  );
                  return (
                    <Col key={`in${i}`} lg={3} md={6} className="card-col">
                      <Card className="list-card">
                        <div className="card-img-block">
                          <img src={imageUrl} className="img-fluid" />
                        </div>
                        <Card.Body>
                          <div className="details">
                            <p>
                              <b>Name : </b> &nbsp;
                              {item.name}
                            </p>
                            <p>
                              <b>Website URL : </b> &nbsp;
                              {item.websiteAdd}
                            </p>
                            <p>
                              <b>Status : </b> &nbsp;
                              {item?.status ? "Active" : "Inactive"}
                            </p>
                            <p style={{ wordBreak: "break-all" }}>
                              <b>Address : </b> &nbsp;
                              {item.partnerAddress}
                            </p>
                          </div>
                          <div className="action">
                            <FaEdit
                              onClick={() => {
                                // handleEdit(item)
                                setShowEditPopup(true);
                                setPartnerName(item.name);
                                setLogoHash(item.logoHash);
                                setPartnerURL(item.websiteAdd);
                                setStatus(item.status);
                                setPartnerAddress(item.partnerAddress);
                                setCreatedOn(item.createdOn * 1000);
                              }}
                              className="cardIcon1"
                            />
                            {/* <FaRegTrashAlt
                            onClick={() => {
                              setShowDelete(true);
                            }}
                            className="cardIcon2"
                          /> */}
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </Container>
      )}

      <Modal
        className="PopupStyle"
        show={showDelete}
        centered
        onClose={() => setShowDelete(false)}
      >
        <Modal.Body>
          <Container>
            <Row>
              <Col lg={12}>
                <div className="message-text">
                  Do you want to delete this partner ?
                </div>
              </Col>
              <Col lg={12} sm={12} xs={12}>
                <div className="btn-div">
                  <Button
                    className="submit-btn btn1"
                    onClick={() => {
                      setShowDelete(false);
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    className="submit-btn"
                    onClick={() => {
                      setShowDelete(false);
                    }}
                  >
                    No
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>

      <Modal
        show={showAddPopup}
        centered
        onClose={() => {
          handleClosePopup();
        }}
      >
        <Modal.Header>
          <span className="popupHeading">Add Partner</span>
          <FaTimes
            className="cross-icon"
            onClick={() => {
              handleClosePopup();
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={onSendmsg}>
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Partner's Name"
                  defaultValue="Mark"
                  value={partnerName}
                  onChange={(event) => setPartnerName(event.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Enter Partner's Name
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Brand Logo</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) => setfile(e?.target?.files?.[0])}
                />
                <Form.Control.Feedback type="invalid">
                  Select Brand Logo
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Website URL</Form.Label>
                <Form.Control
                  required
                  type="url"
                  placeholder="Partner's Website URL"
                  defaultValue="Mark"
                  value={partnerURL}
                  onChange={(event) => setPartnerURL(event.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Enter valid Partner's Website URL
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Status</Form.Label>
                <Form.Check
                  type="switch"
                  id="custom-switch1"
                  label={status ? "Active" : "Inactive"}
                  checked={status}
                  onChange={(e) => handleSwitchChange(e)}
                />
                <Form.Control.Feedback type="invalid">
                  Select Status
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Wallet Address</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Wallet Address"
                  defaultValue="Mark"
                  value={PartnerAddress}
                  onChange={(event) => setPartnerAddress(event.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Enter Wallet Address
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row className="dateInput">
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Created On</Form.Label>
                <DateTimePicker
                  className="dats date-input"
                  format={"dd-MM-yy"}
                  calendarIcon={null}
                  clearIcon={null}
                  disableClock={true}
                  onChange={(value) => setCreatedOn(value)}
                  value={CreatedOn}
                />
              </Form.Group>
            </Form.Row>
            <div className="mt-2 mb-2 text-center">{error}</div>
            <Modal.Footer className="partner-pop">
              <ButtonCommon type="Submit" className="signup-btn" label="Add" />
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showEditPopup}
        centered
        onClose={() => setShowEditPopup(false)}
      >
        <Modal.Header>
          <span className="popupHeading">Edit Partner</span>
          <FaTimes
            className="cross-icon"
            onClick={() => {
              setShowEditPopup(false);
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={onEditSend}>
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Partner's Name"
                  defaultValue="Mark"
                  value={partnerName}
                  onChange={(event) => setPartnerName(event.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Enter Partner's Name
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Brand Logo</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) => setfile(e?.target?.files?.[0])}
                />
                <Form.Control.Feedback type="invalid">
                  Select Brand Logo
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Website URL</Form.Label>
                <Form.Control
                  required
                  type="url"
                  placeholder="Partner's Website URL"
                  defaultValue="Mark"
                  value={partnerURL}
                  onChange={(event) => setPartnerURL(event.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Enter Partner's Website URL
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Status</Form.Label>
                <Form.Check
                  type="switch"
                  id="custom-switch1"
                  label={status ? "Active" : "Inactive"}
                  checked={status}
                  onChange={(e) => handleSwitchChange(e)}
                />
                <Form.Control.Feedback type="invalid">
                  Select Status
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Wallet Address</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Wallet Address"
                  defaultValue="Mark"
                  value={PartnerAddress}
                  onChange={(event) => setPartnerAddress(event.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Enter Wallet Address
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row className="dateInput">
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Created On</Form.Label>
                <DateTimePicker
                  className="dats date-input"
                  format={"dd-MM-yy"}
                  calendarIcon={null}
                  clearIcon={null}
                  disableClock={true}
                  onChange={(value) => setCreatedOn(value)}
                  value={CreatedOn}
                />
              </Form.Group>
            </Form.Row>
            <div className="mt-2 mb-2 text-center">{error}</div>
            <Modal.Footer className="partner-pop">
              <ButtonCommon
                type="Submit"
                className="signup-btn"
                label="Update"
              />
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <SuccessPopup
        show={showAddSuccess}
        setShowModal={(e) => setShowAddSuccess(e)}
        message={"Partner added successfully !"}
      />
      <SuccessPopup
        show={showEditSuccess}
        setShowModal={(e) => setShowEditSuccess(e)}
        message={"Partner edited successfully !"}
      />
      <FailedPopup show={showFailed} setShowModal={(e) => setShowFailed(e)} />
    </div>
  );
}

export default PartnersList;
