import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Modal,
  Table,
} from "react-bootstrap";
import axios from "axios";
import ReactPaginate from "react-paginate";
import AdminNavBar from "../../Components/AdminNavBar";
import cinstance from "../../Service/randomcinstance";
import web3 from "../../Service/web3";
import Loaders from "../../Components/Loaders";
import Autobet from "../../../static/Images/logo.png";
import { FaTimes, FaEdit, FaRegTrashAlt } from "react-icons/fa";
import ButtonCommon from "../../Components/ButtonCommon";
import SuccessPopup from "../../Components/SuccessPopup";
import FailedPopup from "../../Components/FailedPopup";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { ReactComponent as PreviousIcon } from "../../../static/Images/previous.svg";
import { ReactComponent as NextIcon } from "../../../static/Images/next.svg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../../static/css/newslist.css";

function NewsList() {
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [validated, setValidated] = useState(false);
  const [newsDesc, setNewsDesc] = useState("");
  const [loading, setloading] = useState(false);
  const [active, setactive] = useState(false);
  const [error, seterror] = useState("");
  const [showAddSuccess, setShowAddSuccess] = useState(false);
  const [showEditSuccess, setShowEditSuccess] = useState(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [showFailed, setShowFailed] = useState(false);
  const [newsTitle, setNewsTitle] = useState("");
  const [rtf, setRtf] = useState(EditorState.createEmpty());
  const [newsList, setNewsList] = useState("");
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [updateNewsId, setUpdateNewsId] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const limit = 10;
  const apiUrl = process.env.REACT_APP_API_KEY;
  const [file, setFile] = useState("");

  const handleClosePopup = () => {
    setEditorStateFromDescription("");
    setNewsTitle("");
    setValidated(false);
    setShowAddPopup(false);
  };

  const setEditorStateFromDescription = (description) => {
    const contentBlock = htmlToDraft(description);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setRtf(editorState);
    }
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const handleFetchData = async () => {
    if (window.ethereum) {
      window.web3 = web3;
      cinstance.methods
        .admin()
        .call()
        .then(async (p) => {
          let account = await web3.eth.getAccounts();
          if (p == account[0]) {
            setShow(true);

            try {
              const getNewsListFromLocalStorage = () => {
                const savedNews = localStorage.getItem("newsAdmin");
                if (savedNews) {
                  setNewsList(JSON.parse(savedNews));
                }
              };
              let account = await web3.eth.getAccounts();
              const skip = currentPage * limit;

              const response = await fetch(
                `${apiUrl}/news/getAllNews?limit=${limit}&skip=${skip}`,
                {
                  method: "GET",
                  headers: {
                    Authorization: account[0],
                  },
                }
              );

              if (response.ok) {
                const data = await response.json();
                setNewsList(data.result);
                setTotalPages(Math.ceil(data.count / limit));
                localStorage.setItem("newsAdmin", JSON.stringify(data.result));
              } else {
                console.error("Error fetching news:", response.error);
                getNewsListFromLocalStorage();
              }
            } catch (error) {
              console.error("Error fetching news:", error);
            }
          }
        });
    }
  };

  const onSendmsg = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      seterror("All fields are mandatory");
    } else {
      if (
        newsTitle.trim() === "" ||
        rtf.getCurrentContent().hasText() === false
      ) {
        setValidated(true);
        seterror("All fields are mandatory");
        return;
      }

      setloading(true);

      const contentState = rtf.getCurrentContent();
      const newsDescHTML = draftToHtml(convertToRaw(contentState));
      let formData = new FormData();
      formData.append("title", newsTitle);
      formData.append("description", newsDescHTML);
      formData.append("file", file);
      let account = await web3.eth.getAccounts();
      axios
        .post(`${apiUrl}/news/createNews`, formData, {
          headers: {
            Authorization: account[0],
          },
        })
        .then((response) => {
          console.log("get data", response);
          if (response.status === 201) {
            setValidated(false);
            setNewsTitle("");
            setFile("");
            setRtf(EditorState.createEmpty());
            setShowAddPopup(false);
            setShowAddSuccess(true);
            handleFetchData();
          } else {
            seterror("Failed to add news");
            setShowFailed(true);
            setShowAddPopup(false);
          }
          setloading(false);
        })
        .catch((error) => {
          console.error("API request error:", error);
          setShowFailed(true);
          setShowAddPopup(false);
          setloading(false);
          seterror("Failed to add news");
        });
    }
  };

  const onEditSend = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      seterror("All fields are mandatory");
    } else {
      if (
        newsTitle.trim() === "" ||
        rtf.getCurrentContent().hasText() === false
      ) {
        setValidated(true);
        seterror("All fields are mandatory");
        return;
      }

      setloading(true);

      const contentState = rtf.getCurrentContent();
      const newsDescHTML = draftToHtml(convertToRaw(contentState));

      let editFormData = new FormData();
      editFormData.append("title", newsTitle);
      editFormData.append("description", newsDescHTML);
      editFormData.append("file", file);

      let account = await web3.eth.getAccounts();
      axios
        .patch(`${apiUrl}/news/updateNews/${updateNewsId}`, editFormData, {
          headers: {
            Authorization: account[0],
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setValidated(false);
            setNewsTitle("");
            setFile("");
            setRtf(EditorState.createEmpty());
            setShowEditPopup(false);
            setShowEditSuccess(true);
            handleFetchData();
          } else {
            seterror("Failed to add news");
            setShowFailed(true);
            setShowEditPopup(false);
          }
          setloading(false);
        })
        .catch((error) => {
          console.error("API request error:", error);
          setShowFailed(true);
          setShowEditPopup(false);
          setloading(false);
          seterror("Failed to add news");
        });
    }
  };

  const deleteNews = async (id) => {
    const account = await web3.eth.getAccounts();
    setloading(true);
    axios
      .delete(`${apiUrl}/news/delete/${id}`, {
        headers: {
          Authorization: account[0],
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const updatedNewsList = newsList.filter((item) => item.id !== id);
          setNewsList(updatedNewsList);
          localStorage.setItem("newsAdmin", JSON.stringify(updatedNewsList));
          setloading(false);
          setShowDelete(false);
          setShowDeleteSuccess(true);
          handleFetchData();
        } else {
          seterror("Failed to delete news");
          setShowFailed(true);
          setloading(false);
          setShowDelete(false);
        }
      })
      .catch((error) => {
        console.error("API request error:", error);
        seterror("Failed to delete news");
        setShowFailed(true);
        setloading(false);
        setShowDelete(false);
      });
  };

  const handleToggleFeature = async (newsId, featured) => {
    const account = await web3.eth.getAccounts();

    try {
      const response = await axios.put(
        `${apiUrl}/news/featured/${newsId}`,
        {
          headers: {
            Authorization: account[0],
          },
        }
      );

      if (response.status === 200) {
        setNewsList((prevNewsList) => {
          return prevNewsList.map((item) => {
            if (item.id === newsId) {
              return { ...item, featured: !featured };
            }
            return item;
          });
        });
      } else {
        console.error("Failed to update the featured status.");
        setShowFailed(true);
      }
    } catch (error) {
      console.error("API request error:", error);
      setShowFailed(true);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, [currentPage]);

  // console.log("news list", newsList);

  return (
    <div className="NewsList">
      <AdminNavBar />
      {loading && <Loaders />}
      {show == false && (
        <Container className="bgImage  p-o">
          <div className="mspace mt-5">
            <h2>Not an admin</h2>
          </div>
        </Container>
      )}
      {show && (
        <Container className="bgImage  p-o m-0" fluid>
          <div className="mspace1">
            <h3
              className="text-center section-title mt-2"
              style={{ fontWeight: "600", fontSize: "28px", color: "#444" }}
            >
              {" "}
              News List
            </h3>
            <Row>
              <Col>
                <div className="add-btn-section">
                  <Button
                    onClick={() => {
                      setEditorStateFromDescription("");
                      setNewsTitle("");
                      setFile("");
                      setShowAddPopup(true);
                      seterror("");
                    }}
                    className="btn1"
                  >
                    Add News
                  </Button>
                </div>
              </Col>
            </Row>
            <div className="ml-4 card-section">
              <Row>
                {newsList.length == 0 ? (
                  <Col>
                    <div className="text-left">
                      <div className="content">
                        <h4> No Data !</h4>{" "}
                      </div>
                    </div>
                  </Col>
                ) : (
                  <Col xs={12}>
                    <Table responsive="sm" borderless>
                      <thead>
                        <tr>
                          <th className="table-id">Id</th>
                          <th>News Title</th>
                          <th>Featured</th>
                          <th className="action-btn">Edit</th>
                          <th className="action-btn">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {newsList.map((item, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{item.title}</td>
                            <td>
                              <Form>
                                <Form.Check
                                  type="switch"
                                  id={`custom-switch-${i}`}
                                  checked={item.featured}
                                  onChange={() =>
                                    handleToggleFeature(item.id, item.featured)
                                  }
                                />
                              </Form>
                            </td>
                            <td>
                              <FaEdit
                                className="action-icon"
                                onClick={() => {
                                  setNewsTitle(item.title);
                                  setEditorStateFromDescription(
                                    item.description
                                  );
                                  setShowEditPopup(true);
                                  setUpdateNewsId(item.id);
                                  setFile(item.imageUrl);
                                  seterror("");
                                }}
                              />
                            </td>

                            <td>
                              <FaRegTrashAlt
                                className="action-icon"
                                onClick={() => {
                                  setShowDelete(true);
                                  setDeleteItemId(item.id);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                )}
                {totalPages > 1 && (
                  <Col xs={12} className="m-0">
                    <div className="paginationsection">
                      <span className="noofpagetxt">
                        Showing {currentPage + 1} From {totalPages}
                      </span>
                      <div className="paginationallignment">
                        <ReactPaginate
                          previousLabel={
                            <PreviousIcon className="paginationicon" />
                          }
                          nextLabel={<NextIcon className="paginationicon" />}
                          breakLabel={"..."}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={1}
                          breakClassName={"break-me"}
                          pageCount={totalPages}
                          onPageChange={handlePageChange}
                          containerClassName={"pagination"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            </div>
          </div>
        </Container>
      )}

      <Modal
        className="PopupStyle"
        show={showDelete}
        centered
        onClose={() => setShowDelete(false)}
      >
        <Modal.Body>
          <Container>
            <Row>
              <Col lg={12}>
                <div className="message-text">Do you want to delete this news ?</div>
              </Col>
              <Col lg={12} sm={12} xs={12}>
                <div className="btn-div">
                  <Button
                    className="submit-btn btn1"
                    onClick={() => {
                      deleteNews(deleteItemId);
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    className="submit-btn"
                    onClick={() => {
                      setShowDelete(false);
                    }}
                  >
                    No
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>

      <Modal
        show={showAddPopup}
        centered
        onClose={() => {
          handleClosePopup();
        }}
        className="newsPopup"
      >
        <Modal.Header>
          <span className="popupHeading">Add News</span>
          <FaTimes
            className="cross-icon"
            onClick={() => {
              handleClosePopup();
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={onSendmsg}>
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>News Title</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="News"
                  value={newsTitle}
                  onChange={(event) => setNewsTitle(event.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Enter News Title
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>News Image</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) => setFile(e?.target?.files?.[0])}
                />
                <Form.Control.Feedback type="invalid">
                  Select News Image
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>News Description</Form.Label>
                <Editor
                  editorState={rtf}
                  wrapperClassName="rtf-wrapper"
                  editorClassName="rtf-editor"
                  onEditorStateChange={(newState) => setRtf(newState)}
                />
                <Form.Control.Feedback type="invalid">
                  Enter News
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <div className="mt-2 mb-2 text-center">{error}</div>
            <Modal.Footer className="partner-pop">
              <ButtonCommon type="Submit" className="signup-btn" label="Add" />
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showEditPopup}
        centered
        onClose={() => setShowEditPopup(false)}
        className="newsPopup"
      >
        <Modal.Header>
          <span className="popupHeading">Edit News</span>
          <FaTimes
            className="cross-icon"
            onClick={() => {
              setShowEditPopup(false);
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={onEditSend}>
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>News Title</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="News"
                  value={newsTitle}
                  onChange={(event) => setNewsTitle(event.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Enter News Title
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>News Image</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) => setFile(e?.target?.files?.[0])}
                />
                <Form.Control.Feedback type="invalid">
                  Select News Image
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>News Description</Form.Label>
                <Editor
                  editorState={rtf}
                  wrapperClassName="rtf-wrapper"
                  editorClassName="rtf-editor"
                  onEditorStateChange={(newState) => setRtf(newState)}
                />
                <Form.Control.Feedback type="invalid">
                  Enter News
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <div className="mt-2 mb-2 text-center">{error}</div>
            <Modal.Footer className="partner-pop">
              <ButtonCommon
                type="Submit"
                className="signup-btn"
                label="Update"
              />
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <SuccessPopup
        show={showAddSuccess}
        setShowModal={(e) => setShowAddSuccess(e)}
        message={"News added successfully !"}
      />
      <SuccessPopup
        show={showEditSuccess}
        setShowModal={(e) => setShowEditSuccess(e)}
        message={"News updated successfully !"}
      />
      <SuccessPopup
        show={showDeleteSuccess}
        setShowModal={(e) => setShowDeleteSuccess(e)}
        message={"News deleted successfully !"}
      />
      <FailedPopup show={showFailed} setShowModal={(e) => setShowFailed(e)} />
    </div>
  );
}

export default NewsList;
